import React from "react";

// Option type definition
type Option = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  options: Option[];
  maxSelectable: number;
  selectedOptions: Option[];
  onChange: (selected: Option[]) => void;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  maxSelectable,
  selectedOptions,
  onChange,
}) => {
  const handleCheckboxClick = (option: Option) => {
    let updatedSelection;

    if (selectedOptions.some(selected => selected.value === option.value)) {
      // Deselect option
      updatedSelection = selectedOptions.filter(
        selected => selected.value !== option.value
      );
    } else {
      // Select new option
      if (selectedOptions.length < maxSelectable) {
        updatedSelection = [...selectedOptions, option];
      } else {
        alert(`You can select up to ${maxSelectable} options only.`);
        return;
      }
    }

    onChange(updatedSelection);
  };

  return (
    <div className="flex flex-row space-x-4">
      {options.map(option => (
        <div
          key={option.value}
          className="flex items-center space-x-2 cursor-pointer"
        >
          <input
            type="checkbox"
            checked={selectedOptions.some(
              selected => selected.value === option.value
            )}
            onChange={() => handleCheckboxClick(option)}
            className="form-checkbox"
          />
          <label
            className="text-sm font-medium text-gray-700"
            title={option.label} // Full label as tooltip
            onClick={() => handleCheckboxClick(option)}
          >
            {option.label.length > 5
              ? `${option.label.slice(0, 5)}...` // Show first 5 letters followed by "..."
              : option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
