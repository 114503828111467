import React from 'react'
import ServerIntegerationCard from './components/ServerIntegerationCard';

const ServerIntegration = () => {
  return (
   <>
   <div className='py-4 px-8'>
   <ServerIntegerationCard/>
   </div>
   </>
  )
}

export default ServerIntegration;
