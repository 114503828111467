interface Config {
    id: string;
    label: string;
  }
  
  interface LogEntry {
    [key: string]: string | number | null; // Adjusted for type safety (values can be strings, numbers, or null)
  }
  
  // Define the transformed entry type based on the config labels
  type TransformedEntry = {
    [key: string]: string | number | null; // The transformed entry can have dynamic keys (the labels from config) with the respective values
  };
  
  export const transformDataForGraphPdfExcel = (
    data: LogEntry[], 
    config: Config[]
  ): TransformedEntry[] => {
    return data.map((entry) => {
      const transformedEntry: TransformedEntry = {};
  
      // Iterate over the config and extract corresponding data from the entry
      config.forEach(({ id, label }) => {
        if (entry[id] !== undefined) {
          transformedEntry[label] = entry[id]; // Assign value based on label from config
        }
      });
  
      return transformedEntry;
    });
  };
  