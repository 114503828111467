import React from 'react';
import moment from 'moment';
import MobileTowerIcon from '../mobile-tower/MobileTowerIcon';

interface DeviceCardProps {
    deviceId: string;
    imeiNumber: string;
    rssi: string | number | null;
    timeStamp: string | null;
}

const DeviceCard: React.FC<DeviceCardProps> = ({ imeiNumber, rssi, timeStamp }) => {
    return (
        <div className="bg-white min-h-10 w-full md:w-[50%] p-1 rounded-lg shadow-md">
            <div className="flex flex-col ml-2 items-start w-full mt-1">
                <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[50%]">
                    <span className="font-bold">Device ID:</span> {imeiNumber}
                </div>
                <div className="flex items-center md:ml-0 md:w-1/2">
                    <MobileTowerIcon levels={parseInt(rssi as string) || 0} time={timeStamp || ""} />
                    <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                        Last data received at: {timeStamp
                            ? moment(timeStamp).format('YYYY-MM-DD HH:mm:ss')
                            : "No data received yet"}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DeviceCard;
