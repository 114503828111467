import React from 'react';

interface SensorCardProps {
  sensor: string;
  value: string | number;
  unit: string;
  sensorColor?: string; // Color for the sensor name
  valueColor?: string; // Color for the value
  bg_color?: string; // Background color
  unitcolor?: string; // Color for the unit
  onClick?: () => void;
  icon?: string; // Base64 image string for the icon
  time?: string; // Time to be displayed below the value
}

const SensorCard: React.FC<SensorCardProps> = ({
  sensor,
  value,
  unit,
  sensorColor,
  valueColor,
  bg_color,
  onClick,
  unitcolor,
  icon,
  time
}) => {
  return (
    <div
      className="relative rounded-lg border-stroke p-3  cursor-pointer flex-1 mx-1 mb-2 border border-1"
      style={{ backgroundColor: bg_color || 'white' }}
      onClick={onClick}
    >
      <div className="flex items-center">
        <p className="font-medium flex-1" style={{ color: sensorColor || "#202224" }}>
          {sensor}
        </p>
      </div>
      <div className="mt-4 flex items-center">
        <h4 className="text-title-md font-bold" style={{ color: valueColor || 'black' }}>
          {value}
          <span className="text-sm ml-1" style={{ color: unitcolor || 'gray' }}>
            {unit}
          </span>
        </h4>
        {/* Render the icon if it's passed as a base64 string */}
        {icon && (
          <div className="ml-auto">
            <img src={icon} alt="sensor icon" className="w-6 h-6" />
          </div>
        )}
      </div>
      {/* Render the time if it's passed */}
      {time && (
        <p className="text-sm text-gray-500 mt-1">{time}</p>
      )}
    </div>
  );
};

export default SensorCard;
