import React from 'react'
import SensorCard from '../../../../../components/card/SensorCard'
import maxdemandicon from '../../icons/max-demand.png'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps';

const METER_READING_1ST_ROW_CARD_TAGS = ['active_energy', 'reactive_energy', 'apparent_energy']
const METER_READING_1ST_ROW_CARD_COLOR = {
  active_energy: '#D1FADF',
  reactive_energy: '#FEF0C7',
  apparent_energy: '#E7E7FF'
} as const;
const FirstRowCard: React.FC<DashboardEnergyMonitoringProps> = ({sensorInfo, dashBoardSts, action, maxDemand, maxDemandTime}) => {

    
    
    const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 border bottom-2">
                <SensorCard
                    key={"Max Demand"}
                    sensor={'Max Demand'}
                    value={maxDemand ?? ''}
                    unit={'kWh'}
                    bg_color="#FEE4E2"
                    icon={maxdemandicon}
                    time={maxDemandTime && maxDemandTime.toString() || ''}

                />
                {activeTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={action === "DAILY_CONSUMPTION" && dashBoardSts ? dashBoardSts["consumption"+tag] || '' :dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={METER_READING_1ST_ROW_CARD_COLOR[tag as keyof typeof METER_READING_1ST_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
        </>
    )
}

export default FirstRowCard