import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import FirstRowCard from '../../components/FirstRowCard';
import { ChartData } from '../../../../../types/Chart';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { ConsumptionItem } from '../../../../../workers/consumption-data-worker/consumptionWorker';
import BarChart from '../../../../../components/chart/bar-chart/BarChart';
import RadioGroup from '../../../../../components/radio-group/RadioGroup';

const METER_READING_1ST_ROW_CARD_TAGS = ['t_acte', 't_re', 't_apte', 'r_hr']


type ChartState = {
  linechart: ChartData;
  barchart: ChartData;
};

const initialChartData: ChartData = {
  seriesData: [],
  categories: [],
};

const initialActiveLineChartState: ChartState = {
  linechart: initialChartData,
  barchart: initialChartData,
};

const names1: string[] = [];
const keyNames1: string[] = [];
const colors1 = ['f542e9', 'DD0000', 'E7E7FF', 'D1FADF'];
const yAxis1: number[] = [];
const postFix1: string[] = [];
type Option = {
  label: string;
  value: string;
};
const defaultDropdownOption: Option[] = [];
const defaultGraph: Option[] = [];

let postFix2: string[] = [];
const MeterReading: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, dashBoardSts, data = [] }) => {
  const workerRefProcessorLinechart1 = useRef<Worker | null>(null);
  const { currentUser } = useCurrentUserState()
  const workerRefConsumption = useRef<Worker | null>(null);
  const workerRefConProcessor = useRef<Worker | null>(null);
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const [selectedOptions, setSelectedOptions] = useState<{ label: string, value: string }[]>(defaultGraph);
  const [graph, setGraph] = useState<ChartState>(
    initialActiveLineChartState
  );
  const handleRadioChange = (selected: Option[]) => {
    setSelectedOptions(selected);
  };
  const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
    if (sensorInfo?.[tag]?.status) {
      if (!names1.includes(sensorInfo?.[tag]?.displaySensorName)) {
        names1.push(sensorInfo?.[tag]?.displaySensorName);
      }
      if (!keyNames1.includes(tag)) {
        keyNames1.push(tag);
      }
      if (!defaultDropdownOption.some(item => item.value === tag)) {
        if (defaultGraph.length < 3) {
          defaultGraph.push({ value: tag || '', label: sensorInfo[tag].displaySensorName })
        }
        defaultDropdownOption.push({
          label: sensorInfo?.[tag]?.displaySensorName || '',
          value: tag || ''
        });
      }
      postFix1.push(sensorInfo?.[tag]?.displaySensorUnit);

    }
    return sensorInfo?.[tag]?.status;
  });

  const handleDownloadOptionClickLinechart = (option: string) => {
    const config = [
      { id: "timeStamp", label: "Timestamp" },
    ];
    activeTags.map((tag) => {
      config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(data, config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("lineChart-container", filterData, currentUser.appLogo);
    }
  };

  const handleDownloadConcumptionBargraph = (option: string) => {
    const config = [
      { id: "hour", label: "Timestamp" },
    ];
    selectedOptions.map((tag) => {
      config.push({ id: "consumption" + tag.value, label: sensorInfo?.[tag.value].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(consumptionData || [], config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("barChart-container", filterData, currentUser.appLogo);
    }
  };

  useEffect(() => {
    workerRefProcessorLinechart1.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );

    workerRefConsumption.current = new Worker(
      new URL(
        '../../../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );

    workerRefConProcessor.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setGraph((prevCharts) => ({
        ...prevCharts,
        barchart: { seriesData, categories },
      }));
    };
    workerRefProcessorLinechart1.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setGraph((prevCharts) => ({
        ...prevCharts,
        linechart: { seriesData, categories },
      }));
    };
    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };


    return () => {
      if (workerRefProcessorLinechart1.current) {
        workerRefProcessorLinechart1.current.terminate();
        workerRefProcessorLinechart1.current = null;
      }
      if (workerRefConsumption.current) {
        workerRefConsumption.current?.terminate();
        workerRefConsumption.current = null;
      }
      if (workerRefConProcessor.current) {
        workerRefConProcessor.current?.terminate();
        workerRefConProcessor.current = null;
      }

    };
  }, []);

  useEffect(() => {
    if (workerRefProcessorLinechart1.current && data.length > 0) {

      workerRefProcessorLinechart1.current.postMessage({
        data: data,
        names: names1,
        keyNames: keyNames1,
        colors: colors1,
        yAxis: yAxis1,
      })
    }
    if (workerRefConsumption.current && data.length > 0) {
      workerRefConsumption.current.postMessage({
        deviceData: [...data].reverse(),
        tagName: activeTags,
      });
    }
  }, [data])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {
      const reversedConsumptionData = [...consumptionData].reverse();
      const names: string[] = [];
      const keyNames: string[] = [];
      postFix2 = []
      selectedOptions.forEach(tag => {
        const sensorName = sensorInfo?.[tag.value]?.displaySensorName;
        if (sensorName) {
          names.push(sensorName);
          keyNames.push("consumption" + tag.value);
          postFix2.push(sensorInfo?.[tag.value]?.displaySensorUnit);
        }
      });
      const colors = ['2980b9', 'FEF0C7', 'E7E7FF', 'ab55b5'];
      const categories = reversedConsumptionData.map((item) => item.hour)
      workerRefConProcessor.current.postMessage({
        data: reversedConsumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'hour',
        categories
      });
    }
  }, [consumptionData, selectedOptions]);


  return (
    <div>
      <FirstRowCard dashBoardSts={dashBoardSts} sensorInfo={sensorInfo} />
      <div className='bg-white border border-1 mt-2'>
        <div className="relative p-2">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleDownloadOptionClickLinechart}
              id={1}
            />
          </div>
          <p>
            {`Energy data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
              })`}
          </p>
        </div>
        <div
          id="lineChart-container"
        >
          <SplineChart
            title={`${activeTags?.map(tag => sensorInfo?.[tag].displaySensorName).join(", ") || "No sensors"} summary`}
            categories={graph?.linechart?.categories || []}
            series={graph?.linechart?.seriesData || []}
            yAxisTitle="Data"
            xAxisTitle="Time"
            height={350}
            maxCategories={12}
            postFixUnit={postFix1 as string[]}
          />
        </div>
      </div>

      <div className="relative bg-white border border-1 p-4 mt-2">
        <div className="absolute top-2 left-2 w-full max-w-[450px]">
          <RadioGroup
            options={defaultDropdownOption}
            maxSelectable={3}
            selectedOptions={selectedOptions}
            onChange={handleRadioChange}
          />
        </div>
        <div className="pt-16">
          <div className="relative p-2">
            <div className="absolute top-0 right-0">
              <LongMenu
                options={["Download as CSV", "Download as PDF"]}
                onOptionClick={handleDownloadConcumptionBargraph}
                id={1}
              />
            </div>
            <p>
              {`Hourly energy data variation with time (between ${data.length > 0
                ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}`
                : " "
                })`}
            </p>
          </div>
          <div id="barChart-container">
            <BarChart
              title="Consumption Summary"
              categories={graph?.barchart?.categories}
              series={graph?.barchart.seriesData}
              height={300}
              xAxisTitle="Time"
              yAxisTitle="Data"
              postFixUnit={postFix2}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default MeterReading