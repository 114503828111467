import { useNavigate } from "react-router-dom";
import { useDeviceState } from "../../../../app-context/device-context/DeviceState"
import StatusBadge from "../../../../components/status/StatusBadge";
import CommonTable from "../../../../components/table/Table";
import { Column, DataRow } from "../../../../types/table";
import { Device } from "../../../../types/Device";
import { setDevice } from "../../../../app-context/device-context/DeviceAction";
import { useState } from "react";
import { useCurrentUserState } from "../../../../app-context/current-user-context/CurrentUserLoginState";
import { RoleBack } from "../../../../types/Role";
import Loader from "../../../../components/loader/Loader";

const columns: Column[] = [
  { id: 'deviceAlias', label: 'Device Alias' },
  { id: 'deviceName', label: 'DEVICE NAME' },
  { id: 'status', label: 'STATUS', align: 'center' },
];
const DeviceTable = () => {
  const { device, dispatch,loading } = useDeviceState()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { currentUser } = useCurrentUserState()
  const navigate = useNavigate()
  const data: DataRow[] =
    device.device.length > 0
      ? device.device.map((row) => ({
        ...row,
        deviceAlias: (
          <span className="cursor-pointer" onClick={() => { handleClick(row._id, row) }}>{row.deviceAlias}</span>
        ),
        status: <StatusBadge status={row?.connectionTimeIntervalStatus ? 'active' : 'inactive'} />,
      }))
      : []
  const handleClick = (_id: string, deviceState: Device) => {
    navigate("/home/selected-device/" + _id, { state: deviceState })
  }
  const handlePageChange = (newPage: number) => {
    // const direction = newPage > device.currentPage - 1 ? 'next' : 'previous'
    // const page = +device.currentPage
    setDevice(dispatch, {
      page: newPage,
      limit: rowsPerPage,
      organizationId: currentUser.organizationId || '',
      vendorId: currentUser.vendorId || '',
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
      role: currentUser.role.name,
    })
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage)
    setDevice(dispatch, {
      page: device.currentPage,
      limit: newRowsPerPage,
      organizationId: currentUser.organizationId || '',
      vendorId: currentUser.vendorId || '',
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
      role: currentUser.role.name,
    })
  };
  return (
    <div>
   {loading  && <Loader isBarCircle={loading} />}

      <CommonTable
        columns={columns}
        data={data}
        pagination={{
          rowsPerPageOptions: [10, 25, 50, 100, 500],
          rowsPerPage: rowsPerPage,
          page: 1,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: device.totalItems,
          currentPage: device.currentPage,
          totalPages: device.totalPages,
        }}
        setHeight="60vh"
      />
    </div>
  )
}

export default DeviceTable