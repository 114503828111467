import  { Suspense, useEffect, useState } from 'react'
import Card from '../../../components/card/Card'
import GoogleMapComponent from '../../../components/google-map/GoogleMapComponent';
import active_device from '../../../assets/end-user-overview/active_device.png'
import inactive_device from '../../../assets/end-user-overview/inactive_device.png'
// import communication_error from '../../../assets/end-user-overview/communication_error.png'
import VendorsDeviceTable from './components/VendorsDeviceTable';
import { setDevice } from '../../../app-context/device-context/DeviceAction';
import { RoleBack } from '../../../types/Role';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import { useDeviceState } from '../../../app-context/device-context/DeviceState';
import Loader from '../../../components/loader/Loader';
import { http } from '../../../utils/helper/http';
import { baseUrlTenant } from '../../../utils/env/env.config';
import { useNavigate } from 'react-router-dom';

interface VendorData {
  onlineDevicesCount: number;
  offlineDevicesCount: number;
  offlineDevice: number;
  devicesCount: number;
}

const VendorOverview = () => {

 const { currentUser, loaded } = useCurrentUserState()
   const { device, dispatch, loading } = useDeviceState()
   const [vendorData, setvendorData] = useState<VendorData>({
       onlineDevicesCount: 0,
       offlineDevicesCount: 0,
       offlineDevice: 0,
       devicesCount: 0,
     })
  // const vendorData = {
  //   onlineDevicesCount: 50,
  //   offlineDevicesCount: 10,
  //   devicesCount: 60,
  // };
 const loadDeviceData = (search = '') => {
    setDevice(dispatch, {
      page: 1,
      limit: 10,
      search,
      organizationId: currentUser.organizationId || '',
      vendorId: currentUser.vendorId || '',
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
      role: currentUser.role.name,
    });
  };
 const navigate = useNavigate();
  const handleCardClick = (cardType: string) => {
    switch (cardType) {
      case 'organization':
        navigate("/home/organization-management");
        break;
      case 'device':
      case 'active_device':
      case 'inactive_device':
        navigate("/home/devices-dashboard", {state: cardType});
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (device.device.length === 0 && loaded) {
      loadDeviceData()
    }
    http(baseUrlTenant + `dashboardStats?vendorId=${currentUser.vendorId || ''}`)
    .then((response) => {
     console.log('response',response)
      setvendorData(response as VendorData)
    })
    .catch((error) => {
      console.log(error);
    })
  }, [loaded])

  return (
    <div className="mb-8 mt-2">
      {(loading) && <Loader isBarCircle={(loading)}/>}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1 xl:grid-cols-4 2xl:gap-7.5">
        <Suspense fallback={<div>Loading...</div>}>
          <Card value={vendorData.onlineDevicesCount} onClick={()=>{handleCardClick('active_device')}} heading="Active Devices" bg_color="#ffffff" img={active_device} />
          <Card value={vendorData.offlineDevicesCount} onClick={()=>{handleCardClick('inactive_device')}} heading="Inactive Devices" bg_color="#ffffff" img={inactive_device} />
          {/* <Card value={0} heading="Communication Error" bg_color="#ffffff" img={communication_error} /> */}
        </Suspense>
      </div>

      <div className="flex flex-col lg:flex-row h-[calc(100vh-3rem)] pt-3 lg:space-x-2 space-y-2 lg:space-y-0">
        <div className="w-full h-full">
          <VendorsDeviceTable />
        </div>
        <div className="w-full h-full">
          <GoogleMapComponent />
        </div>
      </div>
    </div>
  );
};

export default VendorOverview;
