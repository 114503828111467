import  { useEffect, useState } from "react";
import { handleGetTagGroupByTemplateId } from "../../services/api";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "../loader/Loader";
import { TemplateResponse } from "../../types/TagGroup";
import { TemplateTag } from "../../types/Device";

interface IntVal {
    [key: string]: number;
}

interface JsonData {
    Int_val: IntVal;
    RSSI: number;
    "Time Stamp": string;
    "Device Id": string;
    "Firmware Version Id": string;
}

const TemplateJsonFormat = () => {
    const [tagData, setTagData] = useState<TemplateResponse[]>([]); 
    const { state } = useLocation();
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jsonData, setJsonData] = useState<JsonData>({
        Int_val: {},
        RSSI: 30,
        "Time Stamp": moment().format("YY/MM/DD,HH:mm:ss"),
        "Device Id": "",
        "Firmware Version Id": "Energy_V1.0",
    });


    const handleFetch = async () => {
        try {
            if (state.action === "DEVICE" && state.tags) {
                handleRandomDataGeneratedFromStateTags(state.tags as TemplateTag[]); 
                setTagData(state.tags); 
            } else {
                setLoading(true);
                const tagGroup = await handleGetTagGroupByTemplateId(state._id);
                if (tagGroup) {
                    const tags = tagGroup?.data?.tagGroup;
                    setTagData(tags); 
                    handleRandomDataGeneratedFromApiTags(tags); 
                }
            }
        } catch (error) {
            console.error("Error fetching tag data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRandomDataGeneratedFromStateTags = (tags:TemplateTag[] ) => {
        const updatedIntVal: IntVal = tags?.reduce((acc, tag) => {
            const tagName = tag?.sensorAlias 
            if(tagName !== '' && tagName){
                acc[tagName] = parseFloat((Math.random() * 100).toFixed(2));
            } 
            return acc;
        }, {} as IntVal);

        setJsonData((prev) => ({
            ...prev,
            Int_val: updatedIntVal,
            "RSSI":Math.floor(Math.random() * 31),
            "Time Stamp": moment().format("YY/MM/DD,HH:mm:ss"),
            "Device Id": state.imeiNumber,
        }));
    };

    const handleRandomDataGeneratedFromApiTags = (tags: TemplateResponse[]) => {
        const updatedIntVal: IntVal = tags.reduce((acc, tag) => {
            const tagName =tag?.tagId?.tagName;
            if(tagName !== '' && tagName){
                acc[tagName] = parseFloat((Math.random() * 100).toFixed(2));
            } 
            return acc;
        }, {} as IntVal);

        setJsonData((prev) => ({
            ...prev,
            Int_val: updatedIntVal,
            "RSSI":Math.floor(Math.random() * 31),
            "Time Stamp": moment().format("YY/MM/DD,HH:mm:ss"),
            "Device Id": "IMEI_NUMBER/DEVICE_ID"
        }));
    };

    useEffect(() => {
        handleFetch(); 
    }, []); 

    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleRegenerate = () => {
        if (state.action === "DEVICE" && state.tags) {
            handleRandomDataGeneratedFromStateTags(state.tags);
        } else {
            handleRandomDataGeneratedFromApiTags(tagData);
        }
    };

    return (
        <div className="p-6 bg-gray-50 min-h-screen flex flex-col items-center">
            {loading && <Loader isBarCircle={loading} />}
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
                JSON Data Viewer
            </h1>
            <div className="w-full max-w-4xl bg-white shadow-md rounded-lg border border-gray-200 p-4 space-x-2">
                <pre className="text-sm bg-gray-100 p-4 rounded overflow-x-auto">
                    <code>{JSON.stringify(jsonData, null, 2)}</code>
                </pre>
                <button
                    onClick={handleCopy}
                    className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {copied ? "Copied!" : "Copy to Clipboard"}
                </button>
                <button
                    onClick={handleRegenerate}
                    className="mt-4 px-4 py-2 bg-green-600 text-white font-semibold rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                    Regenerate Data
                </button>
            </div>
        </div>
    );
};

export default TemplateJsonFormat;
