import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { WATER_METER_TEMPLATE, WATER_TOTALIZER_FLOW, ENERGY_1, AC_MONITORING, WATER_TOTALIZER_JOIN, TEMPERATURE_HUMIDITY_SINGLE, LEVEL_MONITORING_420, TEMPERATURE_HUMADITY_BOTH, LEVEL_MONITORING, WATER_QUALITY_1, WATER_QUALITY_2, WATER_QUALITY_3, WATER_QUALITY_4, WATER_QUALITY_5, WATER_QUALITY_6, CUSTOM_TEMPLATE, CGWA, ENERGY_SINGLE_PHASE, PULSE_WATER_TEMPLATE, MA_420, BUZZ_MONITORING, ENERGY_2  } from '../../utils/config-templateId/TemplateId';
import DashboardWaterMeter from './water-meter/DashboardWaterMeter';
import DashboardWaterTotalizerFlow from './Water-Totalizer_Flow/DashboardWaterTotalizerFlow';
import DashboardEnergyMonitoring from './Energy-Monitoring1/DashboardEnergyMonitoring';
import DashboardACMonitoring from './AC-Controller/DashboardACMonitoring';
import DashboardWaterTotalizerJoin from './Water-Totalizer_Join/DashboardWaterTotalizerJoin';
import DashboardTempHumSingle from './Temperature-Humidity/DashboardTempHumSingle';
import Dashboard420MassLevel from './4-20MassLevel/Dashboard420MassLevel';
import DashboardTempHumDouble from './Temperature-Humidity/DashboardTempHumDouble';
import DashboardLevelMonitoring from './Level-Monitoring/DashboardLevelMonitoring';
// import DashboardWaterQualityCommon from './Water-Quality/DashboardWaterQualityCommon';
import CustomTemplate from './custom-template/CustomTemplate';
import Cgwa from './CGWA/Cgwa';
import WaterQuality2 from './Water-Quality/WaterQuality2';
import WaterQuality3 from './Water-Quality/WaterQuality3';
import WaterQuality4 from './Water-Quality/WaterQuality4';
import WaterQuality5 from './Water-Quality/WaterQuality5';
import WaterQuality6 from './Water-Quality/WaterQuality6';
import EnergySinglePhase from './Energy-single-phase1/EnergySinglePhase';
import Pulse_Water_Template from './Pulse-water-template/Pulse_Water_Template';
import Dashboard420MA from './MA_420/Dashboard420MA';
import BuzzMonitoring from './buzz-monitoring/BuzzMonitoring';
import EnergyMonitoring from './energy-monitoring-2/EnergyMonitoring';
import WaterQuality1 from './water-quality/WaterQuality1';
// import EnergyMonitoring from './energy-monitoring-2/EnergyMonitoring';

const TemplateDashboardRouting = () => {
  // const { _id } = useParams()
  const location = useLocation();
  const state = location.state;
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  console.log("Selected Template", selectedTemplate);


  useEffect(() => {
    setSelectedTemplate(state.templateId);
  }, [state]);
  return (
    <div>
      {selectedTemplate === WATER_METER_TEMPLATE && (
        <>
          <p>1</p>
          <DashboardWaterMeter deviceState={state} />
        </>
      )}
      {selectedTemplate === WATER_TOTALIZER_FLOW && (
        <>
          <p>2</p>
          <DashboardWaterTotalizerFlow deviceState={state} />
        </>
      )}
      {selectedTemplate === ENERGY_1 && (
        <>
          <p>3</p>
          <DashboardEnergyMonitoring deviceState={state} />
        </>
      )}
      {selectedTemplate === AC_MONITORING && (
        <>
          <p>4</p>
          <DashboardACMonitoring deviceState={state} />
        </>
      )}
      {selectedTemplate === WATER_TOTALIZER_JOIN && (
        <>
          <p>5</p>
          <DashboardWaterTotalizerJoin deviceState={state} />
        </>
      )}
      {selectedTemplate === TEMPERATURE_HUMIDITY_SINGLE && (
        <>
          <p>6</p>
          <DashboardTempHumSingle deviceState={state} />
        </>
      )}
      {selectedTemplate === TEMPERATURE_HUMADITY_BOTH && (
        <>
          <p>7</p>
          <DashboardTempHumDouble deviceState={state} />
        </>
      )}
      {selectedTemplate === LEVEL_MONITORING_420 && (
        <>
          <p>8</p>
          <Dashboard420MassLevel deviceState={state} />
        </>
      )}
      {selectedTemplate === LEVEL_MONITORING && (
        <>
          <p>9</p>
          <DashboardLevelMonitoring deviceState={state} />
        </>
      )}
      {/* {selectedTemplate === WATER_QUALITY_1 || selectedTemplate === WATER_QUALITY_2 || selectedTemplate === WATER_QUALITY_3 || selectedTemplate === WATER_QUALITY_4 || selectedTemplate === WATER_QUALITY_5 || selectedTemplate === WATER_QUALITY_6 && (
        <>
          <p>10</p>
          <DashboardWaterQualityCommon deviceState={state} />
        </>
      )} */}

      {selectedTemplate === CUSTOM_TEMPLATE &&
        <>
          <p>11</p>
          <CustomTemplate deviceState={state} />
        </>
      }

      {selectedTemplate === WATER_QUALITY_1 &&
        <>
          <p>12</p>
          <WaterQuality1 deviceState={state} />
        </>
      }
        {selectedTemplate === WATER_QUALITY_2 &&
        <>
          <p>13</p>
          <WaterQuality2 deviceState={state} />
        </>
      }
        {selectedTemplate === WATER_QUALITY_3 &&
        <>
          <p>14</p>
          <WaterQuality3 deviceState={state} />
        </>
      }
        {selectedTemplate === WATER_QUALITY_4 &&
        <>
          <p>15</p>
          <WaterQuality4 deviceState={state} />
        </>
      }
        {selectedTemplate === WATER_QUALITY_5 &&
        <>
          <p>16</p>
          <WaterQuality5 deviceState={state} />
        </>
      }
        {selectedTemplate === WATER_QUALITY_6 &&
        <>
          <p>17</p>
          <WaterQuality6 deviceState={state} />
        </>
      }

      {selectedTemplate === CGWA &&
        <>
          <p>13</p>
          <Cgwa deviceState={state} />
        </>
      }

      {selectedTemplate === ENERGY_SINGLE_PHASE &&
        <>
          <p>14</p>
          <EnergySinglePhase deviceState={state} />
        </>
      }

      {selectedTemplate === PULSE_WATER_TEMPLATE &&
        <>
          <p>15</p>
          <Pulse_Water_Template deviceState={state} />
        </>
      }

      {selectedTemplate === MA_420 &&
        <>
          <p>16</p>
          <Dashboard420MA deviceState={state} />
        </>
      }
      {/* {selectedTemplate === ENER &&
        <EnergyMonitoring deviceState={state} />

      } */}

      {selectedTemplate === BUZZ_MONITORING &&
        <BuzzMonitoring deviceState={state} />

      }


      {selectedTemplate === ENERGY_2 &&
        <EnergyMonitoring deviceState={state} />

      }


    </div>
  );
};

export default TemplateDashboardRouting;
