import { Box, Tabs, Tab } from "@mui/material";
import SensorCard from "../../../components/card/SensorCard";
import SplineChart from "../../../components/chart/line-chart/SplineChart";
import CustomTabPanel from "../../../components/custom-tab-panel/CustomTabPanel";
import LongMenu from "../../../components/long-menu/LongMenu";
import a11yProps from "../../../types/a11yProps";
import { Device, ResponseDeviceSensorData, SensorData, SensorInfo } from "../../../types/Device";
import Loader from "../../../components/loader/Loader";
import { useCallback, useEffect, useRef, useState } from "react";
import DeviceCard from "../../../components/device-tower-card/DeviceCard";
import { useCurrentUserState } from "../../../app-context/current-user-context/CurrentUserLoginState";
import { baseUrlDevice } from "../../../utils/env/env.config";
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from "../../../utils/graph-download/pdfDownload";
import { http } from "../../../utils/helper/http";
import { transformDataForGraphPdfExcel } from "../../../workers/graph-data-filter-worker/transformData";
import nitrate from '../../../assets/images/nitrate.png';
import tds1 from '../../../assets/images/tds1.png';
import temp from '../../../assets/images/temp.png';
import pH from '../../../assets/images/pH.png';


interface WaterQualityTemplateProps {
    deviceState: Device;
}
const waterQuality2Tags = ['NI', 'TDS', 'TEMP', 'PH'];
const sensorIcons = {
  NI: nitrate,
  TDS: tds1,
  TEMP: temp,
  PH: pH,
};

const WaterQuality2: React.FC<WaterQualityTemplateProps> = ({ deviceState }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SensorData[]>([]);
    const [dashBoardSts, setDashboardSts] = useState<SensorData>();
    const workerRefSensor = useRef<Worker | null>(null);
    const workerRefProcessor = useRef<Worker | null>(null);
    const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
    const [value, setValue] = useState(0);
    const { currentUser } = useCurrentUserState();
    const [lineChart, setLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });
    
      const handleChange = (event: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
        };
    
        const activeTags = waterQuality2Tags.filter((tag) => sensorInfo?.[tag]?.status);
    
        const handleSensorNameClick = useCallback(
            (event: React.MouseEvent, tag: string) => {
                if (workerRefProcessor.current && data.length > 0) {
                    if (activeTags.length > 0) {
                        const names = [sensorInfo?.[tag]?.displaySensorName];
                        const keyNames = [tag];
                        const colors = ['2980b9'];
                        workerRefProcessor.current.postMessage({
                            data: [...data],
                            names,
                            keyNames,
                            colors,
                        });
                    }
                }
            },
            [data, activeTags]
        );
       
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await http(
                    `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
                );
                const dataAsSensor = response as ResponseDeviceSensorData;
                setData(dataAsSensor.deviceData);
                setDashboardSts(dataAsSensor.deviceData[0]);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        const handleDownloadOptionClick = (option: string, tag: string) => {
            const config = [
                { id: "timeStamp", label: "Timestamp" },
                { id: tag, label: sensorInfo?.[tag].displaySensorName || '' },
            ];
            const filterData = transformDataForGraphPdfExcel(data, config);
            if (option === 'Download as CSV') {
                generateExcelWithDataTable(filterData);
            } else if (option === 'Download as PDF') {
                generateGraphPDFWithDataTable("lineChart-container", filterData, currentUser.appLogo);
            }
        };
    
        useEffect(() => {
            workerRefSensor.current = new Worker(
                new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
            );
    
            workerRefProcessor.current = new Worker(
                new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
            );
    
            workerRefProcessor.current.onmessage = (e) => {
                const { seriesData, categories } = e.data;
                setLineChart({ categories, seriesData });
            };
    
            workerRefSensor.current.onmessage = (e) => {
                const { sensorInfo } = e.data;
                setSensorInfo(sensorInfo);
            };
    
            return () => {
                if (workerRefSensor.current) {
                    workerRefSensor.current.terminate();
                    workerRefSensor.current = null;
                }
                if (workerRefProcessor.current) {
                    workerRefProcessor.current.terminate();
                    workerRefProcessor.current = null;
                }
            };
        }, []);
    
        useEffect(() => {
            if (deviceState.tags && workerRefSensor.current) {
                workerRefSensor.current.postMessage({ tags: deviceState.tags });
                fetchData();
            }
        }, [deviceState.tags]);
    
        useEffect(() => {
            if (workerRefProcessor.current && data.length > 0) {
                if (activeTags.length > 0) {
                    const names = [sensorInfo?.[activeTags[0]].displaySensorName];
                    const keyNames = [activeTags[0]];
                    const colors = ['2980b9'];
                    workerRefProcessor.current.postMessage({
                        data: [...data],
                        names,
                        keyNames,
                        colors,
                    });
                }
            }
        }, [data]);
    
    
return (
    <div className="p-1 space-y-2">
        {loading && <Loader isBarCircle={loading} />}
        <DeviceCard
            deviceId={""}
            imeiNumber={deviceState.imeiNumber}
            rssi={parseInt(dashBoardSts?.rssi as string)}
            timeStamp={dashBoardSts?.timeStamp as string}
        />
        <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
            {activeTags.map((tag) => (
                sensorInfo?.[tag]?.status && (
                    <SensorCard
                        key={tag}
                        sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                        value={dashBoardSts?.[tag] || ''}
                        unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                        icon={sensorIcons[tag as keyof typeof sensorIcons]}
                    />
                )
            ))}
        </div>

        <Box sx={{ width: '100%', backgroundColor: "white" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="dynamic tabs example">
                    {activeTags.map((tag, index) =>
                        sensorInfo?.[tag]?.status ? (
                            <Tab
                                onClick={(e) => handleSensorNameClick(e, tag)}
                                key={tag}
                                label={sensorInfo[tag]?.displaySensorName || `Unknown ${tag}`}
                                {...a11yProps(index)}
                            />
                        ) : null
                    )}
                </Tabs>
            </Box>
            {activeTags.map((tag, index) =>
                sensorInfo?.[tag]?.status ? (
                    <CustomTabPanel
                        key={tag} value={value} index={index}>
                        <div className="relative p-2">
                            <div className="absolute top-0 right-0">
                                <LongMenu
                                    options={["Download as CSV", "Download as PDF"]}
                                    onOptionClick={(option: string) => handleDownloadOptionClick(option, tag)}
                                    id={1}
                                />
                            </div>
                            <p>
                                {`${sensorInfo?.[tag]?.displaySensorName} data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                            </p>
                        </div>

                        <div id="lineChart-container">
                            <SplineChart
                                title={`${sensorInfo[tag]?.displaySensorName || 'Unknown'} summary`}
                                categories={lineChart?.categories || []}
                                series={lineChart?.seriesData || []}
                                yAxisTitle="Data"
                                xAxisTitle="Time"
                                height={300}
                                tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                                maxCategories={12}
                            />
                        </div>
                    </CustomTabPanel>
                ) : null
            )}
        </Box>
    </div>
);
}

export default WaterQuality2;
