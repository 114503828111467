import React, { useEffect, useMemo, useState } from 'react'
import InputField from '../../../components/input-field/InputField'
import SearchableSelect from '../../../components/dropdown-select/SearchableSelect'
import DatePicker from '../../../components/custom-date/DatePicker'
import Button from '../../../components/button/Button'
import LinearWithValueLabel from '../../../components/linear-loader/LinearProgressBar'
import BackIcon from '../../../assets/images/back.png';
import { useLocation, useNavigate } from 'react-router-dom'
import MultiSelectSearchable from '../../../components/dropdown-select/MultiSelectSearchable'
import { generateDayOptions } from '../../../utils/helper/generateDayOptions'
import { useFormik } from 'formik'
import { OptionType } from '../../../types/Dropdown'
import dayjs, { Dayjs } from 'dayjs'
// import { useOrganizationState } from '../../../app-context/organization-context/OrganizationState'
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState'
import { useOptions } from '../../../app-context/dropdown-option/OptionState'
import { handleGetAllDevicebyOrgVendorUser, handleGetAllSitesByOrganizationId, handleGetAllTemplate, handleGetAllUser } from '../../../services/api'
import { ROLE_USER_ID } from '../../../utils/env/env.config'
import { RoleBack } from '../../../types/Role'
import axios from 'axios';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';
import {baseUrlReport} from '../../../utils/env/env.config'

interface MeterReadingProps {

}

interface OptionsState {
    vendor: OptionType[];
    user: OptionType[];
    device: OptionType[]
}

const reportFormatOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'excel', label: 'Excel' },
]

const methodOptions = [
    { value: 'manual', label: 'Manual' },
    { value: 'auto_generated', label: 'Auto Generated' },
]

const reportGeneratedTypeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
];

const receivedTimeOptionWeekly = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
];

const MeterReading: React.FC<MeterReadingProps> = () => {
    const accessToken = window.localStorage.getItem('token');
    const { currentUser } = useCurrentUserState();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const recievedTimeOptionMonthly = useMemo(() => generateDayOptions(), []);
    const [selectedRecievedOption, setSelectedRecievedOption] = useState<OptionType[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<OptionType | null>(null);
    const [selectedVendor, setSelectedVendor] = useState<OptionType | null>(null);
    const [selectedUser, setSelectedUser] = useState<OptionType | null>(null);
    const [selectedDevice, setSelectedDevice] = useState<OptionType | null>(null);
    const { fetchDropDownOptions, state } = useOptions();
    const { showToast } = useToast();
    const { organization } = state
    const [options, setOptions] = useState<OptionsState>({
        vendor: [],
        user: [],
        device: []
    })
    const navigate = useNavigate();

    const {state: locationState} = useLocation();
    console.log("location", locationState);
    
    

    const handleChangeRecievedOption = (selected: OptionType[]) => {
        setSelectedRecievedOption(selected);
        console.log('printed selected REcieved option:-', selected);
    }

    const handleDateChange = (field: string, value: Dayjs | null) => {
        formik.setFieldValue(field, value);
    }

    const handleReportAction = async () => {
        const isManual = formik.values.selectedMethod === 'manual';
        const formattedValues = {
            dataIntervalMinutes: formik.values.dataInterval,
            endDate: dayjs(formik.values.endDate).format('YYYY-MM-DD'),
            organizationId: selectedOrganization?.value,
            receivedOn: isAutoGenerated ? selectedRecievedOption.map(option => option.label) : [],
            receivedTime: isAutoGenerated && formik.values.receivedTime
            ? typeof formik.values.receivedTime === 'string'
                ? formik.values.receivedTime.split(' ')[0] // Example: split by space if needed
                : dayjs(formik.values.receivedTime).isValid()
                    ? dayjs(formik.values.receivedTime).toISOString()
                    : undefined
            : undefined,
            reportFormat: formik.values.reportFormat,
            reportHeader: formik.values.reportHeader,
            reportMethod: isManual ? 'manual' : 'autoGenerated',
            selectedDevice: selectedDevice?.value,
            selectedVendor: selectedVendor?.value,
            startDate: dayjs(formik.values.startDate).format('YYYY-MM-DD'),
            userId: selectedUser?.value,
            generatedType: isAutoGenerated ? formik.values.generatedType : undefined,
            email: formik.values.email, // Add email to the request
        };
    
        try {
            setIsLoaderVisible(true);
    
            const response = await axios.post(
                `${baseUrlReport}create`,
                formattedValues,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${accessToken}`,

                    },
                    responseType: isManual && (formik.values.reportFormat === 'pdf' || formik.values.reportFormat === 'excel') ? 'blob' : 'json',
                }
            );
    
            setIsLoaderVisible(false);
    
            if (!isManual) {
                // Auto-generated report scheduling logic
                showToast('Report scheduled successfully. You will receive an email on the scheduled date.', 'success');
                console.log('Auto-generated report response:', response.data);
                return;
            }
    
            // Manual report download logic
            if (formik.values.reportFormat === 'pdf' || formik.values.reportFormat === 'excel') {
                const blob = new Blob([response.data], {
                    type: formik.values.reportFormat === 'pdf'
                        ? 'application/pdf'
                        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = formik.values.reportFormat === 'pdf' ? 'report.pdf' : 'report.xlsx';
                link.click();
            } else {
                console.log('Manual report response:', response.data);
            }
        } catch (error) {
            setIsLoaderVisible(false);
    
            if (axios.isAxiosError(error)) {
                console.error('Axios error response:', error.response?.data);
                const errorMessage = error.response?.data?.message || 'An error occurred while processing the report.';
                showToast(errorMessage, 'error');
            } else {
                console.error('An unexpected error occurred:', error);
                showToast('An unexpected error occurred.', 'error');
            }
        }
    };
    

    const handleBackIcon = () => {
        navigate('/home/reports');
    }

    useEffect(() => {
        const isOrganizationEmpty = organization.length === 0;
        const isUserNotAdmin = !(currentUser.role.name === RoleBack.SITEADMIN || currentUser.role.name === RoleBack.ORGANIZATIONADMIN);
        if (isOrganizationEmpty && isUserNotAdmin) {
            fetchDropDownOptions('organization', '');
        }
    })

    const fetchOptions = async (key: string, _id: string) => {
        try {
            switch (key) {
                case 'site': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllSitesByOrganizationId(_id);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            vendor: data
                        }));
                    }
                }
                    break;
                case 'userbyorganization': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, '', _id, ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'devicebyorganizationVendorUser': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllDevicebyOrgVendorUser(_id, selectedVendor?.value || '', selectedUser?.value || '');
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            device: data
                        }));
                    }
                }
                    break;
                case 'userbysite': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, _id, '', ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'template': {
                    const data: OptionType[] = await handleGetAllTemplate();
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        template: data || [],
                    }));
                }
                    break;
                default:
                    console.warn(`No case found for key: ${key}`);
                    break;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues: {
            selectedUser: '',
            reportHeader: '',
            selectedMethod: '',
            generatedType: '',
            receivedTime: '',
            reportFormat: '',
            startDate: dayjs(),
            endDate: dayjs(),
            dataInterval: '',
            selectedDevice: '',
            selectedVendor: '',
            selectedOrganization: '',
            selectedRecievedOption: '',
            email:''
        },
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                startDate: dayjs(values.startDate).format('DD-MMM-YYYY'),
                endDate: dayjs(values.endDate).format('DD-MMM-YYYY'),
                selectedOrganization: selectedOrganization ? selectedOrganization.value : '',
                selectedVendor: selectedVendor ? selectedVendor.value : '',
                selectedUser: selectedUser ? selectedUser.value : '',
                selectedDevice: selectedDevice ? selectedDevice.value : '',
                selectedRecievedOption: selectedRecievedOption.map(option => option.label),
            };
            console.log('form values:- ', formattedValues);
            setIsLoaderVisible(true);
        }
    });

    const isAutoGenerated = formik.values.selectedMethod === 'auto_generated';
    const isMonthly = formik.values.generatedType === 'monthly'
    const isWeekly = formik.values.generatedType === 'weekly'
    const isDaily = formik.values.generatedType === 'daily'

    return (
        <div className='md:px-10 md:py-4'>
            <div className='mt-10 px-10 py-5 md:bg-white md:rounded-xl md:border md:border-slate-200 md:relative'>
                <div className='border border-[#E2E8F0] p-4 rounded-full w-[60px] h-[60px] justify-center items-center bg-[#E8F2F7] cursor-pointer absolute top-[-30px] left-[-30px] hidden md:flex' onClick={handleBackIcon}>
                    <img src={BackIcon} alt="backicon" />
                </div>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-5'>
                        <SearchableSelect
                            placeholder='Select Organization'
                            options={organization}
                            value={selectedOrganization}
                            onChange={(option) => {
                                setSelectedOrganization(option);
                                fetchOptions('site', option?.value ?? '');
                                setSelectedVendor(null);
                                setSelectedUser(null);
                                fetchOptions('userbyorganization', option?.value ?? '');
                                fetchOptions('devicebyorganizationVendorUser', option?.value ?? '');
                            }}
                            isLoading={false}
                            isDisabled={false}
                        />
                        <SearchableSelect placeholder='Select Vendor' options={options.vendor} value={selectedVendor} onChange={(option) => { setSelectedVendor(option); }} isLoading={false} />
                        <SearchableSelect placeholder='Select User' options={options.user} value={selectedUser} onChange={(option) => { setSelectedUser(option); }} isLoading={false} isDisabled={false} />
                        <InputField placeholder='Enter Report Header' name='reportHeader' label='Enter Report Header' value={formik.values.reportHeader} onChange={formik.handleChange} />
                        <SearchableSelect placeholder='Select Method' options={methodOptions} value={methodOptions.find(option => option.value === formik.values.selectedMethod) || null} onChange={value => formik.setFieldValue('selectedMethod', value?.value || '')} isLoading={false} isDisabled={false} />
                        <SearchableSelect placeholder='Select Report Format' options={reportFormatOptions} value={reportFormatOptions.find(option => option.value === formik.values.reportFormat) || null} onChange={value => formik.setFieldValue('reportFormat', value?.value || '')} isDisabled={false} isLoading={false} />
                        {!isAutoGenerated ?
                            <>
                                <DatePicker label='Start Date' value={formik.values.startDate} onChange={(date) => handleDateChange('startDate', date)} />
                                <DatePicker label='End Date' value={formik.values.endDate} onChange={(date) => handleDateChange('endDate', date)} />
                            </> :
                            <>
                                <SearchableSelect placeholder='Report Generated Type' options={reportGeneratedTypeOption} value={reportGeneratedTypeOption.find(option => option.value === formik.values.generatedType) || null} onChange={value => formik.setFieldValue('generatedType', value?.value || '')} isLoading={false} isDisabled={false} />
                                <MultiSelectSearchable placeholder='Received On' options={isDaily || isWeekly ? receivedTimeOptionWeekly : isMonthly ? recievedTimeOptionMonthly : []} value={selectedRecievedOption} onChange={handleChangeRecievedOption} />
                                <InputField label='Received Time' type='time' name='receivedTime' value={formik.values.receivedTime} onChange={formik.handleChange} />
                                <InputField label='Email' type='email' name='email' value={formik.values.email} onChange={formik.handleChange} />
                            </>
                        }
                        <InputField placeholder='Data Interval(minutes)' label='Data Interval(minutes)' name='dataInterval' value={formik.values.dataInterval} onChange={formik.handleChange} />
                        <SearchableSelect placeholder='Select Device' options={options.device} value={selectedDevice} onChange={(option) => { setSelectedDevice(option); }} isLoading={false} isDisabled={false} />
                    </div>
                    <div className='button-group flex gap-x-2 md:gap-x-10 mt-8 md:w-1/2 md:mx-auto'>
                        <Button type='reset' variant='outline' size='medium'>Reset</Button>
                        
                        <Button type='button' variant='outline' size='medium' onClick={handleReportAction}>{isAutoGenerated ? 'Schedule' : 'Download'}</Button>
                    </div>

                    {isLoaderVisible &&
                        <div className='flex justify-center'>
                            <LinearWithValueLabel />
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}

export default MeterReading
