import  { Suspense } from 'react'
import { useOverviewState } from '../../../app-context/overview-context/OverviewState'
import org_count from '../../../assets/dashboard/org_count.png';
import device_count from '../../../assets/dashboard/device_count.png';
import offline_device from '../../../assets/dashboard/offline_device.png';
import online_device from '../../../assets/dashboard/online_device.png';
import alert_circle from '../../../assets/dashboard/alert_circle.png';
import Card from '../../../components/card/Card';
import BarChart from '../../../components/chart/bar-chart/BarChart';
import ActiveInactiveChart from '../../../components/chart/round-chart/ActiveInactiveChart';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { useNavigate } from 'react-router-dom';




const OrganizationOverview = () => {
  const { orgAdminOverview } = useOverviewState()
   const navigate = useNavigate();
  const handleCardClick = (cardType: string) => {
    switch (cardType) {
      case 'organization':
        navigate("/home/organization-management");
        break;
      case 'device':
      case 'onlineDevice':
      case 'offlineDevice':
        navigate("/home/devices-dashboard", {state: cardType});
        break;
      default:
        break;
    }
  };

  return (
    <div className="h-screen mb-2 p-1">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 p-5 h-full">
        {/* Card 1 */}
        <div className=" rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
            <Suspense fallback={<div>Loading...</div>}>
              <Card iconBgClass='bg-purple-100' value={orgAdminOverview?.userCount || 0} heading='User Count' bg_color='#ffffff' img={org_count} />
              <Card iconBgClass='bg-orange-100' value={orgAdminOverview?.devicesCount || 0} heading='Device Count' bg_color='#ffffff' img={device_count} />
              <Card iconBgClass='bg-green-100' onClick={()=>{handleCardClick('onlineDevice')}} value={orgAdminOverview?.onlineDevicesCount || 0} heading='Online Device' bg_color='#ffffff' img={online_device} />
              <Card iconBgClass='bg-red-100' onClick={()=>{handleCardClick('offlineDevice')}} value={orgAdminOverview?.offlineDevicesCount || 0} heading='Offline Device' bg_color='#ffffff' img={offline_device} />
            </Suspense>
          </div>

        </div>

        {/* Card 2 */}
        <div className=" rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Subscription Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <BarChart title="Overview" categories={orgAdminOverview?.subscriptionStat?.categories || []} series={orgAdminOverview?.subscriptionStat?.seriesData || []}
              height={250} // Custom height
            // width={600}  // Custom width
            />
          </Suspense>
        </div>

        {/* Card 3 */}
        <div className="rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Device Count Info
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <ActiveInactiveChart
              data={[
                { name: 'Active Devices', count: orgAdminOverview?.onlineDevicesCount || 0, color: '#3B82F6' },
                { name: 'Inactive Devices', count: orgAdminOverview?.offlineDevicesCount || 0, color: '#EF4444' },
                { name: 'Communication Errors', count: 0, color: '#FBBF24' },
              ]}
              height={190} // Specify height if needed
            />
          </Suspense>
        </div>

        {/* Card 4 */}
        <div className="rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Ticket Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <LineChart yAxisTitle='Tickets Count' title="Overview" categories={orgAdminOverview.ticketStat?.categories || []} series={orgAdminOverview.ticketStat?.seriesData || []} height={250} />
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default OrganizationOverview