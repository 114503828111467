import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import Container from '../../../components/container/Container';
import Button from '../../../components/button/Button';
import InputField from '../../../components/input-field/InputField';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrlUser } from '../../../utils/env/env.config';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';

type ShowPasswordsState = {
  [key: string]: boolean;
};

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [showPasswords, setShowPasswords] = useState<ShowPasswordsState>({
    password1: false,
    password2: false,
  });

  const formik = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    validationSchema: Yup.object({
      password1: Yup.string().required('Password is required'),
      password2: Yup.string()
        .required('Password is required')
        .oneOf([Yup.ref('password1')], 'Passwords must match'), // Ensure password2 matches password1
    }),
  
    onSubmit: async (values) => {
      const resetToken = localStorage.getItem('reset_token'); // Assume token is stored in local storage
      if (!resetToken) {
        showToast('Reset token is missing. Please try again.', 'error');
        return;
      }

      try {
        const response = await axios.post(baseUrlUser+'/forgot-password/reset-password', {
          resetToken,
          newPassword: values.password1,
        });

        if (response.status === 200) {
          console.log('Password reset successful:', response.data);
          showToast('Password reset successful! Please log in with your new password.', 'success');
          navigate('/');
        } else {
          console.error('Unexpected response:', response);
          showToast('Password reset failed. Please try again.', 'error');
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          console.error('Password Reset Failed:', error.response?.data?.message || error.message);
          showToast(error.response?.data?.message || 'Failed to reset password.', 'error');
        } else {
          console.error('Unexpected Error:', error);
          showToast('An unexpected error occurred.', 'error');
        }
      }
    },  });

  const handlePasswordVisibility = (passwordKey: string) => {
    setShowPasswords((prevShowPasswords: ShowPasswordsState) => ({
      ...prevShowPasswords,
      [passwordKey]: !prevShowPasswords[passwordKey],
    }));
  };

  return (
    <Container heading="Reset password">
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className='space-y-2'>


          <div className="relative">
            <InputField
              name="password1"
              label="Password"
              type={showPasswords.password1 ? 'text' : 'password'}
              placeholder="Enter your password"
              value={formik.values.password1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password1 && formik.errors.password1 ? formik.errors.password1 : ''}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-1 cursor-pointer">
              {showPasswords.password1 ? (
                <VisibilityOff onClick={() => handlePasswordVisibility('password1')} className="text-black" />
              ) : (
                <Visibility onClick={() => handlePasswordVisibility('password1')} className="text-black" />
              )}
            </div>
          </div>

          <div className="relative mb-4">
            <InputField
              name="password2"
              type={showPasswords.password2 ? 'text' : 'password'}
              label="Confirm Password"
              placeholder="Confirm your password"
              value={formik.values.password2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password2 && formik.errors.password2 ? formik.errors.password2 : ''}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-1 cursor-pointer">
              {showPasswords.password2 ? (
                <VisibilityOff onClick={() => handlePasswordVisibility('password2')} className="text-black" />
              ) : (
                <Visibility onClick={() => handlePasswordVisibility('password2')} className="text-black" />
              )}
            </div>
          </div>
        </div>


        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <Link to="/" className="text-sm text-deepcerulan-600 hover:text-deepcerulan-500 underline">
            Back to Login
          </Link>
        </div>
        <div className="mt-2">
          <Button type="submit" variant="primary" size="medium">
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default ResetPasswordForm;
