import { Alert, AlertData, ApiResponseAlert } from '../../types/Alert';
import { RoleBack } from '../../types/Role';
import { baseUrlAlert } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';

interface AddAlertAction {
    type: 'ADD_ALERT'
    payload: { alert: Alert; totalItems: number }
}

interface SetAlertAction {
    type: 'SET_ALERT'
    payload: AlertData
}


interface UpdateAlertAction {
    type: 'UPDATE_ALERT'
    payload: { id: string; alert: Alert }
}

interface UpdateAlertStatusAction {
    type: "UPDATE_ALERT_STATUS";
    payload: { _id: string, status: boolean, key: string };
}

interface SetLoadingAction {
    type: 'SET_LOADING';
    payload: boolean;
}

export type AlertAction =
    | AddAlertAction
    | UpdateAlertAction
    | SetAlertAction
    | UpdateAlertStatusAction
    | SetLoadingAction


export async function setAlert(
    dispatch: React.Dispatch<AlertAction>,
    payload: {
        page: number
        limit: number
        organizationId: string
        vendorId: string
        userId?: string
        role: string,
        key: string
    },
) {
    try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const { limit, page, organizationId, vendorId, userId, role , key} = payload
        let queryParams = `?page=${page}&limit=${limit}&key=${key}`
        if (role === RoleBack.ORGANIZATIONADMIN) {
            queryParams +=
                organizationId !== '' ? `&organizationId=${organizationId}` : ''
        } else if (role === RoleBack.SITEADMIN) {
            queryParams += `&vendorId=${vendorId}`
        } else if (role === RoleBack.USER) {
            queryParams += userId ? `&userId=${userId}` : ''
        }
        const response = (await http(
            `${baseUrlAlert}alert${queryParams}`,
        )) as ApiResponseAlert
        const { limit: itemPerPage } = response
        const { data } = response
        const dataWithLimit = { ...data, itemPerPage }
        dispatch({
            type: 'SET_ALERT',
            payload: dataWithLimit,
        })
    } catch (err) {
        console.error(err)
    } finally{
        dispatch({ type: 'SET_LOADING', payload: false });
    }
}

export function addNewAlert(
    dispatch: React.Dispatch<AlertAction>,
    payload: { alert: Alert; totalItems: number },
) {
    dispatch({
        type: 'ADD_ALERT',
        payload,
    })
}



export function updateAlert(
    dispatch: React.Dispatch<AlertAction>,
    payload: { id: string; alert: Alert },
) {
    dispatch({
        type: 'UPDATE_ALERT',
        payload,
    })
}


export function updateAlertStatus(
    dispatch: React.Dispatch<AlertAction>,
    payload: { _id: string, status: boolean, key: string }
) {
    dispatch({
        type: "UPDATE_ALERT_STATUS",
        payload,
    });
}