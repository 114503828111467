import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { ChartData } from '../../../../../types/Chart';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import SensorCard from '../../../../../components/card/SensorCard';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import CustomTabPanel from '../../../../../components/custom-tab-panel/CustomTabPanel';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../../../types/a11yProps';



// VOLTAGE POWER
const THD_1ST_ROW_CARD = ['r_vtg_ph_thd', 'y_vtg_ph_thd', 'b_vtg_ph_thd']
const THD_1ST_ROW_CARD_COLOR = {
    r_vtg_ph_thd: '#D1FADF',
    y_vtg_ph_thd: '#FEF0C7',
    b_vtg_ph_thd: '#E7E7FF',
} as const;


// CURRENT POWER
const THD_2ND_ROW_CARD = ['r_crt_ph_thd', 'y_crt_ph_thd', 'b_crt_ph_thd']
const THD_2ND_ROW_CARD_COLOR = {
    r_crt_ph_thd: '#D1FADF',
    y_crt_ph_thd: '#FEF0C7',
    b_crt_ph_thd: '#E7E7FF',
} as const;




type LineChartState = {
    voltage: ChartData;
    current: ChartData;
};

const initialChartData: ChartData = {
    seriesData: [],
    categories: [],
};

const initialActiveLineChartState: LineChartState = {
    voltage: initialChartData,
    current: initialChartData,
};

const names1: string[] = [];
const keyNames1: string[] = [];
const colors1 = ['f542e9', 'DD0000', 'E7E7FF', 'D1FADF'];
const yAxis1: number[] = [];
const postFix1: string[] = [];

const names2: string[] = [];
const keyNames2: string[] = [];
const colors2 = ['117DAE', 'DD0000', 'E7E7FF', 'D1FADF'];
const postFix2: string[] = [];


const Thd: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], sensorInfo }) => {
    const [value, setValue] = React.useState(0);
    const { currentUser } = useCurrentUserState()
    const [lineChart, setLineChart] = useState<LineChartState>(
        initialActiveLineChartState
    );
    const workerRefLineChart1 = useRef<Worker | null>(null);
    const workerRefLineChart2 = useRef<Worker | null>(null);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const firstRowCardActiveTags = THD_1ST_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names1.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names1.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames1.includes(tag)) {
                keyNames1.push(tag);
            }
            postFix1.push(sensorInfo?.[tag]?.displaySensorUnit);

        }
        return sensorInfo?.[tag]?.status
    })
    const secondRowCardActiveTags = THD_2ND_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names2.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names2.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames2.includes(tag)) {
                keyNames2.push(tag);
            }
            postFix2.push(sensorInfo?.[tag]?.displaySensorUnit);

        }
        return sensorInfo?.[tag]?.status
    })

    const handleDownloadOptionClick = (option: string, key: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
        ];
        let id = ""
        if (key === "VOLTAGE") {
            firstRowCardActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "voltage-container"
        } else if (key === "CURRENT") {
            secondRowCardActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "current-container"
        }
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable(id, filterData, currentUser.appLogo);
        }
    };
    useEffect(() => {
        workerRefLineChart1.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart2.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart1.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            // setLineWiseChart({ seriesData, categories })
            setLineChart((prevCharts) => ({
                ...prevCharts,
                voltage: { seriesData, categories },
            }));
        };


        workerRefLineChart2.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                current: { seriesData, categories },
            }));
        };


        if (workerRefLineChart1.current && data.length > 0) {

            workerRefLineChart1.current.postMessage({
                data: data,
                names: names1,
                keyNames: keyNames1,
                colors: colors1,
                yAxis: yAxis1,
            })
        }

        if (workerRefLineChart2.current && data.length > 0) {
            workerRefLineChart2.current.postMessage({
                data: data,
                names: names2,
                keyNames: keyNames2,
                colors: colors2,
            })
        }



        return () => {
            if (workerRefLineChart1.current) {
                workerRefLineChart1.current.terminate();
                workerRefLineChart1.current = null;
            }
            if (workerRefLineChart2.current) {
                workerRefLineChart2.current.terminate();
                workerRefLineChart2.current = null;
            }

        };
    }, []);


    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {firstRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={THD_1ST_ROW_CARD_COLOR[tag as keyof typeof THD_1ST_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {secondRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={THD_2ND_ROW_CARD_COLOR[tag as keyof typeof THD_2ND_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

            <Box sx={{ width: '100%', backgroundColor: "white", marginTop: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Voltage"} {...a11yProps(0)} />
                        <Tab label={"Current"} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel

                    value={value} index={0}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            {/* Placeholder for potential menu */}
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "VOLTAGE")}

                                id={1}
                            />
                        </div>
                        <p>
                            {`Voltage data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                                })`}
                        </p>
                    </div>

                    <div
                        id="voltage-container"
                    >
                        <SplineChart
                            title={`Voltage Summary`}
                            categories={lineChart.voltage.categories}
                            series={lineChart.voltage.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            // tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                            maxCategories={12}
                            postFixUnit={postFix1}
                        />
                    </div>
                </CustomTabPanel>
                <CustomTabPanel
                    value={value} index={1}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            {/* Placeholder for potential menu */}
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "CURRENT")}

                                id={1}
                            />
                        </div>
                        <p>
                            {`Current data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                                })`}
                        </p>
                    </div>


                    <div
                        id="current-container"
                    >
                        <SplineChart
                            title={`Current Summary`}
                            categories={lineChart.current.categories}
                            series={lineChart.current.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            // tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                            maxCategories={12}
                            postFixUnit={postFix2}
                        />
                    </div>
                </CustomTabPanel>

            </Box>
        </>
    )
}

export default Thd