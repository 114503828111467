import React from 'react';
import filterLogo from '../../../assets/images/filter.png';

const CurrentDate = () => {
  // Get the current date
  const today = new Date();

  // Format the date as "date-month-year" with MMM for the month
  const formattedDate = `${today.getDate()}-${today.toLocaleString('en-US', {
    month: 'short',
  })}-${today.getFullYear()}`;

  return (
    <div className='px-2 lg:px-10 py-2'>
      <div className='flex justify-between items-center'>
        <h1 className='font-semibold text-gray-600 text-lg'>{formattedDate}</h1>
        <button
          className="ml-2 p-2 bg-[#117DAE] text-white rounded-full hover:bg-[#0e72a0]"
          aria-label="Filter"
        >
          <img src={filterLogo} alt="Filter Logo" className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default CurrentDate;
