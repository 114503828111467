import { Device, SensorData, SensorInfo } from "../../../../types/Device";

export interface DashboardEnergyMonitoringProps {
  deviceState?: Device;
  data?: SensorData[];
  dashBoardSts?: SensorData; 
  sensorInfo?: SensorInfo
  action?: string;
}

export const METER_READING_TAGS = ['t_acte', 't_re', 't_apte', 'exp_re', 'imp_re', 'exp_ae', 'imp_ae'];

export const METER_READING_1ST_ROW_CARD_TAGS = ['t_acte', 't_re', 't_apte'];
export const METER_READING_1ST_ROW_CARD_COLOR = {
  t_acte: '#D1FADF',
  t_re: '#FEF0C7',
  t_apte: '#E7E7FF',
};

export const METER_READING_2ST_ROW_CARD_TAGS = ['exp_re', 'imp_re', 'exp_ae', 'imp_ae'];
export const METER_READING_2ST_ROW_CARD_COLOR = {
  exp_re: '#FF33A6',
  imp_re: '#33FFB5',
  exp_ae: '#F0F8FF',
  imp_ae: '#FFD700',
};

 export const TAGS = [
  't_apte',
  't_re',
  'exp_re',
  'imp_re',
  't_acte',
  'exp_ae',
  'imp_ae',
  'max_ici3',
  'max_ici2',
  'max_ici1',
  'max_iv31',
  'max_iv23',
  'max_iv12',
  'max_iv3n',
  'max_iv2n',
  'max_iv1n',
  'a_intrpt',
  'r_hour',
  'apr_maxd',
  'rp_mind',
  'rp_maxd',
  'ap_mind',
  'ap_maxd',
  'thd_ci3',
  'thd_ci2',
  'thd_ci1',
  'thd_v31',
  'thd_v23',
  'thd_v12',
  'thd_v3n',
  'thd_v2n',
  'thd_v1n',
  'freq',
  'av_pf',
  'pf3',
  'pf2',
  'pf1',
  't_kva',
  'kva3',
  'kva2',
  'kva1',
  't_kvar',
  'kvar3',
  'kvar2',
  'kvar1',
  't_kw',
  'kw3',
  'kw2',
  'kw1',
  'av_c',
  'cl3',
  'cl2',
  'cl1',
  'av_ll',
  'v31',
  'v23',
  'v12',
  'av_ln',
  'v3n',
  'v2n',
  'v1n'
];