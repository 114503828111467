import React, { lazy, useCallback, useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import Loader from '../../../components/loader/Loader';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import SensorCard from '../../../components/card/SensorCard';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../types/a11yProps';
import CustomTabPanel from '../../../components/custom-tab-panel/CustomTabPanel';
import DualAxisLineChart from '../../../components/chart/line-chart/DualAxisLineChart';
import LongMenu from '../../../components/long-menu/LongMenu';
import SplineChart from '../../../components/chart/line-chart/SplineChart';
import { transformDataForGraphPdfExcel } from '../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
const DeviceCard = lazy(() => import('../../../components/device-tower-card/DeviceCard'));
interface DashboardTempHumDoubleProps {
    deviceState: Device;
}

interface SensorData {
    [key: string]: string | number;
}

interface ResponseData {
    deviceData: SensorData[];
}
const tags = ['Temperature', 'Humidity'];
const DashboardTempHumDouble: React.FC<DashboardTempHumDoubleProps> = ({
    deviceState,
}) => {
    const [data, setData] = useState<SensorData[]>([]);
    const [dashBoardSts, setDashboardSts] = useState<SensorData>();
    const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
    const workerRefSensor = useRef<Worker | null>(null);
    const workerRefLineChart = useRef<Worker | null>(null);
    const workerRefSingleLineChart = useRef<Worker | null>(null);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const [singleAxisValue, setSingleAxisValue] = React.useState(0);
    const { currentUser } = useCurrentUserState()
    const [tempHumSingleLineChart, setTempHumSingleLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });
    const [tempHumDualLineChart, setTempHumDualLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });
    const activeTags = tags.filter((tag) => sensorInfo?.[tag]?.status);

    const handleDownloadSingleAxisOptionClick = (option: string, tag: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
            { id: tag, label: sensorInfo?.[tag].displaySensorName || '' },
        ];
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable("lineChart-container", filterData, currentUser.appLogo);
        }
    };
    const handleDownloaDualAxisOptionClick = (option: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
            // { id: tag, label: sensorInfo?.[tag].displaySensorName || '' },
        ];
        activeTags.map((tag) => {
            config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
        })
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable("dualaxis-lineChart-container", filterData, currentUser.appLogo);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        const axisClicked = newValue === 0 ? 'Dual Axis' : 'Single Axis';
        if (axisClicked === 'Single Axis') {
            if (workerRefSingleLineChart.current && data.length > 0) {
                const names: string[] = [];
                const keyNames: string[] = [];
                const colors = ['117DAE'];
                const yAxis: number[] = [];

                if (sensorInfo?.[activeTags[singleAxisValue]]?.status) {
                    names.push(sensorInfo?.[activeTags[singleAxisValue]]?.displaySensorName);
                    keyNames.push(activeTags[singleAxisValue]);
                    yAxis.push(0);
                }


                workerRefSingleLineChart.current.postMessage({
                    data: data,
                    names,
                    keyNames,
                    colors,
                    yAxis,
                })
            }
        }
    };
    const handleChangeSingleAxis = (event: React.SyntheticEvent, newValue: number) => {
        setSingleAxisValue(newValue);

    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await http(
                `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
            );
            const dataAsSensor = response as ResponseData;
            setData(dataAsSensor.deviceData);
            setDashboardSts(dataAsSensor.deviceData[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);

        }
    };

    const handleSensorNameClick = useCallback(
        (event: React.MouseEvent, tag: string) => {
            if (workerRefSingleLineChart.current && data.length > 0) {
                if (activeTags.length > 0) {
                    const names = [sensorInfo?.[tag]?.displaySensorName];
                    const keyNames = [tag];
                    const colors = ['2980b9'];
                    workerRefSingleLineChart.current.postMessage({
                        data: [...data],
                        names,
                        keyNames,
                        colors,
                    });
                }
            }
        },
        [data, activeTags]
    );

    useEffect(() => {
        workerRefSensor.current = new Worker(
            new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
        );
        workerRefSensor.current.onmessage = function (e) {
            const { sensorInfo } = e.data;
            setSensorInfo(sensorInfo);

        };

        workerRefLineChart.current = new Worker(
            new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );
        workerRefSingleLineChart.current = new Worker(
            new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );
        workerRefLineChart.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setTempHumDualLineChart({ seriesData, categories })
        };
        workerRefSingleLineChart.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setTempHumSingleLineChart({ seriesData, categories })
        };
        return () => {
            if (workerRefSingleLineChart.current) {
                workerRefSingleLineChart.current.terminate();
                workerRefSingleLineChart.current = null;
            }
            if (workerRefLineChart.current) {
                workerRefLineChart.current?.terminate();
                workerRefLineChart.current = null;
            }
            if (workerRefSensor.current) {
                workerRefSensor.current?.terminate();
                workerRefSensor.current = null;
            }
      
          };

    }, []);

    useEffect(() => {
        if (workerRefLineChart.current && data.length > 0 && value === 0) {
            const names: string[] = [];
            const keyNames: string[] = [];
            const colors = ['117DAE', 'DD0000'];
            const yAxis: number[] = [];

            if (sensorInfo?.Temperature.status) {
                names.push(sensorInfo.Temperature.displaySensorName);
                keyNames.push('Temperature');
                yAxis.push(0);
            }

            if (sensorInfo?.Humidity.status) {
                names.push(sensorInfo.Humidity.displaySensorName);
                keyNames.push('Humidity');
                yAxis.push(1);
            }

            workerRefLineChart.current.postMessage({
                data: data,
                names,
                keyNames,
                colors,
                yAxis,
            })
        }
    }, [data])

    useEffect(() => {
        if (deviceState.tags && workerRefSensor.current) {
            workerRefSensor.current.postMessage({ tags: deviceState.tags });
        }
        fetchData();
    }, [deviceState]);


    return (
        <div className="p-1 space-y-2">
            {loading && <Loader isBarCircle={loading} />}
            <DeviceCard
                deviceId={""}
                imeiNumber={deviceState.imeiNumber}
                rssi={parseInt(dashBoardSts?.rssi as string)}
                timeStamp={dashBoardSts?.timeStamp as string}
            />

            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {activeTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                        />
                    )
                ))}
            </div>

            <Box sx={{ width: '100%', backgroundColor: "white" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Dual Axis"} {...a11yProps(0)} />
                        <Tab label={"Single Axis"} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            {/* Placeholder for potential menu */}
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={handleDownloaDualAxisOptionClick}
                                id={1}
                            />
                        </div>
                        <p>
                            {`${activeTags?.map(tag => sensorInfo?.[tag].displaySensorName).join(", ") || "No sensors"
                                } data variation with time (between ${data.length > 0
                                    ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}`
                                    : "No data available"
                                })`}
                        </p>
                    </div>
                    <div
                        id="dualaxis-lineChart-container"
                    >
                        <DualAxisLineChart
                            categories={tempHumDualLineChart.categories}
                            series={tempHumDualLineChart.seriesData}
                            yAxisTitlePrimary={sensorInfo?.['Temperature'].status ? sensorInfo?.['Temperature'].displaySensorName : '-'}
                            yAxisTitleSecondary={sensorInfo?.['Humidity'].status ? sensorInfo?.["Humidity"].displaySensorName : '-'}
                            xAxisTitle="Time"
                            height={450}
                        />
                    </div>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={singleAxisValue} onChange={handleChangeSingleAxis} aria-label="basic tabs example">
                            {activeTags.map((tag, index) =>
                                sensorInfo?.[tag]?.status ? (
                                    <Tab
                                        onClick={(e) => handleSensorNameClick(e, tag)}
                                        key={tag}
                                        label={sensorInfo[tag]?.displaySensorName || `Unknown ${tag}`}
                                        {...a11yProps(index)}
                                    />
                                ) : null
                            )}
                        </Tabs>
                    </Box>
                    {activeTags.map((tag, index) =>
                        sensorInfo?.[tag]?.status ? (
                            <CustomTabPanel
                                key={tag} value={singleAxisValue} index={index}>
                                <div className="relative p-2">
                                    <div className="absolute top-0 right-0">
                                        {/* Placeholder for potential menu */}
                                        <LongMenu
                                            options={["Download as CSV", "Download as PDF"]}
                                            onOptionClick={(option: string) => handleDownloadSingleAxisOptionClick(option, tag)}
                                            id={1}
                                        />
                                    </div>
                                    <p>
                                        {`${sensorInfo?.[tag]?.displaySensorName} data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                                            })`}
                                    </p>
                                </div>


                                <div
                                    id="lineChart-container"
                                >
                                    <SplineChart
                                        title={`${sensorInfo[tag]?.displaySensorName || 'Unknown'} summary`}
                                        categories={tempHumSingleLineChart.categories}
                                        series={tempHumSingleLineChart.seriesData}
                                        yAxisTitle="Data"
                                        xAxisTitle="Time"
                                        height={300}
                                        tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                                        maxCategories={12}
                                    />
                                </div>
                            </CustomTabPanel>
                        ) : null
                    )}
                </CustomTabPanel>
            </Box>
        </div>

    )
}

export default DashboardTempHumDouble