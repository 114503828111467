import React, { useRef, useState } from 'react';
import SearchableSelect from '../../../components/dropdown-select/SearchableSelect';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import DatePicker from '../../../components/custom-date/DatePicker';
import SearchAndActions from '../../../components/search-action/SearchAndActions';

interface LogsFilterProps {
  onFilterChange: (filters: { activityType?: string; startDate?: string; endDate?: string; searchTerm?: string }) => void;
  onSearchChange: (searchTerm: string) => void;
}

const LogsFilter: React.FC<LogsFilterProps> = ({ onFilterChange, onSearchChange }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const eventTypeOptions = [
    { value: 'get', label: 'Get' },
    { value: 'Create', label: 'Create' },
    { value: 'Update', label: 'Update' },
    { value: 'Delete', label: 'Delete' },
  ];

  const handleDateChange = (field: string, value: Dayjs | null) => {
    formik.setFieldValue(field, value);
  };

  const formik = useFormik({
    initialValues: {
      activityType: '',
      startDate: dayjs(),
      endDate: dayjs(),
    },
    onSubmit: (values) => {
      onFilterChange({
        activityType: values.activityType,
        startDate: values.startDate?.toISOString(),
        endDate: values.endDate?.toISOString(),
      });
    },
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      onSearchChange(newSearchTerm);
    }, 500); // Delay of 500ms

  };

  return (
    <form onSubmit={formik.handleSubmit} className="lg:px-10 lg:mt-2 p-2">
      <div>
        <SearchAndActions
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          showExport={false}
        />
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5">
        <div className="bg-white rounded-xl">
          <div className="p-4">
            <label className="mb-2 text-gray-500 text-sm font-semibold">Event Type</label>
            <SearchableSelect
              options={eventTypeOptions}
              value={eventTypeOptions.find((option) => option.value === formik.values.activityType) || null}
              onChange={(selected) => formik.setFieldValue('activityType', selected?.value || '')}
            />
          </div>
        </div>
        <div className="bg-white rounded-xl">
          <div className="p-4">
            <label className="mb-2 text-gray-500 text-sm font-semibold">Start Date</label>
            <DatePicker
              label=""
              value={formik.values.startDate}
              onChange={(date) => handleDateChange('startDate', date)}
            />
          </div>
        </div>
        <div className="bg-white rounded-xl">
          <div className="p-4">
            <label className="mb-2 text-gray-500 text-sm font-semibold">End Date</label>
            <DatePicker
              label=""
              value={formik.values.endDate}
              onChange={(date) => handleDateChange('endDate', date)}
            />
          </div>
        </div>
        <div className="flex items-center">
          <button
            type="submit"
            className="bg-[#117DAE] text-white px-4 py-2 rounded-full hover:bg-[#266c8c]"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </form>
  );
};

export default LogsFilter;
