import React from 'react';
import ReportCategoryCard from '../../../components/card/RepoortCategoryCard';
import meterRedingIcon from '../../../assets/images/meterReading.png'
import consumptionSummaryIcon from '../../../assets/images/consumptionSummary.png'
import consumptionSummary2Icon from '../../../assets/images/consumptionSummary2.png'
import statisticsSummaryIcon from '../../../assets/images/statisticsSummary.png'
import { usePermissionState } from '../../../app-context/permission-context/PermissionState';

const GenerateReport = () => {
  const { permissions } = usePermissionState()
  const meterReading = permissions?.meter_reading?.canMenu || false && permissions?.meter_reading?.canCreate || false
  const consumptionSummary = permissions?.consumption_summary?.canMenu || false && permissions?.consumption_summary?.canCreate || false
  const consumptionSummary2 = permissions?.consumption_summary_2?.canMenu || false && permissions?.consumption_summary_2?.canCreate || false
  const statisticSummary = permissions?.statistic_summary?.canMenu || false && permissions?.statistic_summary?.canCreate || false

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
        {meterReading && (
          <ReportCategoryCard subtitle='Report Format' title='Meter Reading' iconBgClass='bg-purple-100' icon={meterRedingIcon} link={'/home/reports/meter-reading'} />
        )}

        {consumptionSummary && (
          <ReportCategoryCard subtitle='Report Format' title='Consumption Summary' iconBgClass='bg-yellow-100' icon={consumptionSummaryIcon} link={'/home/reports/consumption'} />
        )}

        {consumptionSummary2 && (
          <ReportCategoryCard subtitle='Report Format' title='Consumption Summary 2' iconBgClass='bg-yellow-100' icon={consumptionSummary2Icon} link={'/home/reports/consumption-summary2'} />
        )}

        {statisticSummary && (
          <ReportCategoryCard subtitle='Report Format' title='Statistics Summary' iconBgClass='bg-yellow-100' icon={statisticsSummaryIcon} link={'/home/reports/statistics-summary'} />
        )}
      </div>
    </div>
  )
}

export default GenerateReport;
