import React, { useEffect, useState } from 'react'
import Loader from '../../../components/loader/Loader';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import CommonTable from '../../../components/table/Table';
import { Column, DataRow } from '../../../types/table';
import { http } from '../../../utils/helper/http';
import { baseUrlScheduling } from '../../../utils/env/env.config';
import { RoleBack } from '../../../types/Role';
import { useDeviceState } from '../../../app-context/device-context/DeviceState';
import { setDevice } from '../../../app-context/device-context/DeviceAction';
import StatusBadge from '../../organization/components/StatusBadge';
import { Switch } from '@mui/material';



const columns: Column[] = [
  { label: "Device Name", id: "deviceName" },
  { label: "Device Alias", id: "deviceAlias" },
  { label: "AC Status", id: "ac_status" },
  { label: "Temp (C)", id: "temp" },
  { label: "Humidity (%)", id: "hum" },
  { label: "Set Temp (C)", id: "set_temp" },
  { label: "SCH. Time", id: "schedulerOn" },
  { label: "SCH. Status", id: "scheduleStatus" },
  { label: "Device Status", id: "status" },
];



interface DeviceStatsAc {
  totalDevices: number;
  totalActiveDevices: number;
  totalInactiveDevices: number;
  totalScheduleOn: number;
  totalScheduleOff: number;
}

const AwfisDashboard = () => {
  const { device, dispatch } = useDeviceState()
  const [loading, setLoading] = useState(false);
  const { currentUser } = useCurrentUserState()
  const [deviceStats, setDeviceStats] = useState<DeviceStatsAc>({
    totalDevices: 0,
    totalActiveDevices: 0,
    totalInactiveDevices: 0,
    totalScheduleOn: 0,
    totalScheduleOff: 0
  });


  const handlePageChange = (newPage: number) => {
    console.log('Page changed to:', newPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    console.log('Rows per page changed to:', newRowsPerPage);
  };

  console.log("current user", currentUser);


  const fetchApiData = async () => {
    try {
      const role = currentUser?.role.name || '';
      setLoading(true);
      let queryParams = ""
      if (role === RoleBack.ORGANIZATIONADMIN) {
        queryParams +=
          currentUser.organizationId !== '' ? `&organizationId=${currentUser.organizationId}` : ''
      } else if (role === RoleBack.SITEADMIN) {
        queryParams += `&vendorId=${currentUser.vendorId}`
      }
      if (role === RoleBack.USER) {
        queryParams += currentUser._id ? `&userId=${currentUser._id}` : ''
      }

      const response = await http(baseUrlScheduling + `overview-dashboard?key=ac${queryParams}`) as { status: number, data: DeviceStatsAc }
      const responsedata = response.data;
      setDeviceStats({
        totalDevices: responsedata.totalDevices,
        totalActiveDevices: responsedata.totalActiveDevices,
        totalInactiveDevices: responsedata.totalInactiveDevices,
        totalScheduleOn: responsedata.totalScheduleOn,
        totalScheduleOff: responsedata.totalScheduleOff
      })

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (currentUser.clientId && (deviceStats.totalDevices === 0)) {
      fetchApiData();
      if (device.device.length === 0) {
        setDevice(dispatch, {
          page: 1,
          limit: 10,
          search: '',
          organizationId: currentUser.organizationId || '',
          vendorId: currentUser.vendorId || '',
          userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
          role: currentUser.role.name,
        })
      }
    }

  }, [currentUser.clientId])


  const data: DataRow[] =
    device.device.length > 0
      ? device.device.map((row) => ({
        ...row,
        imeiNumber: (
          <span className="cursor-pointer">{row.imeiNumber}</span>
        ),
        status: <StatusBadge status={row?.connectionTimeIntervalStatus ? 'active' : 'inactive'} />,
        temp: row.deviceData.temp,
        hum: row.deviceData.hum,
        set_temp: row.deviceData.set_temp,
        ac_status: row.deviceData.ac_status,
        schedulerOn: row.schedulingDetails?.schedulerOn || "N/A",
        scheduleStatus: <Switch
          checked={row.schedulingDetails?.triggerStatus || false}
          name="themeSwitch"
          color="primary"
          inputProps={{ 'aria-label': 'Switch with custom icons' }}
        />,
      }))
      : []
  return (
    <>
      <Loader isBarCircle={loading} />

      <div className="flex gap-4 mt-5">
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Total Devices
          </h2>
          <p className="text-center text-xl font-bold text-blue-600">

            {deviceStats.totalDevices}
          </p>
        </div>
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Active devices
          </h2>
          <p className="text-center text-xl font-bold text-green-600">
            {deviceStats.totalActiveDevices}
          </p>
        </div>
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Inactive Devices
          </h2>

          <p className="text-center text-xl font-bold text-red-600">
            {deviceStats.totalInactiveDevices}
          </p>
        </div>
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Schedular On/Off
          </h2>

          <p className="text-center text-xl font-bold">
            <span className="text-green-600">{deviceStats.totalScheduleOn}</span> /
            <span className="text-red-600"> {deviceStats.totalScheduleOff}</span>
          </p>
        </div>
      </div>
      <CommonTable
        columns={columns}
        data={data}
        title="Device Information"
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowsPerPage: 10,
          page: 1,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: device.totalItems,
          currentPage: 1,
          totalPages: 1,
        }}
      />
    </>
  )
}

export default AwfisDashboard