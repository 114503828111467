import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrlUser } from '../../../utils/env/env.config';
import { http } from '../../../utils/helper/http';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import { RoleBack } from '../../../types/Role';
import './RbackUpdate.css';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../../../components/loader/Loader';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';

interface Permission {
  permission_id: string;
  permission_value: string[];
}

interface PermissionsResponse {
  _id: string;
  clientId: string;
  description: string;
  name: string;
  scope: string;
}

interface Role {
  clientId: string;
  dashBoardName: string;
  description: string;
  name: string;
  permissions: Permission[];
}

const RbackUpdate = () => {
  const { roleId } = useParams();
  const { currentUser } = useCurrentUserState();
  const [role, setRole] = useState<Role>();
  const [permission, setPermission] = useState<PermissionsResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  // console.log("ROLE", role, "Permission", permission);
  const fetchRoleBasedOnRoleId = async () => {
    try {
      setIsLoading(true);
      const role = (await http(
        baseUrlUser + currentUser.clientId + '/roles/' + roleId
      )) as Role;
      const permission = (await http(
        baseUrlUser + currentUser.clientId + '/permissions'
      )) as PermissionsResponse[];
      setPermission(permission);
      setRole(role);
      console.log('ROLE', role, 'Permission', permission);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (roleId && currentUser.clientId) {
      fetchRoleBasedOnRoleId();
    }
  }, [roleId, currentUser.clientId]);
  const handleCheckboxChange = (
    permissionName: string,
    permission_id: string
  ) => {
    if (!role) return;

    const permissionIndex = role.permissions.findIndex(
      (p) => p.permission_id === permission_id
    );

    if (permissionIndex !== -1) {
      const updatedPermissions = [...role.permissions];
      const permissionValueIndex =
        updatedPermissions[permissionIndex].permission_value.indexOf(
          permissionName
        );

      if (permissionValueIndex !== -1) {
        updatedPermissions[permissionIndex].permission_value.splice(
          permissionValueIndex,
          1
        );
      } else {
        updatedPermissions[permissionIndex].permission_value.push(
          permissionName
        );
      }

      setRole((prevRole) =>
        prevRole ? { ...prevRole, permissions: updatedPermissions } : prevRole
      );
    } else {
      const newPermission: Permission = {
        permission_id: permission_id,
        permission_value: [permissionName],
      };
      setRole((prevRole) =>
        prevRole
          ? {
              ...prevRole,
              permissions: [...prevRole.permissions, newPermission],
            }
          : prevRole
      );
    }
  };

  const handleRoleUpdate = async () => {
    if (!role) return;
    setIsLoading(true);
    const filteredPermissions = role.permissions.filter(
      (p) => p.permission_value.length > 0
    );
    if (filteredPermissions.length === 0) {
      return showToast('Role Permission Cannot be empty!', 'hint');
    }

    try {
      await http(
        `${baseUrlUser}${currentUser.clientId}/roles/${roleId}`,
        'PUT',
        { ...role, permissions: filteredPermissions }
      );
      showToast('Role update successfully', 'success');
    } catch (error) {
      if (typeof error === 'string') showToast(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheck = (role: Role, _id: string, key: string) => {
    return role.permissions.some(
      (p) => p.permission_id === _id && p.permission_value.includes(key)
    );
  };

  return (
    <div className="flex flex-col h-screen">
      {isLoading && <Loader isBarCircle={isLoading} />}
      {role && (
        <>
          <div className="flex justify-between items-center">
            <div style={{ color: '#117DAE' }} className="flex-1 text-xl text-deepcerulan font-bold">
              <IconButton size="small" onClick={navigate.bind(null, -1)}>
                <ArrowBackIosIcon style={{ color: '#117DAE', fontSize: '1rem' }} />
              </IconButton>{role.name}
            </div>
            {role.name !== RoleBack.SUPERADMIN && (
              <div className="text-end">
                <button
                  onClick={navigate.bind(null, -1)}
                  className="bg-gray-600 mt-2 hover:bg-deepcerulan-700 text-white font-bold py-2 px-4 rounded mx-5 mb-5"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          {/* <div className="text-center text-xl text-deepcerulan font-bold p-2 m-1">{role.name}</div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 relative bg-white rounded-md">
            <div className='bg-[#117DAE] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1px] h-[95%]'></div>
            {permission?.length > 0 &&
              permission.map((p) => (
                // <div key={p._id} className="rounded-lg border border-stroke p-6 bg-white shadow-lg">
                //     <div className="flex justify-between">
                //         <h4 className="text-title-lg font-bold text-black">{p.name}</h4>
                //     </div>
                //     <div className="flex flex-wrap mt-4">
                //         {['Menu', 'Create', 'View', 'Edit', 'Delete'].map((action) => (
                //             <div className="flex items-center mr-4 mb-2" key={action}>
                //                 <input
                //                     type="checkbox"
                //                     id={`${action.toLowerCase()}-${p._id}`}
                //                     name={`${action.toLowerCase()}-${p._id}`}
                //                     checked={handleCheck(role, p._id, action)}
                //                     onChange={() => handleCheckboxChange(action, p._id)}
                //                 />
                //                 <label htmlFor={`${action.toLowerCase()}-${p._id}`} className="ml-2">{action}</label>
                //             </div>
                //         ))}
                //     </div>
                // </div>
                <div
                  key={p._id}
                  className="px-6 py-4"
                >
                  <div className="flex justify-between">
                    <h4 className="text-title-lg font-bold text-black">
                      {p.name.replace(/^./, char => char.toUpperCase())}
                    </h4>
                  </div>
                  <div className="flex flex-wrap mt-4">
                    {['Menu', 'Create', 'View', 'Edit', 'Delete'].map(
                      (action) => {
                        const isChecked = handleCheck(role, p._id, action);
                        return (
                          <div
                            key={action}
                            className={`action-button mr-4 mb-2 
                ${isChecked ? 'bg-lightblue border ' : 'bg-white border border-gray-300'}`}
                            onClick={() => handleCheckboxChange(action, p._id)}
                          >
                            <span className="text-black">{action}</span>
                            {isChecked && (
                              <CheckIcon
                                style={{ fontSize: '16px', color: '#117DAE' }}
                                className="text-deepblue ml-2"
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ))}
          </div>
          {role.name !== RoleBack.SUPERADMIN && (
            <div className="flex justify-end mt-4 mb-4">
              <button
                onClick={handleRoleUpdate}
                className="bg-red-600 hover:bg-deepcerulan-700 text-white font-bold py-2 px-4 rounded mx-5 mb-5"
              >
                Update
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RbackUpdate;
