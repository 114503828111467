import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import filterLogo from '../../assets/images/filter.png'

interface SearchAndActionsProps {
    searchTerm: string;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAddNew?: () => void;
    onExport?: () => void;
    onFilter?: () => void;
    handelAdd?: boolean;
    showExport?: boolean;
}

const SearchAndActions: React.FC<SearchAndActionsProps> = ({ searchTerm, onSearchChange, onAddNew, onExport, onFilter, handelAdd, showExport = true }) => {
    return (
        <div className="flex justify-between items-center mb-4">
            <div className="flex items-center flex-grow mr-4 max-w-xs relative">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={onSearchChange}
                    placeholder="Search..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-3xl"
                />
                <button
                    onClick={onFilter}
                    className="ml-2 p-2 bg-[#117DAE] text-white rounded-full hover:bg-[#0e72a0]"
                    aria-label="Filter"
                >
                    <img src={filterLogo} alt="Filter Logo" className="w-6 h-6" /> {/* Display the logo */}
                </button>
            </div>
            <div className="flex space-x-2">
                {showExport && (
                    <button
                        onClick={onExport}
                        className="px-4 py-2 bg-[#117DAE] text-white rounded-3xl text-sm hover:bg-[#0e72a0]"
                    >
                        <FileDownloadIcon style={{ fontSize: 25, marginRight: '2px' }} />
                        Export Data
                    </button>
                )}
                {handelAdd &&
                    <button
                        onClick={onAddNew}
                        className="px-4 py-2 bg-[#117DAE] text-white rounded-3xl text-sm hover:bg-[#0e72a0]"
                    >
                        <AddIcon style={{ fontSize: 25, marginRight: '2px' }} />
                        Add New
                    </button>
                }
            </div>
        </div>
    );
};

export default SearchAndActions;
