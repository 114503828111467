import React, { useEffect, useState, useRef } from 'react';
import { io as socketIOClient, Socket } from 'socket.io-client';
import { baseUrlMqttWatcher, baseUrlMqttWatcher1 } from '../../utils/env/env.config';
import { useToast } from '../../components/custom-toast/CustomToastProvider';

const MqttWatcher: React.FC = () => {
    const [messages, setMessages] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<'publish' | 'subscribe' | 'listen'>('listen');
    const [publishTopic, setPublishTopic] = useState<string>('');
    const [publishData, setPublishData] = useState<string>('{}'); // Default empty JSON object
    const [subscribeTopic, setSubscribeTopic] = useState<string>('');
    const [subscribedTopic, setSubscribedTopic] = useState<string>(''); // Track the subscribed topic
    const [subscribeMessages, setSubscribeMessages] = useState<string[]>([]); // Messages for subscribed topic
    const [isListening, setIsListening] = useState<boolean>(false);
    const [filteredMessages, setFilteredMessages] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const { showToast } = useToast()

    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        const newSocket = socketIOClient(baseUrlMqttWatcher);
        setSocket(newSocket);

        newSocket.onAny((event, data) => {
            setMessages(prevMessages => [...prevMessages, data]);
            if (event === subscribedTopic) {
                setSubscribeMessages(prevMessages => [...prevMessages, data]);
            }
        });

        newSocket.on('connect', () => {
            // handle connect
            setIsListening(true);
        });

        newSocket.on('disconnect', () => {
            // handle disconnect
            setIsListening(false);
        });

        return () => {
            newSocket.disconnect();
        };
    }, [subscribedTopic]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            scrollToBottom();
        }, 0);
        return () => clearTimeout(timeout);
    }, [messages, subscribeMessages]);

    useEffect(() => {
        if (searchQuery.trim()) {
            setFilteredMessages(
                messages.filter((message) => message.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        } else {
            setFilteredMessages(messages);
        }
    }, [messages, searchQuery]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const handleClearMessages = () => {
        setMessages([]);
    };

    const handleClearSubscribeMessages = () => {
        setSubscribeMessages([]);
    };

    const handlePublish = async () => {
        if (!publishTopic.trim()) {
            return;
        }
        try {
            const jsonData = JSON.parse(publishData);
            const messageString = JSON.stringify(jsonData);
            const response = await fetch(baseUrlMqttWatcher1 + 'publish', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: publishTopic, message: messageString }),
            });

            if (response.ok) {
                // handle success
                showToast('Message published successfully!', 'success');
            } else {
                // handle failure
                showToast('Failed to publish message', 'error');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubscribe = async () => {
        if (!subscribeTopic.trim()) {
            return;
        }
        try {
            const response = await fetch(baseUrlMqttWatcher1 + 'subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: subscribeTopic }),
            });

            if (response.ok) {
                setSubscribedTopic(subscribeTopic); // Update subscribed topic
            } else {
                // handle failure
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePause = () => {
        socket?.disconnect();
        setIsListening(false);
    };

    const handleResume = () => {
        if (!socket?.connected) {
            socket?.connect();
            setIsListening(true);
        }
    };

    const renderTabContent = () => {
      switch (activeTab) {
        case 'publish':
          return (
              <div className="flex flex-col h-full">
                  <div className="flex flex-col p-4 space-y-2">
                      <div className="mb-1">
                          <label htmlFor="publishTopic" className="block text-sm font-medium text-gray-700 border-1 border-gray-400">Topic to Publish:</label>
                          <input
                              type="text"
                              id="publishTopic"
                              className="mt-1 block w-full border-blue-400 border-2 p-2 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                              value={publishTopic}
                              onChange={(e) => setPublishTopic(e.target.value)}
                          />
                      </div>
                      <div className="border-1 border-blue-600">
                          <label htmlFor="publishData" className="block text-sm font-medium text-gray-700">JSON Data Body:</label>
                          <textarea
                              id="publishData"
                              className="border-2 border-blue-400 mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 resize-none"
                              style={{ minHeight: '330px' }} // Adjust this value as needed
                              value={publishData}
                              onChange={(e) => setPublishData(e.target.value)}
                          />
                      </div>
                        <div className='w-full text-right'>
                            <button className='text-white p-2 ml-2 mb-4 rounded-lg bg-[#808080] w-[150px]' onClick={()=> { setPublishTopic(''); setPublishData('{}') }}>Clear</button>
                            <button className='text-white p-2 ml-2 mb-4 rounded-lg bg-[#087db0] w-[150px]' onClick={handlePublish}>Publish</button>
                        </div>
                  </div>
              </div>
          );
          case 'subscribe':
              return (
                  <div className="flex flex-col h-full">
                      <div className="mb-1 border-1 border-blue-600 p-1">
                          <label htmlFor="subscribeTopic" className="block text-sm font-medium text-gray-700 border-1 border-gray-400">Topic to Subscribe:</label>
                          <input
                              type="text"
                              id="subscribeTopic"
                              className="mt-1 block w-full border-2 p-1 border-blue-400 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                              value={subscribeTopic}
                              onChange={(e) => setSubscribeTopic(e.target.value)}
                          />
                      </div>
                      {subscribedTopic && <div className="">Subscribed to: {subscribedTopic}</div>}
                      <div className="flex-1 grid grid-cols-1 rounded-lg border-2 border-blue-400 gap-4 overflow-auto mt-1" style={{ maxHeight: '350px' }}>
                          {subscribeMessages.map((message, index) => (
                              <div key={index} className="bg-gray-200 p-4 rounded-md">{message}</div>
                          ))}
                          <div ref={messagesEndRef} />
                      </div>
                      <div className="w-full text-right d-block mt-2">
                        <button className='bg-[#808080] rounded-lg p-2 ml-2 text-white w-[150px]' onClick={handleClearSubscribeMessages}>Clear Messages</button>
                        <button className='bg-[#087db0] rounded-lg p-2 ml-2 text-white w-[150px]' onClick={handleSubscribe}>Subscribe</button>
                      </div>
                  </div>
              );
          case 'listen':
          default:
              return (
                  <div className="flex flex-col h-full">
                      <div className="flex-1 grid grid-cols-1 rounded-lg border-2 border-blue-400 gap-4 overflow-auto h-full max-h-[calc(100vh-210px)]"> {/* Adjust max height based on header/footer space */}
                          {filteredMessages.map((message, index) => (
                              <div key={index} className="bg-gray-200 p-4 rounded-md">{message}</div>
                          ))}
                          <div ref={messagesEndRef} />
                      </div>
                        <div className="w-full flex items-center justify-between mt-1">
                            <div className="b-4 w-1/2 mr-2">
                                <input
                                    type="text"
                                    placeholder="Search messages..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="border-2 border-blue-400 p-2 rounded-md w-full"
                                />
                            </div>
                            <div className="flex space-x-2">
                                <button className='bg-[#808080] rounded-lg mt-2 p-2 text-white' onClick={handleClearMessages}>Clear Messages</button>
                                <button className='bg-[#087db0] rounded-lg mt-2 ml-2 p-2 text-white' onClick=   {isListening ? handlePause : handleResume}>
                                    {isListening ? 'Pause' : 'Resume'}
                                </button>
                            </div>
                        </div>
                  </div>
              );
      }
  };
  

    return (
        <div className="flex flex-col h-screen p-4 mt-4 bg-white rounded-3xl">
            {/* <h2 className="text-xl font-semibold mb-4">MQTT Data</h2> */}
            <div className="flex border-b border-gray-300 mb-4">
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'publish' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('publish')}
                >
                    Publish
                </button>
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'subscribe' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('subscribe')}
                >
                    Subscribe
                </button>
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'listen' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('listen')}
                >
                    Listen
                </button>
            </div>
            <div className="flex-1">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default MqttWatcher;
