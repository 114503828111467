import React, { useEffect, useRef, useState } from 'react'
import { Device, ResponseDeviceSensorData, SensorData, SensorInfo } from '../../../types/Device';
import Loader from '../../../components/loader/Loader';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import DeviceCard from '../../../components/device-tower-card/DeviceCard';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../types/a11yProps';
import CustomTabPanel from '../../../components/custom-tab-panel/CustomTabPanel';
import Analysis from '../energy-monitoring-2/analysis/Analysis';
import Instantaneous from './instantaneous/Instantaneous';

interface DashboardEnergyMonitoringProps {
  deviceState: Device;
}

const EnergyMonitoring:React.FC<DashboardEnergyMonitoringProps> = ({ deviceState }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SensorData[]>([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const workerRefSensor = useRef<Worker | null>(null);
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const [mainTabValue, setMainTabValue] = useState(0);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTabValue(newValue);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      )
      const dataAsSensor = response as ResponseDeviceSensorData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      console.log(dataAsSensor.deviceData);      
      setLoading(false);
    } catch(error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
      workerRefSensor.current = new Worker(
        new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
      );
  
      workerRefSensor.current.onmessage = function (e) {
        const { sensorInfo } = e.data;
        setSensorInfo(sensorInfo);
      };
  
      return () => {
        if (workerRefSensor.current) {
          workerRefSensor.current.terminate();
          workerRefSensor.current = null;
        }
      };
    }, []);

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
      fetchData();
    }
  }, [deviceState.tags]);

  return (
    <>

      {loading && <Loader isBarCircle={loading} />}

      <div className="flex flex-col lg:flex-row w-full mt-3">
        {/* Left Section: DeviceCard */}
        <div className="flex-1 mb-4 lg:mb-0">
          <DeviceCard
            deviceId={""}
            imeiNumber={deviceState.imeiNumber}
            rssi={parseInt(dashBoardSts?.rssi as string)}
            timeStamp={dashBoardSts?.timeStamp as string}
          />
        </div>
        {/* Right Section: Box with Main Tabs */}
        <div className="flex justify-end lg:ml-4">
          <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
            <Tabs value={mainTabValue} onChange={handleMainTabChange} aria-label="Main tabs for Analysis and Instantaneous">
              <Tab label={"Analysis"} {...a11yProps(0)} />
              <Tab label={"Instantaneous"} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </div>
      </div>

      <CustomTabPanel value={mainTabValue} index={0}>
        <Analysis dashBoardSts={dashBoardSts} data={data} deviceState={deviceState} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={mainTabValue} index={1}>
        <Instantaneous dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>
    
    </>
  )
}

export default EnergyMonitoring;