import React, { useEffect, useRef, useState } from 'react';
import { Device } from '../../../types/Device';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import TabularComponents from '../../../components/table/TabularComponents';
import SplineChart from '../../../components/chart/line-chart/SplineChart';
import BarChart from '../../../components/chart/bar-chart/BarChart';
import ColumnLineChart from '../../../components/chart/line-chart/ColumnLineChart';
import Loader from '../../../components/loader/Loader';
import { ConsumptionItem } from '../../../workers/consumption-data-worker/consumptionWorker';
import InstallationDetails from './components/InstallationDetails';
import LongMenu from '../../../components/long-menu/LongMenu';
import { transformDataForGraphPdfExcel } from '../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';


interface Dashboard420MAProps {
  deviceState: Device;
}

interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

interface SensorInfo {
  [key: string]: {
    displaySensorName: string;
    displaySensorUnit: string;
    status: boolean;
  };
}
// const tags = ['Flow', 'Total'];
// const activeColums : {field:string, headerName:string} [] = [ { field: 'timeStamp', headerName: 'TimeStamp' },]
const Dashboard420MA: React.FC<Dashboard420MAProps> = ({
  deviceState,
}) => {
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SensorData[]>([]);
  const [columns, setColumns] = useState<
    { field: string; headerName: string }[]
  >([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefProcessor = useRef<Worker | null>(null);
  const workerRefConProcessor = useRef<Worker | null>(null);
  const workerRefConsumption = useRef<Worker | null>(null);
  const { currentUser } = useCurrentUserState()
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const [flowLineChart, setFlowLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });
  const [consumptioBarChart, setConsumptionBarChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  // const activeTags = tags.filter((tag) =>{
  //   if(sensorInfo?.[tag]?.status && activeColums.)
  //   return sensorInfo?.[tag]?.status
  // } );


  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const handleDownloadOptionClick = (option: string, tag: string, chartContext: string) => {
    const config = [
      { id: "timeStamp", label: "Timestamp" },
      { id: tag, label: sensorInfo?.[tag].displaySensorName || '' },
    ];
    const filterData = transformDataForGraphPdfExcel(data, config)
    const chartContainerId = `chart-container-${tag.replace(/\s+/g, '-')}-${chartContext}`;
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable(chartContainerId, filterData, currentUser.appLogo);
    }
  };

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );
    workerRefSensor.current.onmessage = function (e) {
      const { columns: initialColumns, sensorInfo } = e.data;
      setSensorInfo(sensorInfo);
      setColumns([
        { field: 'timeStamp', headerName: 'TimeStamp' },
        ...initialColumns.filter((column: { field: string; headerName: string }) => column.field !== 'Total_val'),
      ]);
    };

    workerRefProcessor.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setFlowLineChart({ categories, seriesData });
    };

    workerRefConProcessor.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart({ categories, seriesData });
    };

    workerRefConsumption.current = new Worker(
      new URL(
        '../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );
    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };

    // Clean up the workers on component unmount
    return () => {
      if (workerRefSensor.current) {
        workerRefSensor.current.terminate();
      }
      if (workerRefProcessor.current) {
        workerRefProcessor.current.terminate();
      }
      if (workerRefConsumption.current) {
        workerRefConsumption.current?.terminate();
      }
      if (workerRefConProcessor.current) {
        workerRefConProcessor.current?.terminate();
      }
    };
  }, [consumptioBarChart]);

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState.tags]);
  useEffect(() => {
    if (workerRefProcessor.current && data.length > 0) {
      const reversedData = [...data].reverse();
      // const names = ['Flow']; // Define your series names
      // const keyNames = ['Flow']; // Corresponding keys
      // const colors = ['2980b9']; // Example colors
      const categories = reversedData.map((item) => item.timeStamp as string)
      // workerRefProcessor.current.postMessage({
      //   data: reversedData,
      //   names,
      //   keyNames,
      //   colors,
      // });
      const seriesData = [
        {
          name: sensorInfo?.['Flow'].displaySensorName || '',
          data: reversedData.map((item) => parseFloat(item.Flow as string) || 0),
          color: "#2980b9"
        }
      ]
      setFlowLineChart({ categories, seriesData })
    }

    if (workerRefConsumption.current && data.length > 0) {
      workerRefConsumption.current.postMessage({
        deviceData: [...data].reverse(),
        tagName: ['Total'],
      });
    }
  }, [data]);

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {
      const reversedConsumptionData = [...consumptionData].reverse();
      const names = [sensorInfo?.['Total'].displaySensorName]; // Define your series names
      const keyNames = ['Total']; // Corresponding keys
      const colors = ['2980b9']; // Example colors
      const categories = reversedConsumptionData.map((item) => item.hour)
      workerRefConProcessor.current.postMessage({
        data: reversedConsumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'hour',
        categories
      });
    }
  }, [consumptionData]);

  const flowValue = dashBoardSts?.Flow != null ? dashBoardSts.Flow : '0';


  return (
    <div className="p-1 space-y-2">
      {loading && <Loader isBarCircle={loading} />}
      <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
        <div className="flex flex-col ml-2 items-start w-full mt-1">
          <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[50%]">
            <span className="font-bold">Device ID:</span>{' '}
            {deviceState.imeiNumber}
          </div>
          <div className="flex items-center md:ml-0 md:w-1/2">
            <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
              Last data received at: {dashBoardSts?.timeStamp}
            </p>
          </div>
        </div>
      </div>
      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
        {sensorInfo?.['Flow']?.status && (
          <SensorCard
            sensor={sensorInfo?.['Flow']?.displaySensorName || 'Flow'}
            value={flowValue}
            unit={sensorInfo?.['Flow']?.displaySensorUnit || 'm³'}
          />
        )}
        {sensorInfo?.['Total']?.status && (
          <SensorCard
            sensor={
              sensorInfo?.['Total']?.displaySensorName || 'Flow'
            }
            value={dashBoardSts?.['Total'] || ''}
            unit={sensorInfo?.['Total']?.displaySensorUnit || 'm³'}
          />
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-5 ">
          <div
            style={{ maxHeight: '560px' }}
            className="shadow-sm mt-2 z-11 border border-solid border-gray-300 rounded p-2 bg-white"
          >
            <div className="flex items-center mb-4 w-full">
              <TabularComponents
                columns={columns}
                rows={data}
                pagination={true}
                sorting={true}
                tableBodyHeight="350px"
              />
            </div>
          </div>
          {/* model */}
          <div>
            <InstallationDetails />
          </div>
        </div>

        {/* columns 2 for charts */}

        <div className="mt-1">
          {sensorInfo?.['Flow']?.status && (
            <div className='bg-white p-3 mt-1'>
              <div className='text-end '>
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={(option: string) => handleDownloadOptionClick(option, 'Flow', 'spline')}
                />
              </div>
              <div id="chart-container-Flow-spline" className="p-1">
                <SplineChart
                  title="Flowrate Summary"
                  categories={flowLineChart?.categories || []}
                  series={flowLineChart?.seriesData || []}
                  yAxisTitle="Data"
                  xAxisTitle="Time"
                  height={300}
                  tooltipUnit={sensorInfo?.['Flow'].displaySensorUnit || ''}
                  maxCategories={12}
                />
              </div>
            </div>
          )}

          {sensorInfo?.['Total']?.status && (
            <div className='bg-white p-3 mt-4'>
              <div className='text-end '>
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={(option: string) => handleDownloadOptionClick(option, 'Total', 'bar')}
                />
              </div>
              <div id="chart-container-Total-bar" className="p-1">
                <BarChart
                  title="Consumption Summary"
                  categories={consumptioBarChart.categories}
                  series={consumptioBarChart.seriesData}
                  height={300}
                  xAxisTitle="Time"
                  yAxisTitle="Data"
                  tooltipUnit={sensorInfo?.['Total']?.displaySensorUnit || ''}
                />
              </div>
            </div>
          )}

          {sensorInfo?.['Flow']?.status && (
            <div className='bg-white p-3 mt-4'>
              <div className='text-end '>
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={(option: string) => handleDownloadOptionClick(option, 'Flow', 'column')}
                />
              </div>
              <div id="chart-container-Flow-column" className="p-1">
                <ColumnLineChart
                  title="Flowrate Summary"
                  categories={flowLineChart?.categories || []}
                  series={flowLineChart?.seriesData || []}
                  height={300}
                  xAxisTitle="Time"
                  yAxisTitle="Data"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard420MA;
