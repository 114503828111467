import React, { useEffect, useRef, useState } from 'react'
import FirstRowCard from '../components/FirstRowCard'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { DailyConsumptionItem } from '../../../../../workers/consumption-data-worker/consumptionWorkerDaily';
import { http } from '../../../../../utils/helper/http';
import { baseUrlDevice } from '../../../../../utils/env/env.config';
import { ResponseDeviceSensorData } from '../../../../../types/Device';
import BarChart from '../../../../../components/chart/bar-chart/BarChart';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import Loader from '../../../../../components/loader/Loader';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';

const METER_READING_1ST_ROW_CARD_TAGS = ['active_energy', 'reactive_energy', 'apparent_energy']
const toolTip: string[] = [];
const activeTagId: string[] = []
const DailyConsumption: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, deviceState, maxDemand, maxDemandTime }) => {
  const [consumptionData, setConsumtptionData] = useState<DailyConsumptionItem[]>();
  const workerRefConProcessor = useRef<Worker | null>(null);
  const [loading, setLoading] = useState(false);
  const [consumptioBarChart, setConsumptionBarChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });
  const { currentUser } = useCurrentUserState()

  const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
    if (sensorInfo?.[tag].status) {
      toolTip.push(sensorInfo?.[tag].displaySensorUnit);
      activeTagId.push(sensorInfo?.[tag]?.tagId);
    }
    return sensorInfo?.[tag]?.status;
  });

  const handleDownloadConcumptionBargraph = (option: string) => {
    const config = [
      { id: "day", label: "Timestamp" },
    ];
    activeTags.map((tag) => {
      config.push({ id: "consumption" + tag, label: sensorInfo?.[tag].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(consumptionData || [], config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("barChart-container", filterData, currentUser.appLogo);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}consumption-data?deviceId=${deviceState?._id}&tagId=${encodeURIComponent(JSON.stringify(activeTagId))}&month=2025-01`
      );
      const dataAsSensor = response as ResponseDeviceSensorData;
      setConsumtptionData(dataAsSensor.deviceData as DailyConsumptionItem[])
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    if(!consumptionData){
      fetchData()
    }
    workerRefConProcessor.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart({ categories, seriesData });
    };
    return () => {
      if (workerRefConProcessor.current) {
        workerRefConProcessor.current?.terminate();
        workerRefConProcessor.current = null;
      }

    };

  }, [])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {
      const reversedConsumptionData = [...consumptionData].reverse();
      const names: string[] = [];
      const keyNames: string[] = [];
      activeTags.forEach(tag => {
        const sensorName = sensorInfo?.[tag]?.displaySensorName;
        if (sensorName) {
          names.push(sensorName); 
          keyNames.push("consumption" + tag); 
        }
        const sensorUnit = sensorInfo?.[tag]?.displaySensorUnit || '';
        if (sensorUnit !== undefined) {
          toolTip.push(sensorUnit);
        }
      });
      const colors = ['2980b9', '79c9d4', '79d4b7'];
      const categories = reversedConsumptionData.map((item) => item.day)

      workerRefConProcessor.current.postMessage({
        data: reversedConsumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'day',
        categories
      });
    }
  }, [consumptionData]);


  return (
    <>
      {loading && <Loader isBarCircle={loading} />}
      <FirstRowCard maxDemand={maxDemand} maxDemandTime={maxDemandTime} action="DAILY_CONSUMPTION" dashBoardSts={consumptionData && consumptionData[consumptionData?.length - 1]} sensorInfo={sensorInfo} />
      {activeTags.length > 0 &&
        <>
          <div className='bg-white border border-1 mt-2'>
            <div className="relative p-2">
              <div className="absolute top-0 right-0">
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={handleDownloadConcumptionBargraph}
                  id={1}
                />
              </div>
              <p>
                {`Daily energy  data variation with time (between ${consumptionData?.length && consumptionData.length > 0 ? `${consumptionData[consumptionData.length - 1].day} to ${consumptionData[0].day}` : " "
                  })`}
              </p>
            </div>
            <div
              id="barChart-container"
            >
              <BarChart
                title="Consumption Summary"
                categories={consumptioBarChart.categories}
                series={consumptioBarChart.seriesData}
                height={300}
                xAxisTitle="Time"
                yAxisTitle="Data"
                postFixUnit={toolTip}
              />

            </div>
          </div>

        </>
      }
    </>
  )
}

export default DailyConsumption