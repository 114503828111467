import { Box, Tab, Tabs } from "@mui/material";
import { DashboardEnergyMonitoringProps } from "../types/EnergyMonitoringProps";
import a11yProps from "../../../../types/a11yProps";
import { useState } from "react";
import CustomTabPanel from "../../../../components/custom-tab-panel/CustomTabPanel";
import Voltage from "./voltage/Voltage";
import Current from "./current/Current";
import Power from "./power/Power";
import PowerFactor from "./power-factor/PowerFatctor";
import Frequency from "./frequency/Frequency";
import Other from "./other/Other";
import Demand from "./demand/Demand";
import Threshold from "./threshold/Threshold";

const Instantaneous:React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], sensorInfo }) => {
  const [tabValue, setTabValue] = useState(0);
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>

      <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Sub tabs for Analysis">
          <Tab label={"Voltage"} {...a11yProps(0)} />
          <Tab label={"Current"} {...a11yProps(1)} />
          <Tab label={"Power"} {...a11yProps(2)} />
          <Tab label={"Power Factor"} {...a11yProps(3)} />
          <Tab label={"Frequency"} {...a11yProps(4)} />
          <Tab label={"Demand"} {...a11yProps(5)} />
          <Tab label={"Threshold"} {...a11yProps(6)} />
          <Tab label={"Othrs"} {...a11yProps(7)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={tabValue} index={0}>
        <Voltage dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <Current dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={2}>
        <Power dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={3}>
        <PowerFactor dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={4}>
        <Frequency dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={5}>
        <Demand dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={6}>
        <Threshold dashBoardSts={dashBoardSts} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={7}>
        <Other dashBoardSts={dashBoardSts} sensorInfo={sensorInfo} />
      </CustomTabPanel>

    </>
  )
}

export default Instantaneous;