import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FiLoader } from "react-icons/fi";

interface LogCardProps {
  status: 'success' | 'failed' | 'inProgress';
  title: string;
  message: string;
  deviceId: string;
  activityBy: string;
  eventTime: string;
};

const LogCard: React.FC<LogCardProps> = ({
  status,
  title,
  message,
  deviceId,
  activityBy,
  eventTime,
}) => {
  // Define status-based configurations
  const statusConfig: Record<string, { icon: React.ElementType; borderColor: string; bgColor: string; buttonColor: string ; textColor:string;}> = {
    success: {
      icon: CheckCircleIcon,
      borderColor: "border-green-600",
      bgColor: "bg-green-light",
      buttonColor: "bg-dark-green",
      textColor: "text-green-600",
    },
    failed: {
      icon: CancelIcon,
      borderColor: "border-red-600",
      bgColor: "bg-red-light",
      buttonColor: "bg-dark-red",
      textColor: "text-red-600",
    },
    inProgress: {
      icon: FiLoader,
      borderColor: "border-blue-600",
      bgColor: "bg-blue-light",
      buttonColor: "bg-dark-blue",
      textColor: "text-blue-600",
    },
  };

  const { icon: Icon, borderColor, bgColor, buttonColor , textColor} = statusConfig[status] || {};

  return (
    <div className={`border ${borderColor} ${bgColor} rounded-md p-4`}>
      <div className="flex gap-2">
        <div>
          <Icon style={{ fontSize: "35px" }} className={textColor} />
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-sm md:text-lg">{title}</h1>
            <button className={`w-[100px] h-[30px] ${buttonColor} text-white text-sm rounded-tl-xl rounded-tr-xl rounded-br-xl`}>
              {status}
            </button>
          </div>
          <div className="mt-2 text-gray-600 text-sm">
            <p>
              <span className="font-semibold">Device ID:</span> {deviceId}
            </p>
            <p className="mt-2">{message}</p>
          </div>
          <div className="flex items-center justify-between mt-3 text-gray-600 text-sm">
            <p>
              <span className="font-semibold">Activity By:</span> {activityBy}
            </p>
            <p>
              <span className="font-semibold">Event Time:</span> {eventTime}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogCard;