import ReportCategoryCard from "../../components/card/RepoortCategoryCard"
import realTimeAlert from '../../assets/alert/real-time-alert.svg'
import consumption from '../../assets/alert/cnsumption.png'
import { useNavigate } from "react-router-dom"
import { usePermissionState } from "../../app-context/permission-context/PermissionState"

const Alerts = () => {
  const { permissions } = usePermissionState()
  const navigate = useNavigate()
  const handleCategoryClick = (category: string) => {
    navigate(`/home/alerts/${category}`);
  }
  
  const real_time_alert = permissions?.real_time_alert?.canMenu || false && permissions?.real_time_alert?.canView
  const consumptionAlert = permissions?.consumption_alert?.canMenu || false && permissions?.consumption_alert?.canView

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-5 p-2'>
      {real_time_alert && (
        <ReportCategoryCard title='Real-Time Alert' iconBgClass='bg-purple-100' icon={realTimeAlert} onClick={() => handleCategoryClick('real-time-alert')} link={""} />
      )}

      {consumptionAlert && (
        <ReportCategoryCard title='Consumption Alert' iconBgClass='bg-yellow-100' icon={consumption} onClick={() => handleCategoryClick('consumption-alert')} link={""} />
      )}
    </div>
  )
}

export default Alerts;