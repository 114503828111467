import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../types/EnergyMonitoringProps'
import { ChartData } from '../../../../types/Chart';
import SensorCard from '../../../../components/card/SensorCard';
import LongMenu from '../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../components/chart/line-chart/SplineChart';
import RadioGroup from '../../../../components/radio-group/RadioGroup';
import { transformDataForGraphPdfExcel } from '../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../../app-context/current-user-context/CurrentUserLoginState';


const INSTANTANEOUS_1ST_ROW_CARD = ['v', 'a', 'kW', 'kvar']
const INSTANTANEOUS_1ST_ROW_CARD_COLOR = {
  v: '#D1FADF',
  a: '#FEF0C7',
  kW: '#E7E7FF',
  kvar: '#D1FADF'
} as const;


const INSTANTANEOUS_2ND_ROW_CARD = ['kva', 'hz', 'pf', 'ex1']
const INSTANTANEOUS_2ND_ROW_CARD_COLOR = {
  kva: '#D1FADF',
  hz: '#FEF0C7',
  pf: '#E7E7FF',
  ex1: '#D1FADF'
} as const;

const INSTANTANEOUS_3RD_ROW_CARD = ['ex2', 'ex3', 'ex4', 'ex5']
const INSTANTANEOUS_3RD_ROW_CARD_COLOR = {
  ex2: '#D1FADF',
  ex3: '#FEF0C7',
  ex4: '#E7E7FF',
  ex5: '#D1FADF'
} as const;

const lineGraphChartSensor = [
  ...INSTANTANEOUS_1ST_ROW_CARD,
  ...INSTANTANEOUS_2ND_ROW_CARD,
  ...INSTANTANEOUS_3RD_ROW_CARD,
];
const lineGraphChartColors = [
  "D1FADF", "33FF57", "3357FF", "FF33A8", "A833FF",
  "FF8C33", "D1FADF", "8C33FF", "F1C40F", "2ECC71",
  "9B59B6", "E74C3C"
];




type Option = {
  label: string;
  value: string;
};

const defaultDropdownOption: Option[] = [];
const defaultGraph: Option[] = [];




type LineChartState = {
  linechart: ChartData;
};

const initialChartData: ChartData = {
  seriesData: [],
  categories: [],
};

const initialActiveLineChartState: LineChartState = {
  linechart: initialChartData
};


let postFix: string[] = [];
const Instantaneous: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, data = [], dashBoardSts }) => {
  const {currentUser}=useCurrentUserState()
  const [lineChart, setLineChart] = useState<LineChartState>(
    initialActiveLineChartState
  );
  const [selectedOptions, setSelectedOptions] = useState<{ label: string, value: string }[]>(defaultGraph);
  const workerRefLineChart = useRef<Worker | null>(null);
  const firstRowCardActiveTags = INSTANTANEOUS_1ST_ROW_CARD.filter((tag) => {
    return sensorInfo?.[tag]?.status
  })
  const secondRowCardActiveTags = INSTANTANEOUS_2ND_ROW_CARD.filter((tag) => {
    return sensorInfo?.[tag]?.status
  })
  const handleRadioChange = (selected: Option[]) => {
    setSelectedOptions(selected);
  };

  const thirdRowCardActiveTags = INSTANTANEOUS_3RD_ROW_CARD.filter((tag) => {
    return sensorInfo?.[tag]?.status
  })


  const activeLinegraphSensor = lineGraphChartSensor.filter((tag) => {
    if (sensorInfo?.[tag]?.status) {
      if (!defaultDropdownOption.some(item => item.value === tag)) {
        if (defaultGraph.length < 3) {
          defaultGraph.push({ value: tag || '', label: sensorInfo[tag].displaySensorName })
        }
        defaultDropdownOption.push({
          label: sensorInfo?.[tag]?.displaySensorName || '',
          value: tag || ''
        });
      }
    }
    return sensorInfo?.[tag]?.status
  })

  const handleDownloadOptionClickLinechart = (option: string) => {
    const config = [
      { id: "timeStamp", label: "Timestamp" },
    ];
    selectedOptions.map((tag) => {
      config.push({ id: tag.value, label: sensorInfo?.[tag.value].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(data, config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("lineChart-container", filterData, currentUser.appLogo);
    }
  };

  useEffect(() => {
    workerRefLineChart.current = new Worker(
      new URL('../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );


    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setLineChart((prevCharts) => ({
        ...prevCharts,
        linechart: { seriesData, categories },
      }));
    };



    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      postFix=[]
      selectedOptions.forEach(tag => {
        const sensorName = sensorInfo?.[tag.value]?.displaySensorName;
        if (sensorName) {
          names.push(sensorName);
          keyNames.push(tag.value);
          postFix.push(sensorInfo?.[tag?.value]?.displaySensorUnit || '');
        }
      });
      workerRefLineChart.current.postMessage({
        data: data,
        names: names,
        keyNames: keyNames,
        colors: lineGraphChartColors,
      })
    }

    return () => {
      if (workerRefLineChart.current) {
        workerRefLineChart.current.terminate();
        workerRefLineChart.current = null;
      }
    };
  }, [selectedOptions]);


  return (
    <div>
      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

        {firstRowCardActiveTags.map((tag) => (
          sensorInfo?.[tag]?.status && (
            <SensorCard
              key={tag}
              sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
              value={dashBoardSts?.[tag] || ''}
              unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
              bg_color={INSTANTANEOUS_1ST_ROW_CARD_COLOR[tag as keyof typeof INSTANTANEOUS_1ST_ROW_CARD_COLOR] || '#FFFFFF'}
            />
          )
        ))}
      </div>
      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

        {secondRowCardActiveTags.map((tag) => (
          sensorInfo?.[tag]?.status && (
            <SensorCard
              key={tag}
              sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
              value={dashBoardSts?.[tag] || ''}
              unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
              bg_color={INSTANTANEOUS_2ND_ROW_CARD_COLOR[tag as keyof typeof INSTANTANEOUS_2ND_ROW_CARD_COLOR] || '#FFFFFF'}
            />
          )
        ))}
      </div>
      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

        {thirdRowCardActiveTags.map((tag) => (
          sensorInfo?.[tag]?.status && (
            <SensorCard
              key={tag}
              sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
              value={dashBoardSts?.[tag] || ''}
              unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
              bg_color={INSTANTANEOUS_3RD_ROW_CARD_COLOR[tag as keyof typeof INSTANTANEOUS_3RD_ROW_CARD_COLOR] || '#FFFFFF'}
            />
          )
        ))}
      </div>
      {activeLinegraphSensor.length > 0 &&
        <div className="relative bg-white border border-1 p-4 mt-2">
          <div className="absolute top-2 left-2 w-full max-w-[450px]">
            <RadioGroup
              options={defaultDropdownOption}
              maxSelectable={3}
              selectedOptions={selectedOptions}
              onChange={handleRadioChange}
            />
          </div>

          <div className="relative pt-2 mt-2">
            <div className="absolute top-0 right-0">
              <LongMenu
                options={["Download as CSV", "Download as PDF"]}
                onOptionClick={handleDownloadOptionClickLinechart}
                id={1}
              />
            </div>
            <p>
              {`Energy data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                })`}
            </p>
          </div>
          <div
            id="lineChart-container"
          >
            <SplineChart
              title={`summary`}
              categories={lineChart?.linechart?.categories || []}
              series={lineChart?.linechart?.seriesData || []}
              yAxisTitle="Data"
              xAxisTitle="Time"
              height={350}
              maxCategories={12}
              postFixUnit={postFix as string[]}
            />
          </div>
        </div>

      }
    </div>
  )
}

export default Instantaneous