import React, { useEffect, useState } from 'react';
import LogCard from '../../../components/logs-card/LogCard';
import axios from 'axios';
import { baseUrlReport } from '../../../utils/env/env.config';
import { Box, TablePagination } from '@mui/material';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import Loader from '../../../components/loader/Loader';

interface LogCardProps {
  status: 'success' | 'failed' | 'inProgress';
  title: string;
  message: string;
  deviceId: string;
  activityBy: string;
  eventTime: string;
}

// Define the type for the API response
interface ActivityLogResponse {

  activityType: string;
  totalItems: number;
  createdAt: string;
  message: string;
  performedAt: string;
  relatedEntityType: string;
  status: string;
  updatedAt: string;
  user: {
    Id: string;
    name: string;
    email: string;
  };
  __v: number;
  _id: string;
}

const LogsActivity: React.FC<{ filters: { activityType?: string; startDate?: string; endDate?: string; searchTerm?: string } }> = ({ filters }) => {
  const [logs, setLogs] = useState<LogCardProps[]>([]); // State to hold logs
  const [loading, setLoading] = useState<boolean>(true);
  // const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);


  const fetchAllActivityLogs = async (currentPage: number, currentLimit: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrlReport}getAllActivityLog`, {
        params: {
          page: currentPage,
          limit: currentLimit,
          activityType: filters.activityType || undefined,
          startDate: filters.startDate || undefined,
          endDate: filters.endDate || undefined,
          search: filters.searchTerm || undefined,
        },
      });

      const total = response.data?.totalItems || 0;
      setTotalItems(total);
      setCurrentPage(response.data?.currentPage);
      setTotalPages(response.data?.totalPages);


      // Map the API response to match the LogCardProps structure
      const fetchedLogs: LogCardProps[] = response.data.activityLog.map((log: ActivityLogResponse) => ({
        status: log.status as 'success' | 'failed' | 'inProgress',
        title: `${log.activityType} ${log.relatedEntityType}`,
        message: log.message,
        deviceId: log.user.Id,
        activityBy: log.user.name,
        eventTime: new Date(log.performedAt).toLocaleString(),
      }));

      setLogs(fetchedLogs);
    } catch (error) {
      console.error('Error fetching activity logs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllActivityLogs(1, rowsPerPage);
  }, [rowsPerPage, filters]);

  const handlePageChange = (newPage: number) => {
    fetchAllActivityLogs(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage)
  };


  const zeroBasedPage = Math.max(0, currentPage - 1);

  return (
    <div className="px-2 lg:px-10 py-2">
      {loading ? (
        <Loader isBarCircle={loading} />
      ) : (
        <div className="bg-white rounded-tr-2xl rounded-tl-2xl flex flex-col gap-5 p-3 lg:p-5 h-[60vh] overflow-y-auto">
          {logs.length > 0 ? (
            logs.map((log, index) => <LogCard key={index} {...log} />)
          ) : (
            <p className='mx-auto text-lg font-semibold'>No logs available</p>
          )}
        </div>
      )}

      {!loading && (
        <Box display="flex" justifyContent="end" alignItems="center" bgcolor="white">
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={zeroBasedPage}
            // onPageChange={handlePageChange}
            onPageChange={(event, newPage) => handlePageChange(newPage + 1)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={(props) => (
              <TablePaginationActions
                {...props}
                count={totalItems}
                page={zeroBasedPage}
                rowsPerPage={rowsPerPage}
                totalPages={totalPages}
                onPageChange={(event, newPage) => handlePageChange(newPage + 1)}

              />
            )}
          />
        </Box>
      )}
    </div>

  );
};

export default LogsActivity;
