import React, { useState, useEffect, Suspense, lazy } from 'react';
import axios, { AxiosError } from 'axios';
import CommonTable from '../../../components/table/Table';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Switch } from '@mui/material';
import Loader from '../../../components/loader/Loader';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';
import excel from '../../../assets/report/excel.png';
import pdf from '../../../assets/report/pdf.png'
import { baseUrlReport } from '../../../utils/env/env.config';
import { http } from '../../../utils/helper/http';
import { useNavigate } from 'react-router-dom';
const LazyAlertDelete = lazy(() => import('../../../components/alert-delete/AlertDelete'))


interface ReportData {
  _id:string;
  reportMethod: string;
  reportFormat: string;
  receivedOn: string[];
  receivedTime: string;
  email: string;
  status: boolean;
}

const ScheduleReportList: React.FC = () => {
  const [data, setData] = useState<ReportData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage , setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<string | null>(null);

  const navigate = useNavigate();

  const { showToast } = useToast();

  const fetchReports = async (currentPage: number, currentLimit: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrlReport}/getAll?page=${currentPage}&limit=${currentLimit}`
      );
      console.log('API Response:', response.data);
      const reports = response.data?.reports || [];
      const total = response.data?.totalItems || 0;
      setCurrentPage(response.data?.currentPage);
      setTotalPages(response.data?.totalPages);
      setData(reports);
      setTotalItems(total);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateReportStatus = async (id:string, status:boolean) =>{
    try {
      const response = await http(
        `${baseUrlReport}/scheduleonoff/${id}?status=${status},`,'PATCH')
        showToast(`${status ? 'Active': 'Inactive'}`,`${status ? 'success': 'error'}` );
        return response;
    } catch (error) {
      const axiosError = error as AxiosError<{ message?: string }>;
      const errorMessage = axiosError.response?.data?.message || 'An error occurred while updating the status';
      showToast(errorMessage, 'error');
      console.error('Error updating report status:', axiosError);
      throw error;
    }
  }

  useEffect(() => {
    fetchReports(page, rowsPerPage);
  }, [page, rowsPerPage]);


  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  };


  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage)
   
  };


  const handleConfirmDelete = async () => {
    try {
      await http(baseUrlReport + `delete/${reportToDelete}`, 'DELETE');
      fetchReports(1,10);
      setConfirmDeleteModalOpen(false);
      showToast("Schedule report deleted successfully", 'success');
    } catch (error) {
      if (typeof error === 'string') showToast(error, 'error');
      console.log("Error while deleting:", error);
    }
  };
  
  // Handle Edit Action
  const handleEdit = (row: ReportData) => {
    console.log("Editing row:", row);
    navigate('/home/reports/meter-reading', {state:{_id:row._id, action:'UPDATE'}})
  };

  // Handle Delete Action
  const handleDeleteModal = (id: string) => {
    console.log("Deleting row with id:", id);
    setReportToDelete(id);
    setConfirmDeleteModalOpen(true)
  };

  // Handle Status Change
  const handleStatusChange = async (id: string, index: number, currentStatus: boolean) => {
    try {
       await updateReportStatus(id, !currentStatus); // Wait for the API call
  
      
        // Update the local state only if the API response is successful
        setData((prevData) =>
          prevData.map((item, i) =>
            i === index ? { ...item, status: !currentStatus } : item
          )
        );
      }
     catch (error) {
      console.error('Error updating status:', error);
      const axiosError = error as AxiosError<{ message?: string }>;
      const errorMessage = axiosError.response?.data?.message || 'An error occurred while updating the status';
      showToast(errorMessage, 'error');
    }
  };
  

  // Map data for the table
  const dataRow = data.map((row, index) => ({
    ...row,
    reportFormat: (
      <img
        src={
          row.reportFormat === "pdf"
            ? pdf 
            : row.reportFormat === "excel"
            ? excel
            : "" // Optional default icon
        }
        alt={row.reportFormat}
        style={{ width: '32px', height: '32px' }}
      />
    ),
    actions: (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5px',
          borderRadius: '6px',
          gap: '18px',
          border: '0.2px solid #D5D5D5',
        }}
        className="cursor-pointer"
      >
        <FaRegEdit
          style={{ fontSize: "20px", color: "#6b7280" }}
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
        />
        <RiDeleteBinLine
          style={{ color: 'red', fontSize: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteModal(row._id);
          }}
        />
      </span>
    ),
    status: (
      <Switch
        checked={row.status}
        onChange={() => handleStatusChange((row._id)as string, index, row.status)}
        color="primary"
      />
    ),
  }));

  // Define table columns
  const columns = [
    { id: 'reportMethod', label: 'Report Type' },
    { id: 'reportFormat', label: 'Report Format' },
    { id: 'receivedOn', label: 'Periodicity Time (min)' },
    { id: 'receivedTime', label: 'Received Time' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
  ];

  // Render the table
  return (
    <>
     <Suspense fallback={<div>Loading...</div>}>
            <LazyAlertDelete
              isModalOpen={isConfirmDeleteModalOpen}
              handleCloseModal={() => {
                setConfirmDeleteModalOpen(false)
              }}
              handleConfirmDelete={handleConfirmDelete}
            />
          </Suspense>
      {loading ? (
         <Loader isBarCircle={(loading)}/>
      ) : (
        <CommonTable
          columns={columns}
          data={dataRow}
          title="Schedule Report List"
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500],
            rowsPerPage: rowsPerPage,
            page: currentPage,
            handlePageChange,
            handleChangeRowsPerPage,
            totalItems: totalItems,
            currentPage: currentPage,
            totalPages: totalPages,
          }}
          setHeight={totalItems >= 8 ? "71vh" : "auto"}
        />
      )}
    </>
  );
};

export default ScheduleReportList;
