import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { ChartData } from '../../../../../types/Chart';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import SensorCard from '../../../../../components/card/SensorCard';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../../../types/a11yProps';
import CustomTabPanel from '../../../../../components/custom-tab-panel/CustomTabPanel';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';

const ACTIVE_POWER_ROW_CARD = ['t_kw', 'kw1', 'kw2', 'kw3']
const ACTIVE_POWER_ROW_CARD_COLOR = {
    t_kw: '#D1FADF',
    kw1: '#FEF0C7',
    kw2: '#E7E7FF',
    kw3: '#D1FADF'
};

const REACTIVE_POWER_ROW_CARD = ['t_kvar', 'kvar1', 'kvar2', 'kvar3']
const REACTIVE_POWER_ROW_CARD_COLOR = {
    t_kvar: '#D1FADF',
    kvar1: '#FEF0C7',
    kvar2: '#E7E7FF',
    kvar3: '#D1FADF'
};

const APPARENT_POWER_ROW_CARD = ['t_kva', 'kva1', 'kva2', 'kva3']
const APPARENT_POWER_ROW_CARD_COLOR = {
    t_kva: '#D1FADF',
    kva1: '#FEF0C7',
    kva2: '#E7E7FF',
    kva3: '#D1FADF'
};

type LineChartState = {
    activeChart: ChartData;
    reactiveChart: ChartData;
    apparentChart: ChartData;
};

const initialChartData: ChartData = {
    seriesData: [],
    categories: [],
};

const initialActiveLineChartState: LineChartState = {
    activeChart: initialChartData,
    reactiveChart: initialChartData,
    apparentChart: initialChartData,
};

const names1: string[] = [];
const keyNames1: string[] = [];
const colors1 = ['f542e9', 'DD0000', 'E7E7FF', 'D1FADF'];
const yAxis1: number[] = [];
const postFix1: string[] = [];

const names2: string[] = [];
const keyNames2: string[] = [];
const colors2 = ['117DAE', 'DD0000', 'E7E7FF', 'D1FADF'];
const postFix2: string[] = [];

const names3: string[] = [];
const keyNames3: string[] = [];
const colors3 = ['117DAE', 'DD0000', 'E7E7FF', 'D1FADF'];
const postFix3: string[] = [];


const Power: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], sensorInfo }) => {
    const [value, setValue] = React.useState(0);
    const { currentUser } = useCurrentUserState();
    const [lineChart, setLineChart] = useState<LineChartState>(initialActiveLineChartState);
    const workerRefLineChart1 = useRef<Worker | null>(null);
    const workerRefLineChart2 = useRef<Worker | null>(null);
    const workerRefLineChart3 = useRef<Worker | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const activePowerActiveTags = ACTIVE_POWER_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names1.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names1.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames1.includes(tag)) {
                keyNames1.push(tag);
            }
            postFix1.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status
    })

    const reactivePowerActiveTags = REACTIVE_POWER_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names2.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names2.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames2.includes(tag)) {
                keyNames2.push(tag);
            }
            postFix2.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status
    })

    const apparentPowerActiveTags = APPARENT_POWER_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names3.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names3.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames3.includes(tag)) {
                keyNames3.push(tag);
            }
            postFix3.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status
    })

    const handleDownloadOptionClick = (option: string, key: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
        ];
        let id = ""
        if (key === "ACTIVE") {
            activePowerActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "activeChart-container"
        } else if (key === "REACTIVE") {
            reactivePowerActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "reactiveChart-container"
        } else {
            apparentPowerActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "apparentChart-container"
        }
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable(id, filterData, currentUser.appLogo);
        }
    };

    useEffect(() => {
        workerRefLineChart1.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart2.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart3.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart1.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                activeChart: { seriesData, categories },
            }));
        };

        workerRefLineChart2.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                reactiveChart: { seriesData, categories },
            }));
        };

        workerRefLineChart3.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                apparentChart: { seriesData, categories },
            }));
        };

        if (workerRefLineChart1.current && data.length > 0 && value === 0) {
            workerRefLineChart1.current.postMessage({
                data: data,
                names: names1,
                keyNames: keyNames1,
                colors: colors1,
                yAxis: yAxis1,
            })
        }

        if (workerRefLineChart2.current && data.length > 0) {
            workerRefLineChart2.current.postMessage({
                data: data,
                names: names2,
                keyNames: keyNames2,
                colors: colors2,
            })
        }

        if (workerRefLineChart3.current && data.length > 0) {
            workerRefLineChart3.current.postMessage({
                data: data,
                names: names3,
                keyNames: keyNames3,
                colors: colors3,
            })
        }

        return () => {
            if (workerRefLineChart1.current) {
                workerRefLineChart1.current.terminate();
                workerRefLineChart1.current = null;
            }
            if (workerRefLineChart2.current) {
                workerRefLineChart2.current.terminate();
                workerRefLineChart2.current = null;
            }
            if (workerRefLineChart3.current) {
                workerRefLineChart3.current.terminate();
                workerRefLineChart3.current = null;
            }
        };
    }, []);

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {activePowerActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={ACTIVE_POWER_ROW_CARD_COLOR[tag as keyof typeof ACTIVE_POWER_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {reactivePowerActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={REACTIVE_POWER_ROW_CARD_COLOR[tag as keyof typeof REACTIVE_POWER_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {apparentPowerActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={APPARENT_POWER_ROW_CARD_COLOR[tag as keyof typeof APPARENT_POWER_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <Box sx={{ width: '100%', backgroundColor: "white" , marginTop: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Active"} {...a11yProps(0)} />
                        <Tab label={"Reactive"} {...a11yProps(1)} />
                        <Tab label={"Apparent"} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel

                    value={value} index={0}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "ACTIVE")}
                                id={1}
                            />
                        </div>
                        <p>
                            {`Power data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                        </p>
                    </div>

                    <div id="activeChart-container">
                        <SplineChart
                            title={`Power Summary`}
                            categories={lineChart.activeChart.categories}
                            series={lineChart.activeChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            maxCategories={12}
                            postFixUnit={postFix1}
                        />
                    </div>
                </CustomTabPanel>
                <CustomTabPanel
                    value={value} index={1}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "REACTIVE")}
                                id={1}
                            />
                        </div>
                        <p>
                            {`Power data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                        </p>
                    </div>

                    <div id="reactiveChart-container">
                        <SplineChart
                            title={`Power Summary`}
                            categories={lineChart.reactiveChart.categories}
                            series={lineChart.reactiveChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            maxCategories={12}
                            postFixUnit={postFix2}
                        />
                    </div>
                </CustomTabPanel>
                <CustomTabPanel
                    value={value} index={2}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "APPARENT")}
                                id={1}
                            />
                        </div>
                        <p>
                            {`Power data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                        </p>
                    </div>

                    <div id="apparentChart-container">
                        <SplineChart
                            title={`Power Summary`}
                            categories={lineChart.apparentChart.categories}
                            series={lineChart.apparentChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            maxCategories={12}
                            postFixUnit={postFix3}
                        />
                    </div>
                </CustomTabPanel>
            </Box>

        </>
    )
}

export default Power;