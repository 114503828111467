import SubscriptionCards from "./SubscriptionCards"
import SubscriptionTable from "./SubscriptionTable"

function SubscriptionDasboard() {
  return (
    <div className="p-4">
        <div>
        <SubscriptionCards />
        </div>
        <div></div>
        <div>
        <SubscriptionTable />
        </div>
    </div>
  )
}
export default SubscriptionDasboard