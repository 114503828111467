import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { ChartData } from '../../../../../types/Chart';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import SensorCard from '../../../../../components/card/SensorCard';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../../../types/a11yProps';
import CustomTabPanel from '../../../../../components/custom-tab-panel/CustomTabPanel';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';

const MAX_DEMAND_ROW_CARD = ['ap_maxd', 'rp_maxd', 'apr_maxd']
const MAX_DEMAND_ROW_CARD_COLOR = {
    ap_maxd: '#D1FADF',
    rp_maxd: '#FEF0C7',
    apr_maxd: '#E7E7FF',
};

const MIN_DEMAND_ROW_CARD = ['ap_mind', 'rp_mind']
const MIN_DEMAND_ROW_CARD_COLOR = {
    ap_mind: '#D1FADF',
    rp_mind: '#FEF0C7',
};

type LineChartState = {
    maxDemandChart: ChartData;
    minDemandChart: ChartData;
};

const initialChartData: ChartData = {
    seriesData: [],
    categories: [],
};

const initialActiveLineChartState: LineChartState = {
    maxDemandChart: initialChartData,
    minDemandChart: initialChartData,
};

const names1: string[] = [];
const keyNames1: string[] = [];
const colors1 = ['f542e9', 'DD0000', 'E7E7FF'];
const yAxis1: number[] = [];
const postFix1: string[] = [];

const names2: string[] = [];
const keyNames2: string[] = [];
const colors2 = ['117DAE', 'DD0000'];
const postFix2: string[] = [];


const Demand: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], sensorInfo }) => {
    const [value, setValue] = React.useState(0);
    const { currentUser } = useCurrentUserState();
    const [lineChart, setLineChart] = useState<LineChartState>(initialActiveLineChartState);
    const workerRefLineChart1 = useRef<Worker | null>(null);
    const workerRefLineChart2 = useRef<Worker | null>(null);
    const workerRefLineChart3 = useRef<Worker | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const maxDemandActiveTags = MAX_DEMAND_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names1.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names1.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames1.includes(tag)) {
                keyNames1.push(tag);
            }
            postFix1.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status
    })

    const minDemandActiveTags = MIN_DEMAND_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names2.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names2.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames2.includes(tag)) {
                keyNames2.push(tag);
            }
            postFix2.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status
    });

    const handleDownloadOptionClick = (option: string, key: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
        ];
        let id = ""
        if (key === "MAX_DEMAND") {
            maxDemandActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "maxDemandChart-container"
        } else if (key === "MIN_DEMAND") {
            minDemandActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
            id = "minDemandChart-container"
        }
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable(id, filterData, currentUser.appLogo);
        }
    };

    useEffect(() => {
        workerRefLineChart1.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart2.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart3.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart1.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                maxDemandChart: { seriesData, categories },
            }));
        };

        workerRefLineChart2.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart((prevCharts) => ({
                ...prevCharts,
                minDemandChart: { seriesData, categories },
            }));
        };

        if (workerRefLineChart1.current && data.length > 0 && value === 0) {
            workerRefLineChart1.current.postMessage({
                data: data,
                names: names1,
                keyNames: keyNames1,
                colors: colors1,
                yAxis: yAxis1,
            })
        }

        if (workerRefLineChart2.current && data.length > 0) {
            workerRefLineChart2.current.postMessage({
                data: data,
                names: names2,
                keyNames: keyNames2,
                colors: colors2,
            })
        }

        return () => {
            if (workerRefLineChart1.current) {
                workerRefLineChart1.current.terminate();
                workerRefLineChart1.current = null;
            }
            if (workerRefLineChart2.current) {
                workerRefLineChart2.current.terminate();
                workerRefLineChart2.current = null;
            }
        };

    }, []);

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {maxDemandActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={MAX_DEMAND_ROW_CARD_COLOR[tag as keyof typeof MAX_DEMAND_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {minDemandActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={MIN_DEMAND_ROW_CARD_COLOR[tag as keyof typeof MIN_DEMAND_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

            <Box sx={{ width: '100%', backgroundColor: "white" , marginTop: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Max Demand"} {...a11yProps(0)} />
                        <Tab label={"Min Demand"} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "MAX_DEMAND")}
                                id={1}
                            />
                        </div>
                        <p>
                            {`Power data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                        </p>
                    </div>

                    <div id="maxDemandChart-container">
                        <SplineChart
                            title={`Power Summary`}
                            categories={lineChart.maxDemandChart.categories}
                            series={lineChart.maxDemandChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            maxCategories={12}
                            postFixUnit={postFix1}
                        />
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "MIN_DEMAND")}
                                id={1}
                            />
                        </div>
                        <p>
                            {`Power data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                        </p>
                    </div>

                    <div id="minDemandChart-container">
                        <SplineChart
                            title={`Power Summary`}
                            categories={lineChart.minDemandChart.categories}
                            series={lineChart.minDemandChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            maxCategories={12}
                            postFixUnit={postFix2}
                        />
                    </div>
                </CustomTabPanel>
            </Box>

        </>
    )
}

export default Demand;