import { useState } from 'react'
import LogsFilter from './components/LogsFilter'
import CurrentDate from './components/CurrentDate'
import LogsActivity from './components/LogsActivity'

const LogsPage = () => {
  const [filters, setFilters] = useState<{ activityType?: string; startDate?: string; endDate?: string; searchTerm?: string; }>({});

  const handleSearchChange = (searchTerm: string) => {
    setFilters((prev) => ({ ...prev, searchTerm })); // Update filters with the search term
  };
  return (
    <div>
      <LogsFilter onFilterChange={setFilters} onSearchChange={handleSearchChange} />
      <CurrentDate />
      <LogsActivity filters={filters} />
    </div>
  )
}

export default LogsPage
