import React, {  useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/button/Button';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../../../components/container/Container';
import axios from 'axios';
import { baseUrlUser } from '../../../utils/env/env.config';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';

const OtpForm = () => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const formik = useFormik({
    initialValues: {
      otp: ['', '', '', '', '', ''],
    },
    validationSchema: Yup.object({
      otp: Yup.array().of(
        Yup.string()
          .matches(/^[0-9]$/, 'Invalid OTP')
          .required('OTP is required')
      ),
    }),

    onSubmit: async (values) => {
      const enteredOTP = values.otp.join(''); // Combine individual digits into a string
      const otpToken = localStorage.getItem('otp_token');
      console.log('OTP Token:', otpToken); // Log the token for debugging
      if (!otpToken) {
        showToast('No OTP token found. Please request OTP again.', 'error');
        return;
      }

      try {
        const response = await axios.post(baseUrlUser+
          `forgot-password/verify-otp/${otpToken}/${enteredOTP}`
        );

        if (response.status === 200) {
          console.log('OTP Verified:', response.data);
          localStorage.setItem('reset_token', response.data.resetToken);
          showToast('OTP verified successfully!', 'success');
          navigate('/reset-password');
        } else {
          console.error('Unexpected response:', response);
          showToast('OTP verification failed. Please try again.', 'error');
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          console.error('OTP Verification Failed:', error.response?.data?.message || error.message);
          showToast(error.response?.data?.message || 'Failed to verify OTP.', 'error');
        } else {
          console.error('Unexpected Error:', error);
          showToast('An unexpected error occurred.', 'error');
        }
      }
    },

  });

  // Function to handle input change and focus management
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...formik.values.otp];
      newOtp[index] = value;
      formik.setFieldValue('otp', newOtp);

      // Move focus to the next input field if not the last one and value is not empty
      if (value !== '' && index < formik.values.otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && formik.values.otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };


  useEffect(() => {
    const handleBeforeUnload = () => {

      // localStorage.removeItem('otp_token');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);




  return (
    <Container heading="Enter OTP">
    <form onSubmit={formik.handleSubmit} className="w-full space-y-2">
      <div className="flex mb-4 space-x-2">
        {/* Use an array of input fields for OTP */}
        {formik.values.otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            maxLength={1}
            id={`otp-input-${index}`}
            className="p-4 border border-gray-300 text-center w-12 focus:outline-none"
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputRefs.current[index] = el)} // Assign refs
          />
        ))}
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <Link to="/" className="text-sm text-deepcerulan-600 hover:text-deepcerulan-500 underline">
          Back to Login
        </Link>
      </div>
      <div className="mt-2">

        <Button
          type="submit"
          variant="primary" size="medium"
        >
          Submit
        </Button>
      </div>
    </form>
    </Container>
  );
};

export default OtpForm;
