import { ApiResponseRole, Role, RoleData } from '../../types/Role';
import { baseUrlUser } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';

interface AddRoleAction {
  type: 'ADD_ROLE';
  payload: { role: Role; totalItems: number };
}

interface SetRoleAction {
  type: 'SET_ROLE';
  payload: RoleData;
}

interface SetRolePageAction {
  type: 'SET_ROLE_WITH_PAGE';
  payload: RoleData;
}

interface UpdateRoleAction {
  type: 'UPDATE_ROLE';
  payload: { id: string; role: Role };
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

export type RoleAction =
  | AddRoleAction
  | SetRoleAction
  | SetRolePageAction
  | UpdateRoleAction
  | SetLoadingAction;

export async function setRole(
  dispatch: React.Dispatch<RoleAction>,
  payload: {
    page: number;
    limit: number;
    search?: string;
    clientId: string;
  }
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { clientId, search, limit, page } = payload;
    let queryParams = `?page=${page}&limit=${limit}`;
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }
    const response = (await http(
      `${baseUrlUser}${clientId}/roles${queryParams}`
    )) as ApiResponseRole;
    const { limit: itemPerPage } = response;
    const { data } = response;
    const dataWithLimit = { ...data, itemPerPage };
    dispatch({
      type: 'SET_ROLE',
      payload: dataWithLimit,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewRole(
  dispatch: React.Dispatch<RoleAction>,
  payload: { role: Role; totalItems: number }
) {
  dispatch({
    type: 'ADD_ROLE',
    payload,
  });
}

export function updateRole(
  dispatch: React.Dispatch<RoleAction>,
  payload: { id: string; role: Role }
) {
  dispatch({
    type: 'UPDATE_ROLE',
    payload,
  });
}
