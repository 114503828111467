import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput, ColumnInput } from "jspdf-autotable"; // Ensure correct types are imported
import * as XLSX from 'xlsx';
import { fetchImageAsBase64 } from '../generate-pdf/generatePdf';

type TransformedEntry = {
  [key: string]: string | number | null;
};

export const generateGraphPDFWithDataTable = async (
  elementId: string, // ID of the chart element
  data: TransformedEntry[], // Transformed data
  logoImageUrl: string, //
  fileName: string = "report.pdf" // File name for the generated PDF
): Promise<void> => {
  try {
    const pdf = new jsPDF({
      compress: true, // Enable compression
    });

    pdf.setFontSize(16);
    pdf.text("Frinso", pdf.internal.pageSize.width - 20, 10, { align: 'right' });

    if (logoImageUrl) {
      try {
        const logoBase64 = await fetchImageAsBase64(logoImageUrl);

        const logoWidth = 30; // Adjust size to reduce file size
        const logoHeight = 15;

        // Add the logo closer to the top of the page by reducing the Y position (top offset)
        pdf.addImage(logoBase64, 'PNG', 10, 15, logoWidth, logoHeight, undefined, 'FAST', 0.6);
      } catch (error) {
        console.error('Error loading the logo:', error);
      }
    }

    const pageWidth = pdf.internal.pageSize.getWidth();

    pdf.setFont("helvetica");
    pdf.setFontSize(12);

    // Add a heading with reduced space from the logo
    pdf.setFontSize(15).setFont("helvetica", "bold");
    pdf.text("Device Report Summary", pageWidth / 2, 40, { align: "center" });

    // Capture the chart element as an image
    const element = document.getElementById(elementId);

    const chartPositionY = 60; // Reduced position for the chart
    if (element) {
      const canvas = await html2canvas(element, {
        scale: 1.5, // Adjust scale for resolution
        useCORS: true, // Handle cross-origin requests if applicable
      });
      const chartImgData = canvas.toDataURL("image/jpeg", 0.75);
      pdf.addImage(chartImgData, "JPEG", 15, chartPositionY, 180, 100);
    }

    // Table generation with reduced space
    const tableStartY = 170; // Adjusted to reduce space below the chart

    if (data.length > 0) {
      // Define columns and rows for the table
      const tableColumns: ColumnInput[] = Object.keys(data[0]).map((key) => ({
        header: key.toUpperCase(),
        dataKey: key,
      }));

      const tableRows: RowInput[] = data.map((entry) => ({
        ...entry,
      }));

      autoTable(pdf, {
        columns: tableColumns,
        body: tableRows,
        startY: tableStartY,
      });
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

    // Footer with generated date and author
    pdf.setFontSize(10);
    pdf.text(`Report generated by Frinso on ${formattedDate}`, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, { align: 'center' });

    // Save the PDF
    pdf.save(fileName);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};




export const generateExcelWithDataTable = (
  data: TransformedEntry[], // Transformed data
  fileName: string = "report.xlsx" // File name for the generated Excel file
): void => {
  try {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Device Report');

    // Generate the Excel file and download it
    XLSX.writeFile(wb, fileName);
  } catch (error) {
    console.error("Error generating Excel:", error);
  }
};
