import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import SensorCard from '../../../../../components/card/SensorCard'
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../../../types/a11yProps';
import CustomTabPanel from '../../../../../components/custom-tab-panel/CustomTabPanel';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';


const VOLTAGE_1ST_ROW_CARD = ['avg_vtg', 'r_ph_vtg', 'y_ph_vtg', 'b_ph_vtg']
const VOLTAGE_1ST_ROW_CARD_COLOR = {
    avg_vtg: '#D1FADF',
    r_ph_vtg: '#FEF0C7',
    y_ph_vtg: '#E7E7FF',
    b_ph_vtg: '#D1FADF'
} as const;
const VOLTAGE_2ND_ROW_CARD = ['ry_ph_vtg', 'yb_ph_vtg', 'rb_ph_vtg']
const VOLTAGE_2ND_ROW_CARD_COLOR = {
    ry_ph_vtg: '#FEF0C7',
    yb_ph_vtg: '#E7E7FF',
    rb_ph_vtg: '#D1FADF',
} as const;

const names: string[] = [];
const keyNames: string[] = [];
const colors = ['f542e9', 'DD0000', 'E7E7FF', 'D1FADF'];
const yAxis: number[] = [];
const postFix: string[] = [];

const names2: string[] = [];
const keyNames2: string[] = [];
const colors2 = ['117DAE', 'DD0000', 'E7E7FF', 'D1FADF'];
// const yAxis2: number[] = [];
const postFix2: string[] = [];


const Voltage: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, dashBoardSts, data = [] }) => {
    const [value, setValue] = React.useState(0);
    const {currentUser}=useCurrentUserState()
    const [lineWiseChart, setLineWiseChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });
    const [lineToLineChart, setLineToLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });
    const workerRefLineChart1 = useRef<Worker | null>(null);
    const workerRefLineChart2 = useRef<Worker | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const firstRowCardActiveTags = VOLTAGE_1ST_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames.includes(tag)) {
                keyNames.push(tag);
            }
            // if (!postFix.includes(sensorInfo?.[tag]?.displaySensorUnit)) {
                postFix.push(sensorInfo?.[tag]?.displaySensorUnit);
            // }
            // if (!yAxis.includes(0)) { 
            //     yAxis.push(0);
            // }
        }
        return sensorInfo?.[tag]?.status
    })
    const secondRowCardActiveTags = VOLTAGE_2ND_ROW_CARD.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names2.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names2.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames2.includes(tag)) {
                keyNames2.push(tag);
            }
            // if (!postFix2.includes(sensorInfo?.[tag]?.displaySensorUnit)) {
                postFix2.push(sensorInfo?.[tag]?.displaySensorUnit);
            // }
            // if (!yAxis.includes(0)) { 
            //     yAxis.push(0);
            // }
        }
        return sensorInfo?.[tag]?.status
    })

    const handleDownloadOptionClick = (option: string, key: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
        ];
        if(key === "LINE_WISE"){
            firstRowCardActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
        } else {
            secondRowCardActiveTags.map((tag) => {
                config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
            })
        }
        const filterData = transformDataForGraphPdfExcel(data, config)
        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable( key === "LINE_WISE" ? "lineChart-container1" : 'lineChart-container2', filterData, currentUser.appLogo);
        }
    };

   
    useEffect(() => {
        workerRefLineChart1.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart2.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart1.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineWiseChart({ seriesData, categories })
        };


        workerRefLineChart2.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineToLineChart({ seriesData, categories })
        };

        if (workerRefLineChart1.current && data.length > 0 && value === 0) {

            workerRefLineChart1.current.postMessage({
                data: data,
                names,
                keyNames,
                colors,
                yAxis,
            })
        }

        if (workerRefLineChart2.current && data.length > 0) {
            workerRefLineChart2.current.postMessage({
                data: data,
                names: names2,
                keyNames: keyNames2,
                colors: colors2,
                yAxis,
            })
        }

        return () => {
            if (workerRefLineChart1.current) {
                workerRefLineChart1.current.terminate();
                workerRefLineChart1.current = null;
            }
            if (workerRefLineChart2.current) {
                workerRefLineChart2.current.terminate();
                workerRefLineChart2.current = null;
            }
        };
    }, []);

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">

                {firstRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={VOLTAGE_1ST_ROW_CARD_COLOR[tag as keyof typeof VOLTAGE_1ST_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                <SensorCard
                    key={''}
                    sensor={''}
                    value={''}
                    unit={''}
                    bg_color={''}
                />
                {secondRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={VOLTAGE_2ND_ROW_CARD_COLOR[tag as keyof typeof VOLTAGE_2ND_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <Box sx={{ width: '100%', backgroundColor: "white" , marginTop: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"Line Wise"} {...a11yProps(0)} />
                        <Tab label={"Line To Line"} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel
                    value={value} index={0}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            {/* Placeholder for potential menu */}
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "LINE_WISE")}

                                id={1}
                            />
                        </div>
                        <p>
                            {`Voltage data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                                })`}
                        </p>
                    </div>


                    <div
                        id="lineChart-container1"
                    >
                        <SplineChart
                            title={`Voltage Summary`}
                            categories={lineWiseChart.categories}
                            series={lineWiseChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            // tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                            maxCategories={12}
                            postFixUnit={postFix}
                        />
                    </div>
                </CustomTabPanel>
                <CustomTabPanel
                    value={value} index={1}>
                    <div className="relative p-2">
                        <div className="absolute top-0 right-0">
                            {/* Placeholder for potential menu */}
                            <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(option: string) => handleDownloadOptionClick(option, "LINE_TO_LINE")}
                                
                                id={1}
                            />
                        </div>
                        <p>
                            {`Voltage data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                                })`}
                        </p>
                    </div>


                    <div
                        id="lineChart-container2"
                    >
                        <SplineChart
                            title={`Voltage Summary`}
                            categories={lineToLineChart.categories}
                            series={lineToLineChart.seriesData}
                            yAxisTitle="Data"
                            xAxisTitle="Time"
                            height={300}
                            // tooltipUnit={sensorInfo[tag]?.displaySensorUnit || ''}
                            maxCategories={12}
                            postFixUnit={postFix2}
                        />
                    </div>
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default Voltage