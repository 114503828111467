import React, { Suspense, useState, useRef, lazy } from 'react';
import ActiveInactiveChart from '../../../components/chart/round-chart/ActiveInactiveChart';
import alert_circle from "../../../../src/assets/dashboard/alert_circle.png";
import SearchAndActions from '../../../components/search-action/SearchAndActions';
import Modal from '../../../components/modal/Modal';
const LazyAddSubscribeForm = lazy(() => import('../components/AddSubscribe'));

function SubscriptionCards() {
  const chartData = [
    { name: 'Paid', count: 10000, color: '#3B82F6' },
    { name: 'Outstanding', count: 5000, color: '#EF4444' },
  ];

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addSubscribeModal, setAddSubscribeModal] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      console.log('Search Term:', event.target.value);
    }, 500);
  };

  const handleAddNew = () => {
    setAddSubscribeModal(true);
  };

  const handleClose = () => {
    setAddSubscribeModal(false);
  };

  return (
    <div>
      <div className="flex justify-between space-x-4 mt-4">
        {['Organization', 'Vendor', 'Device'].map((label, index) => (
          <div key={index} className="rounded-lg bg-white shadow-md p-4 w-1/3">
            <h5 className="text-sm font-semibold mb-4 flex items-center">
              {label}
              <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
            </h5>
            <Suspense fallback={<div>Loading...</div>}>
              <ActiveInactiveChart
                data={chartData}
                height={200}
              />
            </Suspense>
          </div>
        ))}
      </div>
      <div>
      <div className='mt-5'> 
        <SearchAndActions
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          onAddNew={handleAddNew}
          onExport={() => console.log('Export action')}
          handelAdd={true} 
        />
      </div>
      <Modal toggleModal={addSubscribeModal} onClose={handleClose}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddSubscribeForm action="ADD_NEW" onClose={handleClose} />
        </Suspense>
      </Modal>
      </div>
    </div>
  );
}

export default SubscriptionCards;
