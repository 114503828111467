import { useState } from "react";
import { DashboardEnergyMonitoringProps } from "../../energy-monitoring-2/types/EnergyMonitoringProps";
import { Box, Tab, Tabs } from "@mui/material";
import a11yProps from "../../../../types/a11yProps";
import MeterReading from "./MeterReading/MeterReading";
import CustomTabPanel from "../../../../components/custom-tab-panel/CustomTabPanel";
import DailyConsumption from "../../energy-monitoring-2/analysis/DailyConsumption/DailyConsumption";

const Analysis: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], deviceState, sensorInfo }) => {
  const [analysisTabValue, setAnalysisTabValue] = useState(0);

  const handleAnalysisTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setAnalysisTabValue(newValue);
  };

  return (
    <>

      <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
        <Tabs value={analysisTabValue} onChange={handleAnalysisTabChange} aria-label="Sub tabs for Analysis">
          <Tab label={"Meter Reading"} {...a11yProps(0)} />
          <Tab label={"Daily Consumption"} {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={analysisTabValue} index={0}>
        <MeterReading dashBoardSts={dashBoardSts} data={data} deviceState={deviceState} sensorInfo={sensorInfo} />
      </CustomTabPanel>

      <CustomTabPanel value={analysisTabValue} index={1}>
        <DailyConsumption dashBoardSts={dashBoardSts} data={data} deviceState={deviceState} sensorInfo={sensorInfo} />
      </CustomTabPanel>
    
    </>
  )
}

export default Analysis;