import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import SearchAndActions from '../../components/search-action/SearchAndActions'
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
const VendersTable = lazy(() => import('./components/VendersTable'));
import { useSiteState } from '../../app-context/site-context/VendorState';
import { setSite } from '../../app-context/site-context/VendorAction';
import Modal from '../../components/modal/Modal';
import Loader from '../../components/loader/Loader';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
import downloadFile from '../../utils/csv-pdf-interceptor/download-file';
import { baseUrlTenant } from '../../utils/env/env.config';
import { RoleBack } from '../../types/Role';
const LazyAddNewVenderForm = lazy(() => import('./components/AddNewVenderForm'))

const Venders = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.vendor?.canCreate || false
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { currentUser } = useCurrentUserState()
  const { vendor, dispatch, loading } = useSiteState()
  const [addVenderModal, setAddVenderModal] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    if (vendor) {
      if ((vendor.vendor.length === 0 && currentUser.clientId)) {
      setSite(dispatch, {
        page: 1,
        limit: 10,
        search: '',
        organizationId: currentUser.organizationId || '',
        vendorId: currentUser.vendorId || '',
        role: currentUser.role.name,
      })
    }
    }
  }, [currentUser.clientId])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (currentUser.clientId) {
          timerRef.current = setTimeout((): void => {
            setSite(dispatch, {
              page: 1,
              limit: 10,
              search: event.target.value,
              organizationId: currentUser.organizationId || '',
              vendorId: currentUser.vendorId || '',
              role: currentUser.role.name,
            })
          }, 500);
        }
      };
    
  //   if (currentUser.clientId) {
  //   timerRef.current = setTimeout((): void => {
  //     setSite(dispatch, {
  //       page: 1,
  //       limit: 10,
  //       search: event.target.value,
  //       organizationId: currentUser.organizationId || '',
  //       vendorId: currentUser.vendorId || '',
  //       role: currentUser.role.name,
  //     })
  //   }, 500);
  // };

  const handleAddNew = () => {
    setAddVenderModal(true);
  };

  const handleExport = async () => {
    const role = currentUser.role.name;
    
    let queryParams = `/sites/export-excel?`;
    if (role === RoleBack.ORGANIZATIONADMIN && currentUser.organizationId) {
      queryParams += `organizationId=${currentUser.organizationId}&`;
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `vendorId=${currentUser.vendorId}&`;
    } else if (role === RoleBack.USER) {
      queryParams += `userId=${currentUser._id}&`;
    }
  
    if (searchTerm !== '' && searchTerm !== undefined) {
      queryParams += `search=${searchTerm}`;
    }

    try {
      await downloadFile(
        `${baseUrlTenant}${queryParams}`,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'vendor.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      console.error("Export failed:", error);
      setDownloadProgress(0);
    } finally {
      setDownloadProgress(0);
    }
  };
  
  // const handleExport = async () => {
  //   const role = currentUser.role.name
  //   let queryParams = `/sites/export-excel?`
  //   if (role === RoleBack.ORGANIZATIONADMIN && currentUser.organizationId) {
  //     queryParams += `organizationId=${currentUser.organizationId}&`;
  //   } else if (role === RoleBack.SITEADMIN) {
  //     queryParams += `vendorId=${currentUser.vendorId}&`;
  //   } else if (role === RoleBack.USER) {
  //     queryParams += `userId=${currentUser._id}&`;
  //   }

  //   if (searchTerm !== '' && searchTerm !== undefined) {
  //     queryParams += `search=${searchTerm}`;
  //   }
  //   try {
  //     await downloadFile(
  //       baseUrlTenant + queryParams,
  //       (progress) => {
  //         setDownloadProgress(progress);
  //       },
  //       'GET',
  //       {},
  //       'vendor.xlsx'
  //     );
  //     setDownloadProgress(0);
  //   } catch (error) {
  //     setDownloadProgress(0);
  //   } finally {
  //     setDownloadProgress(0);

  //   }

  // };
  return (
    <div className="p-1 py-2">
      {(loading || downloadProgress > 0) && <Loader isBarCircle={(loading || downloadProgress > 0)}/>}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <VendersTable />
      </Suspense>
      <Modal
        // sx={customSx}
        toggleModal={addVenderModal}
        onClose={() => {
          setAddVenderModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewVenderForm
            onClose={() => {
              setAddVenderModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Venders