import React, { useEffect, useRef, useState } from 'react'
import SearchAndActions from '../../../components/search-action/SearchAndActions'
import CommonTable from '../../../components/table/Table'
import Loader from '../../../components/loader/Loader'
import { Column, DataRow } from '../../../types/table'
import { ApiResponseTata, TataItem } from '../../../types/ServerIntegration'
import { http } from '../../../utils/helper/http'
import { baseUrlDevice } from '../../../utils/env/env.config'
import moment from 'moment-timezone'

const columns: Column[] = [
  { id: 'deviceName', label: 'Device Name' },
  { id: 'status', label: 'Status' },
  { id: 'lastPublishTime', label: 'Last Public Time' },
]

const TataTable = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [tataData, setTataData] = useState<TataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const fetchTataData = async (currentPage: number, currentLimit: number, search: string) => {
    setLoading(true);
    try {
      let query = `${baseUrlDevice}tata?page=${currentPage}&limit=${currentLimit}`
      if (search && search !== '') {
        query += `&search=${search}`
      }
      const response = await http(query, 'GET')
      const tataResponse = response as ApiResponseTata;
      setTataData(tataResponse.data?.tata);
      const total = tataResponse.data?.totalItems || 0;
      setCurrentPage(tataResponse.data?.currentPage);
      setTotalPages(tataResponse.data?.totalPages);
      setTotalItems(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTataData(page, rowsPerPage, searchTerm);
  }, [page, rowsPerPage, timerRef])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage)

  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (newSearchTerm === '') {
      setPage(1);
      setRowsPerPage(10);
      fetchTataData(1, 10, '');
    } else {
      timerRef.current = setTimeout((): void => {
        fetchTataData(page, rowsPerPage, newSearchTerm);
      }, 500);
    }
  };

  const handleExport = () => {
    console.log("you clicked me on export button");
  }

  const formatLastPublishTime = (dateString: string | undefined): string => {
    return dateString ? moment(dateString).format("DD/MM/YYYY HH:mm:ss") : "N/A";
  };

  const tableData: DataRow[] = tataData.map((row) => ({
    ...row,
    'deviceName': row.deviceDetails?.deviceName || 'N/A',
    'status': row.lastPublishData?.status || 'N/A',
    'lastPublishTime': formatLastPublishTime(row.lastPublishTime || 'N/A'),

  }));
  return (
    <div className="p-1 py-2">
      {loading && <Loader isBarCircle={loading} />}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onExport={handleExport}
      />

      <CommonTable
        columns={columns}
        data={tableData}
        title="Tata Information"
        pagination={{
          rowsPerPageOptions: [10, 25, 50, 100, 500],
          rowsPerPage: rowsPerPage,
          page: currentPage,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: totalItems,
          currentPage: currentPage,
          totalPages: totalPages,
        }}
        setHeight={totalItems >= 8 ? "74vh" : "auto"}
      />
    </div>

  )
}

export default TataTable;
