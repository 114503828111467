import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';
import FirstRowCard from '../components/FirstRowCard';
import { ConsumptionItem } from '../../../../../workers/consumption-data-worker/consumptionWorker';
import BarChart from '../../../../../components/chart/bar-chart/BarChart';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
const METER_READING_1ST_ROW_CARD_TAGS = ['active_energy', 'reactive_energy', 'apparent_energy']

const toolTip: string[] = [];
const MeterReading: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, dashBoardSts, data = [], maxDemand, maxDemandTime }) => {
  const workerRefProcessorLinechart1 = useRef<Worker | null>(null);
  const workerRefConsumption = useRef<Worker | null>(null);
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const workerRefConProcessor = useRef<Worker | null>(null);
  const { currentUser } = useCurrentUserState()
  const handleDownloadOptionClickLinechart = (option: string) => {
    const config = [
      { id: "timeStamp", label: "Timestamp" },
    ];
    activeTags.map((tag) => {
      config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(data, config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("lineChart-container", filterData, currentUser.appLogo);
    }
  };

  const handleDownloadConcumptionBargraph = (option: string) => {
    const config = [
      { id: "hour", label: "Timestamp" },
    ];
    activeTags.map((tag) => {
      config.push({ id: "consumption" + tag, label: sensorInfo?.[tag].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(consumptionData || [], config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("barChart-container", filterData, currentUser.appLogo);
    }
  };
  const [lineChart, setLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });
  const [consumptioBarChart, setConsumptionBarChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });
  const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
    return sensorInfo?.[tag]?.status;
  });




  useEffect(() => {
    workerRefProcessorLinechart1.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );

    workerRefConsumption.current = new Worker(
      new URL(
        '../../../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );

    workerRefConProcessor.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart({ categories, seriesData });
    };
    workerRefProcessorLinechart1.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setLineChart({ categories, seriesData });
    };
    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };
    return () => {
      if (workerRefProcessorLinechart1.current) {
        workerRefProcessorLinechart1.current.terminate();
        workerRefProcessorLinechart1.current = null;
      }
      if (workerRefConsumption.current) {
        workerRefConsumption.current?.terminate();
        workerRefConsumption.current = null;
      }
      if (workerRefConProcessor.current) {
        workerRefConProcessor.current?.terminate();
        workerRefConProcessor.current = null;
      }

    };
  }, [consumptioBarChart]);


  useEffect(() => {
    if (workerRefProcessorLinechart1.current && data.length > 0) {
      if (activeTags.length > 0) {
        const names = activeTags.map(tag => sensorInfo?.[tag]?.displaySensorName).filter(name => name !== undefined);
        activeTags.forEach(tag => {
          const sensorUnit = sensorInfo?.[tag]?.displaySensorUnit || '';
          if (sensorUnit !== undefined) {
            toolTip.push(sensorUnit);
          }
        });
        const keyNames = [...activeTags];
        const colors = ['2980b9', 'FEF0C7', 'E7E7FF'];
        workerRefProcessorLinechart1.current.postMessage({
          data: [...data],
          names,
          keyNames,
          colors,
        });
      }
    }

    if (workerRefConsumption.current && data.length > 0) {
      workerRefConsumption.current.postMessage({
        deviceData: [...data].reverse(),
        tagName: activeTags,
      });
    }

  }, [data])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {

      const reversedConsumptionData = [...consumptionData].reverse();
      const names: string[] = [];
      const keyNames: string[] = [];
      activeTags.forEach(tag => {
        const sensorName = sensorInfo?.[tag]?.displaySensorName;
        if (sensorName) {
          names.push(sensorName); // Add to names array
          keyNames.push("consumption" + tag); // Add the key (tag) to keyNames array
        }
      });
      const colors = ['2980b9', 'FEF0C7', 'E7E7FF'];
      const categories = reversedConsumptionData.map((item) => item.hour)
      workerRefConProcessor.current.postMessage({
        data: reversedConsumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'hour',
        categories
      });
    }
  }, [consumptionData]);

  return (

    <>
      <FirstRowCard maxDemand={maxDemand} maxDemandTime={maxDemandTime} action='METER_READING' dashBoardSts={dashBoardSts} sensorInfo={sensorInfo} />
      {activeTags.length > 0 &&
        <>
          <div className='bg-white border border-1 mt-2'>
            <div className="relative p-2">
              <div className="absolute top-0 right-0">
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={handleDownloadOptionClickLinechart}
                  id={1}
                />
              </div>
              <p>
                {`Energy data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                  })`}
              </p>
            </div>
            <div
              id="lineChart-container"
            >
              <SplineChart
                title={`${activeTags?.map(tag => sensorInfo?.[tag].displaySensorName).join(", ") || "No sensors"} summary`}
                categories={lineChart?.categories || []}
                series={lineChart?.seriesData || []}
                yAxisTitle="Data"
                xAxisTitle="Time"
                height={350}
                tooltipUnit={''}
                maxCategories={12}
                postFixUnit={toolTip as string[]}
              />
            </div>
          </div>


          <div className='bg-white border border-1'>
            <div className="relative p-2">
              <div className="absolute top-0 right-0">
                {/* Placeholder for potential menu */}
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={handleDownloadConcumptionBargraph}
                  id={1}
                />
              </div>
              <p>
                {`Hourly energy  data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                  })`}
              </p>
            </div>
            <div
              id="barChart-container"
            >
              <BarChart
                title="Consumption Summary"
                categories={consumptioBarChart.categories}
                series={consumptioBarChart.seriesData}
                height={300}
                xAxisTitle="Time"
                yAxisTitle="Data"
                postFixUnit={toolTip}
              />
            </div>
          </div>

        </>
      }


    </>
  )
}

export default MeterReading