import React, { useState } from 'react'
import Button from '../../components/button/Button'
import GenerateReport from './generate-reports/GenerateReport';
import ScheduleReportList from './schedule-report/ScheduleReportList';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
// import { Outlet } from 'react-router-dom';

const Reports = () => {
const { permissions } = usePermissionState()  
  const [activeGeneralReport, setIsActiveGeneralReport] = useState(true);
  const [activeScheduleReport, setIsActiveScheduleReport] = useState(false);
  const meterRedingMenu = permissions?.meter_reading?.canMenu || false
  const Consumption_summaryMenu = permissions?.consumption_summary?.canMenu || false
  const Consumption_summary_2Menu = permissions?.consumption_summary_2?.canMenu|| false
  const Statistic_summaryMenu = permissions?.statistic_summary?.canMenu || false


  const handleGeneralReportClick = () => {
    setIsActiveGeneralReport(true);
    setIsActiveScheduleReport(false);
  };

  const handleScheduleReportClick = () => {
    setIsActiveGeneralReport(false);
    setIsActiveScheduleReport(true);
  };

  const canMenuGenerateList = meterRedingMenu || Consumption_summaryMenu || Consumption_summary_2Menu || Statistic_summaryMenu;
  return (
    <>
      <div className='py-4 px-8'>
        
        {canMenuGenerateList && (
        <div className='switch-container flex'>
          <Button type='button' variant='outline' size='medium' width='200px' onClick={handleGeneralReportClick}  active={activeGeneralReport} >Generate Report</Button>
          <span className='p-4'></span>
          <Button type='button' variant='outline' size='medium' width='200px' onClick={handleScheduleReportClick} active={activeScheduleReport}>Schedule Report List</Button>
        </div>
        )}
        <div className='py-8'>
          {activeGeneralReport && (
            <div className='generate-report-section'>
              <GenerateReport />
            </div>
          )}
          {activeScheduleReport && (
            <div className='schedule-report-section'><ScheduleReportList/></div>
          )}
        </div>
        {/* Nested routes for ReportFormats will be rendered here
        <Outlet /> */}
      </div>
    </>
  )
}

export default Reports