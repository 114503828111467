import React from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import SensorCard from '../../../../../components/card/SensorCard';

const OTHERS_1ST_ROW_CARD_TAGS = ['r_hour', 'a_intrpt'];
const OTHERS_1ST_ROW_CARD_TAGS_COLOR = {
    r_hour: '#D1FADF',
    a_intrpt: '#FEF0C7',
};

const OTHERS_2ND_ROW_CARD_TAGS = ['max_iv1n', 'max_iv2n', 'max_iv3n'];
const OTHERS_2ND_ROW_CARD_TAGS_COLOR = {
    max_iv1n: '#D1FADF',
    max_iv2n: '#FEF0C7',
    max_iv3n: '#E7E7FF',
};

const OTHERS_3RD_ROW_CARD_TAGS = ['max_iv12', 'max_iv23', 'max_iv31'];
const OTHERS_3RD_ROW_CARD_TAGS_COLOR = {
    max_iv12: '#D1FADF',
    max_iv23: '#FEF0C7',
    max_iv31: '#E7E7FF',
};

const OTHERS_4TH_ROW_CARD_TAGS = ['max_ici1', 'max_ici2', 'max_ici3'];
const OTHERS_4TH_ROW_CARD_TAGS_COLOR = {
    max_ici1: '#D1FADF',
    max_ici2: '#FEF0C7',
    max_ici3: '#E7E7FF',
};

const Other: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, dashBoardSts }) => {

    const firstRowCardActiveTags = OTHERS_1ST_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    const secondRowCardActiveTags = OTHERS_2ND_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    const thirdRowCardActiveTags = OTHERS_3RD_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    const fourthRowCardActiveTags = OTHERS_4TH_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                {firstRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={OTHERS_1ST_ROW_CARD_TAGS_COLOR[tag as keyof typeof OTHERS_1ST_ROW_CARD_TAGS_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                {secondRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={OTHERS_2ND_ROW_CARD_TAGS_COLOR[tag as keyof typeof OTHERS_2ND_ROW_CARD_TAGS_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                {thirdRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={OTHERS_3RD_ROW_CARD_TAGS_COLOR[tag as keyof typeof OTHERS_3RD_ROW_CARD_TAGS_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                {fourthRowCardActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={OTHERS_4TH_ROW_CARD_TAGS_COLOR[tag as keyof typeof OTHERS_4TH_ROW_CARD_TAGS_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>

        </>
    )
}

export default Other;