export interface SensorConfig {
  parameterName: string;
  registerType: string;
  registerAddress: string;
  slaveId: string;
  scalingFactor: string;
  modbusDataType: string;
  dataType: string;
  dataSize: string;
}

export interface ApiResponseModConfig {
  message: string
}

export interface SlaveAttributes {
  functionCode: string;
  regName: string;
  address: string;
  slaveId: string;
  Scaling_Factor: string;
  modbusDataType: string;
  modbusDataSize: string;
}

export const stopBits = [
    { value: '1', label: '1' },
    { value: '2', label: '2' }
];

export const parityStatus = [
    { value: "0", label: "None (0)" },
    { value: "1", label: "Odd (1)" },
    { value: "2", label: "Even (2)" }
];

export const resgisterType = [
    { value: "0", label: "Holding Register" },
    { value: "1", label: "Input Register" },
    { value: "2", label: "Coil Status" }
];

export const scallingFactor = [
    { value: "0", label: "1" },
    { value: "1", label: "0.1" },
    { value: "2", label: "0.01" },
    { value: "3", label: "0.001" }
];

export const modbusType = [
  { value: "1", label: "16 bits signed Integer (AB)" },
  { value: "2", label: "16 bits unsigned Integer (AB)" },
  { value: "3", label: "32 bits signed Integer (ABCD)" },
  { value: "4", label: "32 bits unsigned Integer (ABCD)" },
  { value: "5", label: "32 bits Float format (ABCD)" },
  { value: "6", label: "32 bits signed Integer (CDAB)" },
  { value: "7", label: "32 bits unsigned Integer (CDAB)" },
  { value: "8", label: "32 bits Float format (CDAB)" }
];

export const dataType = [
  { value: "0", label: "PARAM_TYPE_U8" },
  { value: "1", label: "PARAM_TYPE_U16" },
  { value: "2", label: "PARAM_TYPE_U32" },
  { value: "3", label: "PARAM_TYPE_FLOAT" }
];

export const dataSize = [
  { value: "1", label: "PARAM_TYPE_U8" },
  { value: "2", label: "PARAM_TYPE_U16" },
  { value: "4", label: "PARAM_TYPE_U32 OR FLOAT "}
];