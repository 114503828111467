import React from 'react';
import CommonTable from '../../../../components/table/Table';
import { Column, DataRow } from '../../../../types/table';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";

function StatisticsSummaryTable() {
  const columns: Column[] = [
    { id: 'deviceName', label: 'DEVICE NAME' },
    { id: 'sensors', label: 'SENSORS', align: 'center' },
    { id: 'operator', label: 'OPERATOR', align: 'center' },
    { id: 'periodicity', label: 'PERIODICITY', align: 'center' },
    { id: 'action', label: 'ACTIONS', align: 'center', action: true },
  ];

  // Dummy data for consumption summary
  const StatisticsSummary = {
    summary: [
      {
        deviceName: 'Device A',
        sensors: 'Temperature, Pressure',
        operator: 'Minimum',
        periodicity: 'Hourly',
      },
      {
        deviceName: 'Device B',
        sensors: 'Humidity, Light',
        operator: 'Average',
        periodicity: 'Daily',
      }

    ],
    totalItems: 5,
    currentPage: 1,
    totalPages: 1,
  };

  const data: DataRow[] =
    StatisticsSummary.summary.length > 0
      ? StatisticsSummary.summary.map((row) => ({
        ...row,
        action: (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              paddingLeft: '10px',
              paddingRight: '10px',
              borderRadius: '6px',
              gap: '18px',
              border: '0.2px solid #D5D5D5',
              margin: 'auto',
            }}
            className="cursor-pointer"
          >
            <FaRegEdit
              style={{ fontSize: "20px", color: "#6b7280" }}
              onClick={(e) => {
                e.stopPropagation();
                console.log(`Edit clicked for ${row.deviceName}`);
              }}

            />
            <RiDeleteBinLine
              style={{ color: 'red', fontSize: '20px' }}
              onClick={(e) => {
                e.stopPropagation();
                console.log(`Delete clicked for ${row.deviceName}`);
              }}
            />
          </span>
        ),
      }))
      : [];

  const handlePageChange = (newPage: number) => {
    console.log('Page changed to:', newPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    console.log('Rows per page changed to:', newRowsPerPage);
  };

  return (
    <>
      <CommonTable
        columns={columns}
        data={data}
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowsPerPage: 10,
          page: StatisticsSummary.currentPage,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: StatisticsSummary.totalItems || 0,
          currentPage: StatisticsSummary.currentPage,
          totalPages: StatisticsSummary.totalPages || 0,
        }}
      />
    </>
  );
}

export default StatisticsSummaryTable;
