import React, { useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SeriesData } from '../../../types/Chart';


interface SplineChartProps {
    title: string;
    categories: string[];
    series: SeriesData[];
    xAxisTitle?: string; 
    yAxisTitle?: string; 
    height?: number; 
    tooltipUnit?: string;
    postFixUnit?: string[];
    maxCategories?: number | null;
    id?: string;
  }

  const SplineChart: React.FC<SplineChartProps> = ({ title, id, categories, series,height, yAxisTitle , xAxisTitle, tooltipUnit, postFixUnit,
  maxCategories}) => {
  const [xAxisTickInterval, setXAxisTickInterval] = useState<number | undefined>(undefined);


    const processedSeries = series.map((s) => ({
      ...s,
      data: s.data.map((value) => {
        if (typeof value === 'string') {
          const parsedValue = parseInt(value, 10);
          return isNaN(parsedValue) ? 0 : parsedValue;
        }
        return typeof value === 'number' ? Math.floor(value) : 0;
      }),
    }));
    
    const tickInterval = maxCategories
    ? Math.ceil(categories.length / maxCategories)
    : undefined;

    const options = {
        chart: {
            type: 'spline', 
            height: height,
            zoomType: 'x',
            spacing: [10, 10, 10, 20],
          },
          title: {
            text: title,
          },
          accessibility: {
            enabled: true,
          },
          xAxis: {
            categories: categories,
            title: {
              text: xAxisTitle || 'Categories',
            },
            tickInterval: xAxisTickInterval || tickInterval,
            minTickInterval: 1,
            labels: {
              formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                return categories[+this.pos];
              },
            },
            events: {
              setExtremes: function (e: Highcharts.AxisSetExtremesEventObject) {
                const visibleRange = e.max - e.min;
                if (visibleRange < 3600000) { // Less than an hour in milliseconds
                  setXAxisTickInterval(1); // Show 1-hour intervals when zoomed in
                } else if (visibleRange < 86400000) { // Less than a day in milliseconds
                  setXAxisTickInterval(1); // Show 6-hour intervals
                } else {
                  setXAxisTickInterval(tickInterval); // Default tick interval
                }
              },
            },
          },
          yAxis: {
            title: {
              text: yAxisTitle ? yAxisTitle : 'Values',
            },
            gridLineWidth: 0, // Hide grid lines on y-axis
            lineWidth: 0,
          },

          plotOptions: {
            spline: {
                lineWidth: 3,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                marker: {
                    enabled: false
                },
            }
        },
        series: processedSeries.map((s) => ({
            ...s, 
            color: s.color || undefined, 
          })),
        credits: {
          enabled: false, // Disable the watermark
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          formatter: function(this: Highcharts.TooltipFormatterContextObject){
            const points = this.points?.map((point, i) => {
              return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${tooltipUnit ? tooltipUnit || '' : postFixUnit ? postFixUnit[i] : ''}</b><br/>`;
            }).join('');
            return `<b>${this.x}</b><br/>${points}`;
          }
        }
    }
  return (
  <HighchartsReact id={id}  highcharts={Highcharts} options={options}/>
  )
}

export default SplineChart;
