import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: (string | number)[];
  color?: string;
}

interface ACChartProps {
  title?: string;
  categories: string[];
  series: SeriesData[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
  tooltipUnit?: string;
  statusSeriesNames?: string[]; // Specify dynamic status series names
}

const DualAxisChartForAC: React.FC<ACChartProps> = ({
  title,
  categories,
  series,
  height,
  yAxisTitle,
  xAxisTitle,
  tooltipUnit,
  statusSeriesNames = [], // Default to empty array if not provided
}) => {

  
  
  const formattedSeries = series.map((serie) => {
    if (statusSeriesNames.includes(serie?.name)) {
      return {
        ...serie,
        data: serie.data.map((value) => (value === 'ON' ? 1 : 0)),
        yAxis: 1,
      };
    }
    return {
      ...serie,
      data: serie.data.map((value) =>
        typeof value === 'string' ? Number(value) : value
      ),
      yAxis: 0,
    };
  });

 

  const options = {
    chart: {
      type: 'line',
      height: height,
      zoomType: 'x',
      spacing: [10, 10, 10, 20],
    },
    title: {
      text: title,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle || 'Categories',
      },
    },
    yAxis: [
      {
        title: {
          text: yAxisTitle || 'Values',
        },
        opposite: false,
      },
      {
        title: {
          text: 'Status',
        },
        opposite: true,
        min: 0,
        max: 1,
        tickPositions: [0, 1],
        labels: {
          formatter: function (
            this: Highcharts.AxisLabelsFormatterContextObject
          ): string {
            return this.value === 1 ? 'ON' : 'OFF';
          },
        },
      },
    ],
    series: formattedSeries.map((serie) => ({
      ...serie,
      marker: {
        enabled: false,
      },
    })),
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        const points = this.points
          ?.map((point) => {
            const isStatusSeries =
              statusSeriesNames.includes(point.series.name);
            const value = isStatusSeries
              ? point.y === 1
                ? 'ON'
                : 'OFF'
              : point.y;
            return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${value} ${
              tooltipUnit || ''
            }</b><br/>`;
          })
          .join('');
        return `<b>${this.x}</b><br/>${points}`;
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DualAxisChartForAC;
