import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomTabPanel from '../../../../components/custom-tab-panel/CustomTabPanel'
import a11yProps from '../../../../types/a11yProps'
import { DashboardEnergyMonitoringProps } from '../types/EnergyMonitoringProps'
import MeterReading from './MeterReading/MeterReading'
import DailyConsumption from './DailyConsumption/DailyConsumption'


interface ConsumptionItem {
  consumptionactive_energy: number;  // Ensure that this property is of type number
  hour: string;  // Assuming halfHour is a string or another type
}
let maxDemand: number;
let maxDemadTime: string;

const Analysis: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], deviceState, sensorInfo }) => {
  const [analysisTabValue, setAnalysisTabValue] = useState(0);
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const workerRefConsumption = useRef<Worker | null>(null);

  const handleAnalysisTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setAnalysisTabValue(newValue);
  };

  useEffect(() => {
    workerRefConsumption.current = new Worker(
      new URL(
        '../../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );

    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };

    return () => {
      if (workerRefConsumption.current) {
        workerRefConsumption.current.terminate();
        workerRefConsumption.current = null;
      }
    };
  }, []);


  useEffect(() => {
    if (workerRefConsumption.current && data.length > 0 && sensorInfo?.["active_energy"]?.status) {
      workerRefConsumption.current.postMessage({
        deviceData: [...data].reverse(),
        tagName: ['active_energy'],
      });
    }
  }, [data]);

  useEffect(() => {
    if (consumptionData && consumptionData.length > 0) {
      const maxDemandObject: ConsumptionItem = consumptionData
        .slice()
        .sort((a, b) => b["consumptionactive_energy"] - a["consumptionactive_energy"])[0];

      maxDemand = maxDemandObject.consumptionactive_energy / 0.5;
      maxDemadTime = maxDemandObject.hour;
    }
    
  }, [consumptionData]);


  return (
    <>
      <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
        <Tabs value={analysisTabValue} onChange={handleAnalysisTabChange} aria-label="Sub tabs for Analysis">
          <Tab label={"Meter Reading"} {...a11yProps(0)} />
          <Tab label={"Daily Consumption"} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={analysisTabValue} index={0}>
        {/* Meter Reading Content */}
        {/* <div>Details of Meter Reading go here</div> */}
        <MeterReading maxDemand={maxDemand}  maxDemandTime={maxDemadTime} dashBoardSts={dashBoardSts} data={data} deviceState={deviceState} sensorInfo={sensorInfo} />
      </CustomTabPanel>
      <CustomTabPanel value={analysisTabValue} index={1}>
        {/* Daily Consumption Content */}
        {/* <div>Details of Daily Consumption go here</div> */}
        <DailyConsumption  maxDemand={maxDemand}  maxDemandTime={maxDemadTime} dashBoardSts={dashBoardSts} deviceState={deviceState} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>
    </>
  )
}

export default Analysis