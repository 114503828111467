// export function generateDayOptions() {
//     const { getFullYear, getMonth } = new Date();
//     const daysInMonth = new Date(getFullYear(), getMonth() + 1, 0).getDate();
  
//     return Array.from({ length: daysInMonth }, (_, i) => {
//       const day = i + 1;
//       const dayValue = day.toString().padStart(2, '0'); // Ensures double-digit format
//       return {
//         value: dayValue,
//         label: `${dayValue}th day of the month`,
//       };
//     });
//   }
  

export function generateDayOptions() {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Get current year
  const month = currentDate.getMonth() + 1; // Get current month (adding 1 because getMonth() returns zero-based index)

  // Calculate the number of days in the current month and year
  const daysInMonth = new Date(year, month, 0).getDate();
  
  // Generate options for days
  const dayOptions = [];
  for (let day = 1; day <= daysInMonth; day++) {
    const dayValue = day < 10 ? `0${day}` : `${day}`; // Ensure double-digit format
    dayOptions.push({
      value: dayValue,
      label: `${dayValue}th day of the month` // Customize label as needed
    });
  }
  
  return dayOptions;
}
// Function to format a date as 12-Dec-2024
export function formatDate(date: Date): string {
  console.log(`getting date from params:`,date);
  
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();


  console.log(`my date from formateDate method :`,day,month,year);
  

  return `${day}-${month}-${year}`;
}