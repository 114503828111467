import React, { useEffect, useRef, useState } from 'react'
import SearchAndActions from '../../../components/search-action/SearchAndActions'
import CommonTable from '../../../components/table/Table';
import { Column, DataRow } from '../../../types/table';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import Loader from '../../../components/loader/Loader';
import { ApiResponseHwra, HwraItem } from '../../../types/ServerIntegration';
import moment from 'moment-timezone';

const columns: Column[] = [
  { id: 'deviceName', label: 'Device Name' },
  { id: 'status', label: 'Status' },
  { id: 'lastPublishTime', label: 'Last Public Time' },
]

const HwraTable = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [hwraData, setHwraData] = useState<HwraItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const fetchHwraData = async (currentPage: number, currentLimit: number, search: string) => {
    setLoading(true);
    try {
      let query = `${baseUrlDevice}hwra?page=${currentPage}&limit=${currentLimit}`
      if (search !== '') {
        query += `&search=${search}`
      }
      const response = await http(query, 'GET')
      const hwraResponse = response as ApiResponseHwra;
      setHwraData(hwraResponse.data?.hwra);
      const total = hwraResponse.data?.totalItems || 0;
      setCurrentPage(hwraResponse.data?.currentPage);
      setTotalPages(hwraResponse.data?.totalPages);
      setTotalItems(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchHwraData(page, rowsPerPage, searchTerm);
  }, [page, rowsPerPage, timerRef])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage)

  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (newSearchTerm === '') {
      setPage(1);
      setRowsPerPage(10);
      fetchHwraData(1, 10, '');
    } else {
      timerRef.current = setTimeout((): void => {
        fetchHwraData(page, rowsPerPage, newSearchTerm);
      }, 500);
    }
  };

  const handleExport = () => {
    console.log("you clicked me on export button");

  }

  const formatLastPublishTime = (dateString: string | undefined): string => {
    return dateString ? moment(dateString).format("DD/MM/YYYY HH:mm:ss") : "N/A";
  };
  const tableData: DataRow[] = hwraData.map((row) => ({
    ...row,
    'deviceName': row.deviceDetails?.deviceName || 'N/A',
    'status': row.lastPublishData?.status || 'N/A',
    'lastPublishTime': formatLastPublishTime(row.lastPublishTime || 'N/A'),

  }));


  return (
    <div className="p-1 py-2">
      {loading && <Loader isBarCircle={loading} />}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onExport={handleExport}
      />

      <CommonTable
        columns={columns}
        data={tableData}
        title="HWRA Information"
        pagination={{
          rowsPerPageOptions: [10, 25, 50, 100, 500],
          rowsPerPage: rowsPerPage,
          page: currentPage,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: totalItems,
          currentPage: currentPage,
          totalPages: totalPages,
        }}
        setHeight={totalItems >= 8 ? "74vh" : "auto"}
      />
    </div>
  )
}

export default HwraTable;
