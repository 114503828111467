import React from 'react'
import { DashboardEnergyMonitoringProps } from '../types/EnergyMonitoringProps';
import SensorCard from '../../../../components/card/SensorCard';

const METER_READING_1ST_ROW_CARD_TAGS = ['t_acte', 't_re', 't_apte', 'r_hr']
const METER_READING_1ST_ROW_CARD_COLOR = {
    t_acte: '#D1FADF',
    t_re: '#FEF0C7',
    t_apte: '#E7E7FF',
    r_hr: '#fcba03'
} as const;
const FirstRowCard: React.FC<DashboardEnergyMonitoringProps> = ({sensorInfo, dashBoardSts}) => {

    
    
    const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
        return sensorInfo?.[tag]?.status;
    });

    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 border bottom-2">
                {activeTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            // value={action === "DAILY_CONSUMPTION" && dashBoardSts ? dashBoardSts["consumption"+tag] || '' :dashBoardSts?.[tag] || ''}
                            value={dashBoardSts?.[tag] ?? ''}

                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={METER_READING_1ST_ROW_CARD_COLOR[tag as keyof typeof METER_READING_1ST_ROW_CARD_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
        </>
    )
}

export default FirstRowCard