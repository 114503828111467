import React, { useEffect, useRef, useState } from 'react'
import { Device, ResponseDeviceSensorData, SensorData, SensorInfo } from '../../../types/Device';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import Loader from '../../../components/loader/Loader';
import DeviceCard from '../../../components/device-tower-card/DeviceCard';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../types/a11yProps';
import CustomTabPanel from '../../../components/custom-tab-panel/CustomTabPanel';
import Analysis from './analysis/Analysis';
import Instantaneous from './instantaneous/Instantaneous';

interface DashboardCustomTemplateProps {
    deviceState: Device;
}
const EnergySinglePhase: React.FC<DashboardCustomTemplateProps> = ({ deviceState }) => {
    const [dashBoardSts, setDashboardSts] = useState<SensorData>();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SensorData[]>([]);
    const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
    const [mainTabValue, setMainTabValue] = useState(0);
    const workerRefSensor = useRef<Worker | null>(null);

    const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setMainTabValue(newValue);
    };




    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await http(
                `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
            );
            const dataAsSensor = response as ResponseDeviceSensorData;
            setData(dataAsSensor.deviceData);
            setDashboardSts(dataAsSensor.deviceData[0]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        workerRefSensor.current = new Worker(
            new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
        );

        workerRefSensor.current.onmessage = function (e) {
            const { sensorInfo } = e.data;
            setSensorInfo(sensorInfo);
        };


        return () => {
            if (workerRefSensor.current) {
                workerRefSensor.current.terminate();
                workerRefSensor.current = null;
            }
        };
    }, []);

    useEffect(() => {

        if (deviceState.tags && workerRefSensor.current) {
            workerRefSensor.current.postMessage({ tags: deviceState.tags });
            fetchData();
        }
    }, [deviceState.tags]);


    return (
        <div>
            {loading && <Loader isBarCircle={loading} />}
            <div className="flex flex-col lg:flex-row w-full">
                {/* Left Section: DeviceCard */}
                <div className="flex-1 mb-4 lg:mb-0">
                    <DeviceCard
                        deviceId={""}
                        imeiNumber={deviceState.imeiNumber}
                        rssi={parseInt(dashBoardSts?.rssi as string)}
                        timeStamp={dashBoardSts?.timeStamp as string}
                    />
                </div>

                {/* Right Section: Box with Main Tabs */}
                <div className="flex justify-end lg:ml-4">
                    <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
                        <Tabs value={mainTabValue} onChange={handleMainTabChange} aria-label="Main tabs for Analysis and Instantaneous">
                            <Tab label={"Analysis"} {...a11yProps(0)} />
                            <Tab label={"Instantaneous"} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </div>
            </div>
            <CustomTabPanel value={mainTabValue} index={0}>
                {/* Analysis Tab */}
                {/* <h2>ANALYSIS</h2> */}
                <Analysis dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}} />
            </CustomTabPanel>

            <CustomTabPanel value={mainTabValue} index={1}>
                {/* Instantaneous Tab */}
                {/* <h2>INSTANTANEOUS</h2> */}
                <Instantaneous dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}} />
            </CustomTabPanel>

        </div>
    )
}

export default EnergySinglePhase