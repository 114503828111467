import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import { ConsumptionItem } from '../../../workers/consumption-data-worker/consumptionWorker';
import BarChart from '../../../components/chart/bar-chart/BarChart';
import { Box, Tab, Tabs } from '@mui/material';
import a11yProps from '../../../types/a11yProps';
import CustomTabPanel from '../../../components/custom-tab-panel/CustomTabPanel';
import LongMenu from '../../../components/long-menu/LongMenu';
import { transformDataForGraphPdfExcel } from '../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../utils/graph-download/pdfDownload';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import Loader from '../../../components/loader/Loader';

interface Pulse_Water_TemplateProps {
  deviceState: Device;
}
interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

interface SensorInfo {
  [key: string]: {
    displaySensorName: string;
    displaySensorUnit: string;
    status: boolean;
  };
}

const tags = ['Flow', 'Totalizer'];

const Pulse_Water_Template: React.FC<Pulse_Water_TemplateProps> = ({
  deviceState,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SensorData[]>([]);
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const workerRefConProcessor = useRef<Worker | null>(null);
  const workerRefConsumption = useRef<Worker | null>(null);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [consumptionData, setConsumtptionData] = useState<ConsumptionItem[]>();
  const [value, setValue] = React.useState(0);
  const { currentUser } = useCurrentUserState()

  const [consumptioBarChart, setConsumptionBarChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const [totalizerFlowLineChart, setTotalizerFlowLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const activeTags = tags.filter((tag) => sensorInfo?.[tag]?.status);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadOptionClick = (option: string, tag: string) => {
    const chartContext = tag === 'Flow' ? 'line' : tag === 'Totalizer' ? 'bar' : 'column';

    const config = [
      { id: "timeStamp", label: "Timestamp" },
      { id: tag, label: sensorInfo?.[tag]?.displaySensorName || '' },
    ];
    const filterData = transformDataForGraphPdfExcel(data, config);
    const chartContainerId = `chart-container-${tag.replace(/\s+/g, '-')}-${chartContext}`;

    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData);
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable(chartContainerId, filterData, currentUser.appLogo);
    }
  };

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );

    workerRefSensor.current.onmessage = function (e) {
      if (e.data && e.data.sensorInfo) {
        setSensorInfo(e.data.sensorInfo);
      } else {
        console.error('Invalid sensor worker message:', e.data);
      }
    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setTotalizerFlowLineChart({ seriesData, categories })
    };

    workerRefConsumption.current = new Worker(
      new URL(
        '../../../workers/consumption-data-worker/consumptionWorker.ts',
        import.meta.url
      )
    );
    workerRefConsumption.current.onmessage = function (e) {
      const consumptionData = e.data as { success: boolean; data: [] }; // Receive processed consumption data
      setConsumtptionData(consumptionData.data);
    };

    workerRefConProcessor.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart({ categories, seriesData });
    };

    return () => {
      if (workerRefSensor.current) {
        workerRefSensor.current.terminate();
      }

      if (workerRefLineChart.current) {
        workerRefLineChart.current.terminate();
      }

      if (workerRefConsumption.current) {
        workerRefConsumption.current.terminate();
      }
    };
  }, []);

  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors = ['117DAE', 'DD0000'];

      if (sensorInfo?.Flow.status) {
        names.push(sensorInfo.Flow.displaySensorName);
        keyNames.push('Flow');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }

    if (workerRefConsumption.current && data.length > 0 && sensorInfo?.['Totalizer']?.status) {
      workerRefConsumption.current.postMessage({
        deviceData: data,
        tagName: ['Totalizer'],
      });
    }
  }, [data])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData && sensorInfo?.['Totalizer']?.status) {
      const names: string[] = [];
      if (sensorInfo?.Totalizer.status) {
        names.push(sensorInfo.Totalizer.displaySensorName);
      }
      const keyNames = ['consumptionTotalizer'];
      const colors = ['2980b9'];
      workerRefConProcessor.current.postMessage({
        data: consumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'hour',
      });
    }
  }, [consumptionData]);

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState]);

  return (
    <div className="p-4 space-y-2">
      {loading && <Loader isBarCircle={loading} />}
      <div className="bg-white min-h-10 w-full md:w-[50%]  p-1 rounded-lg shadow-md">
        <div className="flex flex-col items-start ml-2 w-full mt-1">
          <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-full">
            <span className="font-bold">Device ID:</span>{' '}
            {deviceState.imeiNumber}
          </div>
          <div className="flex items-center md:ml-0 md:w-1/2">
            <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
              Last data received at: {dashBoardSts?.timeStamp}
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
        {sensorInfo?.['Flow']?.status && (
          <SensorCard
            sensor={sensorInfo?.Flow.status ? sensorInfo.Flow.displaySensorName : "Flow"}
            value={dashBoardSts ? dashBoardSts.Flow : "N/A"}
            unit={sensorInfo?.Flow.status ? sensorInfo.Flow.displaySensorUnit : "m³"}
          />
        )}
        {sensorInfo?.['Totalizer']?.status && (
          <SensorCard
            sensor={sensorInfo.Totalizer.status ? sensorInfo.Totalizer.displaySensorName : "Totalizer"}
            value={dashBoardSts ? dashBoardSts.Totalizer : "N/A"}
            unit={sensorInfo.Totalizer.status ? sensorInfo.Totalizer.displaySensorUnit : "m³"}
          />
        )}
      </div>

      <Box sx={{ width: '100%', backgroundColor: "white" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="dynamic tabs example">
            {activeTags.map((tag, index) =>
              sensorInfo?.[tag]?.status ? (
                <Tab
                  // onClick={(e) => handleSensorNameClick(e, tag)}
                  key={tag}
                  label={sensorInfo[tag]?.displaySensorName || `Unknown ${tag}`}
                  {...a11yProps(index)}
                />
              ) : null
            )}
          </Tabs>
        </Box>

        {activeTags.map((tag, index) =>
          sensorInfo?.[tag]?.status ? (
            <CustomTabPanel key={tag} value={value} index={index}>
              <div className="relative p-2">
                <div className="absolute top-0 right-0">
                  <LongMenu
                    options={["Download as CSV", "Download as PDF"]}
                    onOptionClick={(option: string) => handleDownloadOptionClick(option, tag)}
                    id={1}
                  />
                </div>
                <p>
                  {`${sensorInfo?.[tag]?.displaySensorName} data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "})`}
                </p>
              </div>

              <div id={`chart-container-${tag.replace(/\s+/g, '-')}-${tag === 'Flow' ? 'line' : 'bar'}`}>
                {tag === 'Flow' && (
                  <LineChart
                    title={`${sensorInfo?.[tag]?.displaySensorName || 'Unknown'} summary`}
                    categories={totalizerFlowLineChart?.categories || []}
                    series={totalizerFlowLineChart?.seriesData || []}
                    yAxisTitle="Data"
                    xAxisTitle="Time"
                    height={450}
                    tooltipUnit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                  />
                )}

                {tag === 'Totalizer' && (
                  <BarChart
                    title={`${sensorInfo[tag]?.displaySensorName || 'Unknown'} summary`}
                    categories={consumptioBarChart.categories}
                    series={consumptioBarChart.seriesData}
                    height={450}
                    xAxisTitle="Time"
                    yAxisTitle="Consumption Summary"
                  />
                )}
              </div>
            </CustomTabPanel>
          ) : null
        )}

      </Box>
    </div>
  )
}

export default Pulse_Water_Template;