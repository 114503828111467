import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import { setRole } from '../../app-context/role-context/RoleAction';
import { useRoleState } from '../../app-context/role-context/RoleState';
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Loader from '../../components/loader/Loader';
import Modal from '../../components/modal/Modal';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
import downloadFile from '../../utils/csv-pdf-interceptor/download-file';
import { baseUrlUser } from '../../utils/env/env.config';
const RoleTable = lazy(() => import('./components/RoleTable'));
const LazyAddNewRoleForm = lazy(() => import('./components/AddNewRoleForm'));

const Role = () => {
  const { permissions } = usePermissionState();
  const canCreate = permissions?.roleback?.canCreate || false;
  const { currentUser } = useCurrentUserState();
  const { dispatch, loading, roles } = useRoleState();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addRoleModal, setAddRoleModal] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    if (currentUser.clientId && roles.roles.length === 0) {
      setRole(dispatch, {
        page: 1,
        limit: 10,
        search: '',
        clientId: currentUser.clientId,
      });
    }
  }, [currentUser.clientId]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout((): void => {
      setRole(dispatch, {
        page: 1,
        limit: 10,
        search: event.target.value,
        clientId: currentUser.clientId,
      });
    }, 500);
  };

  const handleAddNew = () => {
    setAddRoleModal(true);
  };

  const handleExport = async () => {
    try {
      let queryParams = `${currentUser.clientId}/roles-export/in-excel`;
      if (searchTerm !== '' && searchTerm !== undefined) {
        queryParams += `?search=${searchTerm}`;
      }
      await downloadFile(
        baseUrlUser + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'role_and_permissions.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally {
      setDownloadProgress(0);
    }
  };

  return (
    <div className="p-1 py-2">
      {(loading || downloadProgress > 0) && (
        <Loader isBarCircle={loading || downloadProgress > 0} />
      )}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <RoleTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addRoleModal}
        onClose={() => {
          setAddRoleModal(false);
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewRoleForm
            onClose={() => {
              setAddRoleModal(false);
            }}
            action="ADD_NEW"
          />
        </Suspense>
      </Modal>
    </div>
  );
};

export default Role;
