import { CurrentUser } from "../../types/CurrentLoginUser";
import { RoleBack } from "../../types/Role";
import { Ticket, TicketData } from "../../types/Ticket";
import { baseUrlTicketing } from "../../utils/env/env.config";
import { http } from "../../utils/helper/http";




interface SetTicketAction {
  type: 'SET_TICKET'
  payload: TicketData
}

interface AddTicketAction {
  type: 'ADD_TICKET'
  payload: { tickets: Ticket; totalItems: number }
}


interface UpdateTicketAction {
  type: 'UPDATE_TICKET'
  payload: { id: string; tickets: Ticket }
}




interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}


export type TicketAction = SetTicketAction | SetLoadingAction | AddTicketAction | UpdateTicketAction

export async function setTicket(
  dispatch: React.Dispatch<TicketAction>,
  payload: {
    page: number
    limit: number
    search: string
    currentUser: CurrentUser
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page,currentUser, search } = payload
    const role = currentUser.role.name
    let queryParams = `?page=${page}&limit=${limit}`
    const organizationId = currentUser.organizationId
    const vendorId = currentUser.vendorId
    const userId = currentUser._id
    if (role === RoleBack.ORGANIZATIONADMIN) {
      queryParams +=
        organizationId !== '' ? `&organizationId=${organizationId}` : ''
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `&vendorId=${vendorId}`
    }
    if (role === RoleBack.USER) {
      queryParams += userId ? `&userId=${userId}` : ''
    }
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }

    const response = (await http(`${baseUrlTicketing}tickets${queryParams}`)) as TicketData

    dispatch({
      type: 'SET_TICKET',
      payload: response
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}


export function addNewTicket(
  dispatch: React.Dispatch<TicketAction>,
  payload: { tickets: Ticket; totalItems: number },
) {
  dispatch({
    type: 'ADD_TICKET',
    payload,
  })
}


export function updateTicket(
  dispatch: React.Dispatch<TicketAction>,
  payload: { id: string; tickets: Ticket },
) {
  dispatch({
    type: 'UPDATE_TICKET',
    payload,
  })
}
