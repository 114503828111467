import React, { useMemo, useState } from 'react'
import MultiSelectSearchable from '../../../components/dropdown-select/MultiSelectSearchable'
import SearchableSelect from '../../../components/dropdown-select/SearchableSelect'
import InputField from '../../../components/input-field/InputField'
import LinearWithValueLabel from '../../../components/linear-loader/LinearProgressBar'
import BackIcon from '../../../assets/images/back.png';
import { useFormik } from 'formik'
import { useDeviceState } from '../../../app-context/device-context/DeviceState'
import { useOrganizationState } from '../../../app-context/organization-context/OrganizationState'
import { useSiteState } from '../../../app-context/site-context/VendorState'
import { useUserState } from '../../../app-context/user-context/UserState'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { generateDayOptions } from '../../../utils/helper/generateDayOptions'
import Button from '../../../components/button/Button'
import { OptionType } from '../../../types/Dropdown'
import DatePicker from '../../../components/custom-date/DatePicker'
import ConsumptionSummeryTable from './components/ConsumptionSummeryTable'
import AddIcon from '@mui/icons-material/Add';


const reportFormatOptions = [
  { value: 'pdf', label: 'PDF' },
  { value: 'excel', label: 'Excel' },
]

const methodOptions = [
  { value: 'manual', label: 'Manual' },
  { value: 'auto_generated', label: 'Auto Generated' },
]

const reportGeneratedTypeOption = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
];

const receivedTimeOptionWeekly = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' },
];

const periodicityOption = [
  { value: 'hourly', label: 'Hourly' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];


const ConsumptionSummary2 = () => {
  const { user } = useUserState();
  const { organizations } = useOrganizationState();
  const { device } = useDeviceState();
  const { vendor } = useSiteState();
  const navigate = useNavigate();
  const recievedTimeOptionMonthly = useMemo(() => generateDayOptions(), []);
  const [selectedRecievedOption, setSelectedRecievedOption] = useState<OptionType[]>([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);



  const usersList = useMemo(() => user.user.map(userItem => ({
    value: userItem._id ?? '',
    label: userItem.name,
  })), [user]);

  const organizationList = useMemo(() => organizations.organizations.map(orgItem => ({
    value: orgItem._id ?? '',
    label: orgItem.organizationName,
  })), [organizations]);

  const deviceList = useMemo(() => device.device.map(deviceItem => ({
    value: deviceItem._id ?? '',
    label: deviceItem.deviceName,
  })), [device]);

  const vendorList = useMemo(() => vendor.vendor.map(vendorItem => ({
    value: vendorItem._id ?? '',
    label: vendorItem.vendorName,
  })), [vendor]);

  const handleDateChange = (field: string, value: Dayjs | null) => {
    formik.setFieldValue(field, value);
  }

  const handleChangeRecievedOption = (selected: OptionType[]) => {
    setSelectedRecievedOption(selected);
  }

  const formik = useFormik({
    initialValues: {
      selectedOrganization: '',
      selectedVendor: '',
      selectedUser: '',
      reportHeader: '',
      selectedMethod: '',
      startDate: dayjs(),
      endDate: dayjs(),
      generatedType: '',
      reportFormat: '',
      receivedTime: '',
      selectedDevice: '',
      selectedSensor: '',
      selectedMeterREading: ''
    },
    onSubmit: () => { }
  });

  const isAutoGenerated = formik.values.selectedMethod === 'auto_generated';
  const isMonthly = formik.values.generatedType === 'monthly'
  const isWeekly = formik.values.generatedType === 'weekly'
  const isDaily = formik.values.generatedType === 'daily'

  const handleBackIcon = () => {
    navigate('/home/reports');
  }

  const handleDownload = () => {
    setIsLoaderVisible(true);
    formik.handleSubmit();
  }

  return (
    <div className='md:px-10 md:py-4'>
      <div className='mt-10 px-10 py-5 md:bg-white md:rounded-xl md:border md:border-slate-200 md:relative'>
        <div className='border border-[#E2E8F0] p-4 rounded-full w-[60px] h-[60px] justify-center items-center bg-[#E8F2F7] cursor-pointer absolute top-[-30px] left-[-30px] hidden md:flex' onClick={handleBackIcon}>
          <img src={BackIcon} alt="backicon" />
        </div>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-5'>
            <SearchableSelect placeholder='Select Organization' options={organizationList} value={organizationList.find(option => option.value === formik.values.selectedOrganization) || null} onChange={value => formik.setFieldValue('selectedOrganization', value?.value || '')} isLoading={false} isDisabled={false} />
            <SearchableSelect placeholder='Select Vendor' options={vendorList} value={vendorList.find(option => option.value === formik.values.selectedVendor) || null} onChange={value => formik.setFieldValue('selectedVendor', value?.value || '')} isLoading={false} />
            <SearchableSelect placeholder='Select User' options={usersList} value={usersList.find(option => option.value === formik.values.selectedUser) || null} onChange={value => formik.setFieldValue('selectedUser', value?.value || '')} isLoading={false} isDisabled={false} />
            <InputField placeholder='Enter Report Header' name='reportHeader' label='Enter Report Header' value={formik.values.reportHeader} onChange={formik.handleChange} />
            <SearchableSelect placeholder='Select Method' options={methodOptions} value={methodOptions.find(option => option.value === formik.values.selectedMethod) || null} onChange={value => formik.setFieldValue('selectedMethod', value?.value || '')} isLoading={false} isDisabled={false} />
            <SearchableSelect placeholder='Select Report Format' options={reportFormatOptions} value={reportFormatOptions.find(option => option.value === formik.values.reportFormat) || null} onChange={value => formik.setFieldValue('reportFormat', value?.value || '')} isDisabled={false} isLoading={false} />
            {!isAutoGenerated ?
              <>
                <DatePicker label='Start Date' value={formik.values.startDate} onChange={(date) => handleDateChange('startDate', date)} />
                <DatePicker label='End Date' value={formik.values.endDate} onChange={(date) => handleDateChange('endDate', date)} />
              </> :
              <>
                <SearchableSelect placeholder='Report Generated Type' options={reportGeneratedTypeOption} value={reportGeneratedTypeOption.find(option => option.value === formik.values.generatedType) || null} onChange={value => formik.setFieldValue('generatedType', value?.value || '')} isLoading={false} isDisabled={false} />
                <MultiSelectSearchable placeholder='Received On' options={isDaily || isWeekly ? receivedTimeOptionWeekly : isMonthly ? recievedTimeOptionMonthly : []} value={selectedRecievedOption} onChange={handleChangeRecievedOption} />
                <InputField label='Received Time' type='time' name='receivedTime' value={formik.values.receivedTime} onChange={formik.handleChange} />
              </>
            }
            <SearchableSelect placeholder='Select Periodicity' options={periodicityOption} />
            <SearchableSelect placeholder='Meter Reading' options={[]} />
            <SearchableSelect placeholder='Select Device' options={deviceList} value={deviceList.find(option => option.value === formik.values.selectedDevice) || null} onChange={value => formik.setFieldValue('selectedDevice', value?.value || '')} isLoading={false} isDisabled={false} />
            <SearchableSelect placeholder='Select Sensor' options={[]} />
          </div>
          <div className='flex justify-end mt-8'>
            <button className='w-[137px] h-[38px] rounded-[26px] text-white bg-[#117DAE] shadow-md shadow-[#117DAE80] flex items-center justify-center gap-1'>

              <AddIcon className='text-white' /> Add New
            </button>
          </div>
          <div className='mt-6 mb-4'>
            <ConsumptionSummeryTable />
          </div>
          {isLoaderVisible &&
            <div className='flex justify-center'>
              <LinearWithValueLabel />
            </div>
          }
          <div className='button-group flex gap-x-2 md:gap-x-10 mt-8 md:w-1/2 md:mx-auto'>
            <Button type='reset' variant='outline' size='medium'>Reset</Button>
            <Button type='button' variant='outline' size='medium' onClick={handleDownload}>Download</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ConsumptionSummary2;
