interface Data {
    value: string;
    label: string;
}

export function getValueFromObject(data: Data[] , value: string) {  
  const newData = data.filter((d: Data)=> d.value == value);  
  return newData[0].label;
}

export function getTagValueFromObject(data: Data[] , tagName: string) {    
  const newData = data.filter((d: Data)=> d.label == tagName);  
  return newData[0].value;
}