export interface RoleFormValues {
  dashBoardName: string;
  name: string;
  roleName: string;
  description: string;
  accessLevel: string;
}

export interface PredefinedRole {
  _id: string;
  name: string;
}

export interface Role {
  _id?: string;
  dashBoardName: string;
  name: string;
  roleName: string;
  type: string;
  description: string;
  permissions?: [];
  clientId?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  predefinedRoleId: PredefinedRole;
}

export interface RoleData {
  roles: Role[];
  limit?: number;
  currentPage: number;
  totalPages: number;
  previousPage: number;
  totalItems: number;
}

export interface ApiResponseRole {
  page: number;
  limit: number;
  data: RoleData;
}

export interface CreatePermissionResponse {
  data: Role;
}

// roles.ts
export enum RoleBack {
  SUPERADMIN = 'SUPER_ADMIN',
  ORGANIZATIONADMIN = 'ORGANIZATION',
  SITEADMIN = 'VENDOR',
  USER = 'END_USER',
  SUBADMIN = 'SUB_ADMIN'
}

export interface ActionOption {
  action: string[];
  dropdownAction: string[];
}
