import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import SplineChart from '../../../../../components/chart/line-chart/SplineChart';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import SensorCard from '../../../../../components/card/SensorCard';

const FREQUENCY_CARD_TAGS = ['freq'];
const FREQUENCY_CARD_TAGS_COLOR = {
    freq: '#D1FADF',
};

const names: string[] = [];
const keyNames: string[] = [];
const colors = ['f542e9'];
const yAxis: number[] = [];
const postFix: string[] = [];

const Frequency: React.FC<DashboardEnergyMonitoringProps> = ({ sensorInfo, data = [], dashBoardSts }) => {
    const { currentUser } = useCurrentUserState()
    const workerRefLineChart = useRef<Worker | null>(null);
    const [lineChart, setLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });

    const frequencyActiveTags = FREQUENCY_CARD_TAGS.filter((tag) => {
        if (sensorInfo?.[tag]?.status) {
            if (!names.includes(sensorInfo?.[tag]?.displaySensorName)) {
                names.push(sensorInfo?.[tag]?.displaySensorName);
            }
            if (!keyNames.includes(tag)) {
                keyNames.push(tag);
            }
            postFix.push(sensorInfo?.[tag]?.displaySensorUnit);
        }
        return sensorInfo?.[tag]?.status;
    });

    const handleDownloadOptionClick = (option: string) => {
        const config = [
            { id: "timeStamp", label: "Timestamp" },
        ];

        frequencyActiveTags.map((tag) => {
            config.push({ id: tag, label: sensorInfo?.[tag].displaySensorName || '' })
        })

        const filterData = transformDataForGraphPdfExcel(data, config);

        if (option === 'Download as CSV') {
            generateExcelWithDataTable(filterData)
        } else if (option === 'Download as PDF') {
            generateGraphPDFWithDataTable('lineChart-container', filterData, currentUser.appLogo);
        }
    };

    useEffect(() => {
        workerRefLineChart.current = new Worker(
            new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );

        workerRefLineChart.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setLineChart({ seriesData, categories })
        };

        if (workerRefLineChart.current && data.length > 0) {
            workerRefLineChart.current.postMessage({
                data: data,
                names,
                keyNames,
                colors,
                yAxis,
            })
        }
        return () => {
            if (workerRefLineChart.current) {
                workerRefLineChart.current.terminate();
                workerRefLineChart.current = null;
            }

        };
    }, []);
    return (
        <>
            <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4  bottom-2">
                {frequencyActiveTags.map((tag) => (
                    sensorInfo?.[tag]?.status && (
                        <SensorCard
                            key={tag}
                            sensor={sensorInfo?.[tag]?.displaySensorName || 'Unknown Sensor'}
                            value={dashBoardSts?.[tag] || ''}
                            unit={sensorInfo?.[tag]?.displaySensorUnit || ''}
                            bg_color={FREQUENCY_CARD_TAGS_COLOR[tag as keyof typeof FREQUENCY_CARD_TAGS_COLOR] || '#FFFFFF'}
                        />
                    )
                ))}
            </div>
            <div className="relative p-2 mt-2 bg-white">
                <div className="absolute top-0 right-0">
                    <LongMenu
                        options={["Download as CSV", "Download as PDF"]}
                        onOptionClick={(option: string) => handleDownloadOptionClick(option)}
                        id={1}
                    />
                </div>
                <p>
                    {`Frequency data variation with time (between ${data.length > 0 ? `${data[data.length - 1].timeStamp} to ${data[0].timeStamp}` : " "
                        })`}
                </p>
            </div>

            <div id="lineChart-container">
                <SplineChart
                    title={`Frequency Summary`}
                    categories={lineChart.categories}
                    series={lineChart.seriesData}
                    yAxisTitle="Data"
                    xAxisTitle="Time"
                    height={300}
                    maxCategories={12}
                    postFixUnit={postFix}
                />
            </div>
        </>
    )
}

export default Frequency;