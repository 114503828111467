import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import CommonTable from "../../../../components/table/Table";
import StatusBadge from "../../../../components/status/StatusBadge";
import { useDeviceState } from "../../../../app-context/device-context/DeviceState";
import { useCurrentUserState } from "../../../../app-context/current-user-context/CurrentUserLoginState";
import { setDevice } from "../../../../app-context/device-context/DeviceAction";
import { RoleBack } from "../../../../types/Role";
import { Column, DataRow } from "../../../../types/table";
import { Device } from "../../../../types/Device";

const columns: Column[] = [
  { id: "deviceAlias", label: "Device Alias" },
  { id: "deviceName", label: "Device Name" },
  { id: "status", label: "Status", align: "center" },
];

function VendorsDeviceTable() {
  const { device, dispatch, loading } = useDeviceState();
  const { currentUser } = useCurrentUserState();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const data: DataRow[] =
    device.device.length > 0
      ? device.device.map((row) => ({
          ...row,
          deviceAlias: (
            <span
              className="cursor-pointer"
              onClick={() => handleClick(row._id, row)}
            >
              {row.deviceAlias}
            </span>
          ),
          status: <StatusBadge status={row?.connectionTimeIntervalStatus ? "active" : "inactive"} />,
        }))
      : [];

  const handleClick = (_id: string, deviceState: Device) => {
    navigate(`/home/selected-device/${_id}`, { state: deviceState });
  };

  const handlePageChange = (newPage: number) => {
    const direction = newPage > device.currentPage - 1 ? "next" : "previous";
    const page = +device.currentPage;
    setDevice(dispatch, {
      page: direction === "next" ? page + 1 : page - 1,
      limit: rowsPerPage,
      organizationId: currentUser.organizationId || "",
      vendorId: currentUser.vendorId || "",
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : "",
      role: currentUser.role.name,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setDevice(dispatch, {
      page: device.currentPage,
      limit: newRowsPerPage,
      organizationId: currentUser.organizationId || "",
      vendorId: currentUser.vendorId || "",
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : "",
      role: currentUser.role.name,
    });
  };

  return (
    <div>
      {loading && <Loader isBarCircle={loading} />}
      <CommonTable
        columns={columns}
        data={data}
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowsPerPage: rowsPerPage,
          page: device.currentPage,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: device.totalItems,
          currentPage: device.currentPage,
          totalPages: device.totalPages,
        }}
        setHeight="60vh"
      />
    </div>
  );
}

export default VendorsDeviceTable;
