import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import CustomTabPanel from '../../../../components/custom-tab-panel/CustomTabPanel'
import a11yProps from '../../../../types/a11yProps';
import { DashboardEnergyMonitoringProps } from '../types/EnergyMonitoringProps';
import Voltage from './voltage/Voltage';
import Current from './current/Current';
import Power from './power/Power';
import PowerFactor from './power-factor/PowerFactor';
import Frequency from './frequency/Frequency';
import Thd from './thd/Thd';

const Instantaneous:React.FC<DashboardEnergyMonitoringProps> = ({dashBoardSts, data, deviceState, sensorInfo}) => {
  // State for individual sections' tabs
  const [instantaneousTabValue, setInstantaneousTabValue] = useState(0);
  // Instantaneous Tab change handler
  const handleInstantaneousTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setInstantaneousTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
        <Tabs value={instantaneousTabValue} onChange={handleInstantaneousTabChange} aria-label="Sub tabs for Instantaneous">
          <Tab label={"Voltage"} {...a11yProps(0)} />
          <Tab label={"Current"} {...a11yProps(1)} />
          <Tab label={"Power"} {...a11yProps(2)} />
          <Tab label={"Power Factor"} {...a11yProps(3)} />
          <Tab label={"Frequency"} {...a11yProps(4)} />
          <Tab label={"Thd"} {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={instantaneousTabValue} index={0}>
        {/* <div>Details for Voltage go here</div> */}
        <Voltage dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />
      </CustomTabPanel>
      <CustomTabPanel value={instantaneousTabValue} index={1}>
        {/* <div>Details for Current go here</div> */}
        <Current  dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />
      </CustomTabPanel>
      <CustomTabPanel value={instantaneousTabValue} index={2}>
        {/* <div>Details for Power go here</div> */}
        <Power  dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />
      </CustomTabPanel>
      <CustomTabPanel value={instantaneousTabValue} index={3}>
        {/* <div>Details for Power factor go here</div> */}
        <PowerFactor  dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />

      </CustomTabPanel>
      <CustomTabPanel value={instantaneousTabValue} index={4}>
        {/* <div>Details for Frequency go here</div> */}
        <Frequency  dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />

      </CustomTabPanel>
      <CustomTabPanel value={instantaneousTabValue} index={5}>
        {/* <div>Details for THD go here</div> */}
        <Thd  dashBoardSts={dashBoardSts || {}} data={data} deviceState={deviceState} sensorInfo={sensorInfo || {}}  />
      </CustomTabPanel>
    </>
  )
}

export default Instantaneous