import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ApiResponseDevice, DeviceData } from '../../../types/Device';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import { Column, DataRow } from '../../../types/table';
import CommonTable from '../../../components/table/Table';
import StatusBadge from '../../organization/components/StatusBadge';
import Loader from '../../../components/loader/Loader';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import SearchAndActions from '../../../components/search-action/SearchAndActions';
import { RoleBack } from '../../../types/Role';
import downloadFile from '../../../utils/csv-pdf-interceptor/download-file';

interface Data {
  devices: DeviceData;
}

const columns: Column[] = [
  { id: 'imeiNumber', label: 'IMEI NO.' },
  { id: 'deviceName', label: 'DEVICE NAME' },
  { id: 'status', label: 'STATUS', align: 'center' },
];

const DeviceDashboard = () => {
  const { state } = useLocation()
  const [data, setData] = useState<Data>({
    devices: { device: [], currentPage: 1, totalPages: 1, previousPage: 0, totalItems: 0 },
  });
  const [loading, setLoading] = useState(false)
  const {currentUser}=useCurrentUserState()
  const [searchQuery, setSearchQuery] = useState('');
  const [downloadProgress, setDownloadProgress] = useState(0);
  console.log(currentUser)
  const handleFetchData = async (label: string, page: number, limit: number, searchQuery?: string, isExport?: boolean) => {
    try {
      let queryparams = `devices?page=${page}&limit=${limit}&connectionTimeIntervalStatus=${state === "onlineDevice"}`
      if(currentUser.role.name === RoleBack.ORGANIZATIONADMIN) queryparams += `&organizationId=${currentUser.organizationId}`
      if (currentUser.role.name === RoleBack.SITEADMIN){
        queryparams += `&vendorId=${currentUser.vendorId}`;
      }
      if(currentUser.role.name === RoleBack.USER){
        queryparams += `&userId=${currentUser._id}`;
      }
      if (searchQuery) queryparams += `&search=${encodeURIComponent(searchQuery)}`;
      if (isExport) queryparams += `&export=excel`;
      switch (label) {
        case "Devices": {
          setLoading(true)
          const response = await http(baseUrlDevice + queryparams) as ApiResponseDevice
          setData((prev) => ({ ...prev, devices: response.data }));
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    if(currentUser._id){
    handleFetchData('Devices', 1, 10)
    }
  }, [currentUser._id])


  const d: DataRow[] =
    data.devices.device.length > 0
      ? data.devices.device.map((row) => ({
        ...row,
        status: <StatusBadge status={row?.connectionTimeIntervalStatus ? 'active' : 'inactive'} />,
      }))
      : []

     
  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
    const newRowsPerPage = +event.target.value;
    console.log('Rows per page changed to:', newRowsPerPage, label);
  };

  const handlePageChange = (newPage: number, label: string) => {
    console.log('Page changed to:', newPage, label);
    switch (label) {
      case 'Devices': {
        handleFetchData('Devices', newPage, 10);
        break;
      }
      default:
        break;
    }
  };
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    handleFetchData('Devices', 1, 10, query);
  };

  const handleAddNew = () => {
    console.log('Add New Device clicked');
  };
  const handleExport = async () => {
    const role = currentUser.role.name
    let queryParams = `devices/export-excel?connectionTimeIntervalStatus=${state === "onlineDevice"}&`
    if (role === RoleBack.ORGANIZATIONADMIN && currentUser.organizationId) {
      queryParams += `organizationId=${currentUser.organizationId}&`;
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `vendorId=${currentUser.vendorId}&`;
    } else if (role === RoleBack.USER) {
      queryParams += `userId=${currentUser._id}&`;
    }
    if(searchQuery !== '' && searchQuery !== undefined){
      queryParams += `search=${searchQuery}`;
    }
    try {
      await downloadFile(
        baseUrlDevice + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'device.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally{
      setDownloadProgress(0);

    }
  };

  const handleFilter = () => {
    console.log('Filter clicked');
  };
  return (
    <>
     {(loading || downloadProgress > 0) && <Loader isBarCircle={(loading || downloadProgress > 0)}/>}
      <SearchAndActions
        searchTerm={searchQuery}
        onSearchChange={(e) => handleSearch(e.target.value)}
        onAddNew={handleAddNew}
        onExport={handleExport}
        onFilter={handleFilter}
        handelAdd={false}
      />
      <CommonTable
        columns={columns}
        data={d}
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowsPerPage: 10,
          page: 1, 
          handlePageChange: (e) => { handlePageChange(e, 'Devices') },
          handleChangeRowsPerPage: (e) => { { handleChangeRowsPerPage(e, 'Devices') } },
          totalItems: data.devices.totalItems,
          currentPage: data.devices.currentPage,
          totalPages: data.devices.totalPages,
        }}
      />
    </>
  )
}

export default DeviceDashboard