import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: number[];
  color?: string; // Optional color property
}

interface BarChartProps {
  title?: string;
  categories: string[];
  series: SeriesData[];
  height?: number; // Optional height property
  // width?: number;  // Optional width property
  xAxisTitle?: string; // Prop for x-axis title
  yAxisTitle?: string; // New prop for y-axis title
  tooltipUnit?: string;
  postFixUnit?: string[];

}

const BarChart: React.FC<BarChartProps> = ({
  title,
  categories,
  series,
  height,
  xAxisTitle = "Data", // Default value for x-axis title
  yAxisTitle = 'Values', // Default value for y-axis title
  tooltipUnit,
  postFixUnit

}) => {
  const formattedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((value) => {
      return typeof value === 'string' ? Number(value) : value;
    }),
  }));
  const options = {
    chart: {
      type: 'column', // Use 'column' for vertical bars
      style: {
        fontFamily: 'Arial, sans-serif', // Optional: Set font style
      },
      height: height,
      zoomType: 'x',
    },
    title: {
      text: title,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle,
      },
    },
    yAxis: {
      min: null, // Allow Highcharts to calculate the minimum dynamically
      title: {
        text: yAxisTitle,
      },
    },
    series: formattedSeries,
    credits: {
      enabled: false, // Disable the watermark
    },
    tooltip: {
      shared: true, // This enables the tooltip to display information for all series at the same x point
      crosshairs: true, // Enable crosshairs to show on hover
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const points = this.points
          ?.map((point, i) => {
            return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${
              tooltipUnit ? tooltipUnit || '' : postFixUnit ? postFixUnit[i] : ''
            }</b><br/>`;
          })
          .join('');
        return `<b>${this.x}</b><br/>${points}`;
      },
    },
  };
  

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;



// const dummyData = {
//   title: 'Sales Overview',
//   categories: ['January', 'February', 'March', 'April', 'May'],
//   series: [
//     {
//       name: 'Product A',
//       data: [30, 20, 50, 40, 60],
//       color: '#117DAE', // Optional color
//     },
//     {
//       name: 'Product B',
//       data: [40, 30, 70, 60, 80],
//       color: '#A4A4A6', // Optional color
//     },
//   ],
// };

// // Example usage of BarChart with dummy data
// const ExampleUsage = () => (
//   <div>
//     <BarChart
//       title={dummyData.title}
//       categories={dummyData.categories}
//       series={dummyData.series}
//       height={300}
//       xAxisTitle="Months"
//       yAxisTitle="Sales"
//     />
//   </div>
// );

// export default ExampleUsage;


// const options = {
//   chart: {
//     type: 'column', // Use 'column' for vertical bars
//     style: {
//       fontFamily: 'Arial, sans-serif', // Optional: Set font style
//     },
//     height: height,
//     zoomType: 'x',
//   },
//   title: {
//     text: title,
//   },
//   accessibility: {
//     enabled: false,
//   },
//   xAxis: {
//     categories: categories,
//     title: {
//       text: xAxisTitle,
//     },
//   },
//   yAxis: {
//     min: 0,
//     title: {
//       text: yAxisTitle,
//     },
//   },
//   series: formattedSeries,
//   credits: {
//     enabled: false, // Disable the watermark
//   },
//   tooltip: {
//     shared: true,  // This enables the tooltip to display information for all series at the same x point
//     crosshairs: true,  // Enable crosshairs to show on hover
//     formatter: function (this: Highcharts.TooltipFormatterContextObject) {
//       const points = this.points?.map((point, i) => {
//         return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${tooltipUnit ? tooltipUnit || '' : postFixUnit ? postFixUnit[i] : ''}</b><br/>`;
//       }).join('');
//       return `<b>${this.x}</b><br/>${points}`;
//     }
//   },
// };