// import React from 'react';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   TablePagination,
// } from '@mui/material';
// import { Column, DataRow } from '../../types/table';



// interface CommonTableProps {
//   columns: Column[];
//   data: DataRow[];
//   title?: string;
//   pagination?: {
//     rowsPerPageOptions?: number[];
//     rowsPerPage?: number;
//     page?: number;
//     handlePageChange?: (newPage: number) => void;
//     handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
//     totalItems?: number;
//     currentPage?: number;
//     totalPages?: number;
//   };
//   setHeight?: string;
// }

// const CommonTable: React.FC<CommonTableProps> = ({
//   columns,
//   data,
//   title,
//   pagination,
//   setHeight,
// }) => {
//   const {
//     rowsPerPageOptions = [5, 10, 25],
//     rowsPerPage = 5,
//     handlePageChange,
//     handleChangeRowsPerPage,
//     totalItems = 0,
//     currentPage = 1,
//   } = pagination || {};
  
//   const zeroBasedPage = Math.max(0, currentPage - 1);
//   return (
//     <>
//     <Paper sx={{ border: '1px solid #ddd', borderRadius: 3 }}>
//       <TableContainer sx={{ height: {
//       xs: '100%',
//       md: setHeight,
//     }, }}>
//         {title && 
//         <Typography variant="h6" component="div" sx={{ margin: 1, paddingLeft: "6px" }}>
//           {title}
//         </Typography>
//         }
//         <Table stickyHeader>
//           <TableHead>
//             <TableRow>
//             <TableCell
//                   sx={{
//                     padding: 2,
//                     borderBottom: '1px solid #ddd',
//                     color: 'black',
//                     fontWeight: 'bold',
//                   }}
//                   key="sno"
//                   align="left"
//                 >
//                   S.NO
//                 </TableCell>
//               {columns.map((column) => (
//                 <TableCell     sx={{
//                     padding: 2,
//                     borderBottom: '1px solid #ddd',
//                     color: 'black', // Set text color to black
//                     fontWeight: 'bold', // Make text bold
//                   }} key={column.id} align={column.align || 'left'}>
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data.map((row, index) => (
//               <TableRow key={index}
//               onClick={row.onRowClick ? row.onRowClick : undefined}
//               >
//                 <TableCell key="sno" align='left'>
//                     {zeroBasedPage * rowsPerPage + index + 1}
//                   </TableCell>
//                 {columns.map((column) => (
//                   <TableCell key={column.id} align={column.align || 'left'}>
//                       {row[column.id] ?? null}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//         {pagination && (
//           <TablePagination
//             rowsPerPageOptions={rowsPerPageOptions}
//             component="div"
//             count={totalItems}
//             rowsPerPage={rowsPerPage}
//             page={zeroBasedPage} // Adjust for 0-based index
//             onPageChange={(event, newPage) => handlePageChange?.(newPage + 1)} // Adjust for 1-based page number
//             onRowsPerPageChange={handleChangeRowsPerPage}
//             labelRowsPerPage="Items per page" 
//           />
//         )}
//     </Paper>
//     </>
//   );
// };

// export default CommonTable;
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from '@mui/material';
import { Column, DataRow } from '../../types/table';
import TablePaginationActions from './TablePaginationActions';

interface CommonTableProps {
  columns: Column[];
  data: DataRow[];
  title?: string;
  pagination?: {
    rowsPerPageOptions?: number[];
    rowsPerPage?: number;
    page?: number;
    handlePageChange?: (newPage: number) => void;
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    totalItems?: number;
    currentPage?: number;
    totalPages?: number;
  };
  setHeight?: string;
}

const CommonTable: React.FC<CommonTableProps> = ({
  columns,
  data,
  title,
  pagination,
  setHeight,
}) => {
  const {
    rowsPerPageOptions = [10, 25, 50, 100, 500],
    rowsPerPage = 10,
    handlePageChange,
    handleChangeRowsPerPage,
    totalItems = 0,
    currentPage = 1,
  } = pagination || {};

  const zeroBasedPage = Math.max(0, currentPage - 1);
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  return (
    <>
      <Paper sx={{ border: '1px solid #ddd', borderRadius: 3 }}>
        <TableContainer
          sx={{
            height: {
              xs: '100%',
              md: setHeight,
            },
          }}
        >
          {title && (
            <Typography variant="h6" component="div" sx={{ margin: 1, paddingLeft: '6px' }}>
              {title}
            </Typography>
          )}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    padding: 2,
                    borderBottom: '1px solid #ddd',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                  key="sno"
                  align="left"
                >
                  S.NO
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      padding: 2,
                      borderBottom: '1px solid #ddd',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    key={column.id}
                    align={column.align || 'left'}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={row.onRowClick ? row.onRowClick : undefined}
                  style={{ cursor: row.onRowClick ? 'pointer' : 'default' }}
                >
                  <TableCell key="sno" align="left">
                    {zeroBasedPage * rowsPerPage + index + 1}
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align || 'left'}>
                      {column.id === 'action' ? (
                        // Conditionally render action column only if it's meant to be displayed
                        row.action
                      ) : (
                        row[column.id] ?? null
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={zeroBasedPage}
            onPageChange={(event, newPage) => handlePageChange?.(newPage + 1)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Items per page"
            ActionsComponent={(props) => (
              <TablePaginationActions
                {...props}
                count={totalItems}
                page={zeroBasedPage} 
                rowsPerPage={rowsPerPage}
                totalPages={totalPages}
                onPageChange={(event, newPage) => handlePageChange?.(newPage + 1)} 
                
              />
            )}
          />
        )}
      </Paper>
    </>
  );
};

export default CommonTable;
