import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import InputField from '../../components/input-field/InputField';
import Button from '../../components/button/Button';
import { OptionType } from '../../types/Dropdown';
import SearchableSelect from '../../components/dropdown-select/SearchableSelect';
import CommonTable from '../../components/table/Table';
import { Column } from '../../types/table';
import { baseUrlDevice, baseUrlModConfigWS } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';
import { Device, TemplateTag } from '../../types/Device';
import { ApiResponseModConfig, dataSize, dataType, modbusType, parityStatus, resgisterType, scallingFactor, SensorConfig, SlaveAttributes, stopBits } from '../../types/Modbus';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { useToast } from '../../components/custom-toast/CustomToastProvider';
import { getTagValueFromObject, getValueFromObject } from '../../utils/helper/objectDataFinder';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { CancelOutlined, CloseOutlined, ReportProblemOutlined } from '@mui/icons-material';
import { io, Socket } from 'socket.io-client';

interface OptionsState {
    stopBits: OptionType[];
    parityStatus: OptionType[];
    parameter: OptionType[];
    resgisterType: OptionType[];
    scallingFactor: OptionType[];
    modbusType: OptionType[];
    dataType: OptionType[];
    dataSize: OptionType[];
}

const columns: Column[] = [
    { id: 'parameterName', label: 'Parameter Name' },
    { id: 'registerType', label: 'Register Type' },
    { id: 'registerAddress', label: 'Register Address', align: 'center' },
    { id: 'slaveId', label: 'Slave ID', align: 'center' },
    { id: 'scalingFactor', label: 'Scalling Factor', align: 'center'},
    { id: 'modbusDataType', label: 'Modbus Data Type', align: 'center'},
    { id: 'dataType', label: 'Data Type', align: 'center'},
    { id: 'dataSize', label: 'Data Size', align: 'center'},
    { id: 'action', label: 'Actions', align: 'center', action: true},
];

const steps = [
  '',
  'Mod Configuration Details updated',
  'Sensor Configuration Details updated',
  'Version Configuration Details updated',
  'Mod configuration done successfully.',
  'Restarting Device',
];

function Modbus_Config() {
    const { _id } = useParams();
    
    const [currentDevice, setCurrentDevice] = useState<Device | null>(null);
    const [tags, setTags] = useState<TemplateTag[]>([]);
    const [sensorConfigs, setSensorConfigs] = useState<SensorConfig[]>([]);
    const { showToast } = useToast();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number>(-1);
    const [retrieveButtonText, setRetrieveButtonText] = useState<string>('Retrieve')

    const [open, setOpen] = useState<boolean>(false);
    const [open1, setOpen1] = useState<boolean>(false);
    const [open2, setOpen2] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const modIORef = useRef<Socket | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [baudRate, setBaudRate] = useState<string>('9600');
    const [selectedStopBits, setSelectedStopBits] = useState<OptionType | null>(null);
    const [selectedParityStatus, setSelectedParityStatus] = useState<OptionType | null>(null);
    const [wordLength, setWordLength] = useState<string>('8');
    const [sleepTime, setSleepTime] = useState<string>('15');
    const [firmwareVersionName, setFirmwareVersionName] = useState('Generic');

    const [selectedParameter, setSelectedParameter] = useState<OptionType | null>(null);
    const [selectedRegisterType, setSelectedRegisterType] = useState<OptionType | null>(null);
    const [registerAddress, setRegisterAddress] = useState<string>('40001');
    const [slaveId, setSlaveId] = useState<string>('01');
    const [selectedScallingFactor, setSelectedScallingFactor] = useState<OptionType | null>(null);
    const [selectedModbusType, setSelectedModbusType] = useState<OptionType | null>(null);
    const [selectedDataType, setSelectedDataType] = useState<OptionType | null>(null);
    const [selectedDataSize, setSelectedDataSize] = useState<OptionType | null>(null);

    const [options, setOptions] = useState<OptionsState>({
        stopBits: [],
        parityStatus: [],
        parameter: [],
        resgisterType: [],
        scallingFactor: [],
        modbusType: [],
        dataType: [],
        dataSize: [],
    });

    useEffect(() => {
        if (modIORef.current) {
            modIORef.current.disconnect();
            modIORef.current = null;
        }

        const IO = io(`${baseUrlModConfigWS}modbus-config?deviceId=${_id}`);
        modIORef.current = IO;

        let waitingForStepThree = false;

        IO.on('retrieve-config-data', (data) => {
            clearTimeout(timeoutRef.current || 0);
            if(data){
                setSensorConfigs([]);
                const results = [];
                if(data.deviceCategory == "ESP32") {
                    setBaudRate(data.Modbus_config.buad_rate);
                    setSelectedStopBits({
                        value: data.Modbus_config.Stop_bits,
                        label: getValueFromObject(stopBits, data.Modbus_config.Stop_bits)
                    });
                    setSelectedParityStatus({
                        value: data.Modbus_config.parity,
                        label: getValueFromObject(parityStatus, data.Modbus_config.parity)
                    });
                    setWordLength(data.Modbus_config.word_length);

                    setSleepTime(data.sleeptime);
                    setFirmwareVersionName(data.Firm_Ver);

                    const deviceData = data.deviceData;
                    const modCount = parseInt(data.Modcount, 10);
                    let count = 0;                    

                    for(const key in deviceData){
                        if(count < modCount){
                            const data = deviceData[key];
                            results.push({
                                parameterName: getTagValueFromObject(tags.map((tag) => ({ value: tag.tagId, label: tag.tagName })), data.Param_Name),
                                registerType: data.Modbus_Reg_Type,
                                registerAddress: data.Reg_Start,
                                slaveId: data.Modbus_Slave_Addr,
                                scalingFactor: data.Scaling_Factor,
                                modbusDataType: data.Modbus_Data_Type,
                                dataType: data.Data_Type,
                                dataSize: data.Data_Size
                            });
                            count++;
                        }
                    }
                } 
                else {
                    let parity: string = '0';
                    switch (data.modbusRtu.parity) {
                        case 'N':
                            parity = '0';
                            break;
                        case 'O':
                            parity = '1';
                            break;
                        case 'E':
                            parity = '2';
                            break;
                        default:
                            parity = '0';
                            break;
                    }

                    setBaudRate(data.modbusRtu.baudRate);
                    setSelectedStopBits({
                        value: data.modbusRtu.stopBits,
                        label: getValueFromObject(stopBits, data.modbusRtu.stopBits)
                    });
                    setSelectedParityStatus({
                        value: data.modbusRtu.parity,
                        label: getValueFromObject(parityStatus, parity)
                    });
                    setWordLength(data.modbusRtu.dataBits);
                    setSleepTime(data.sleeptime);
                    setFirmwareVersionName(data.Firm_Ver);
                    
                    data.modbusRtu.slaves.forEach((slave: SlaveAttributes) => {
                        let functionCode = '0';
                        switch (slave.functionCode) {
                            case '3':
                            functionCode = '0';
                            break;
                            case '4':
                            functionCode = '1';
                            break;
                            case '1':
                            functionCode = '2';
                            break;
                            default:
                            functionCode = '0';
                            break;
                        }
                        results.push({
                            parameterName: getTagValueFromObject(tags.map((tag) => ({ value: tag.tagId, label: tag.tagName })), slave.regName),
                            registerType: functionCode,
                            registerAddress: slave.address,
                            slaveId: slave.slaveId,
                            scalingFactor: slave.Scaling_Factor,
                            modbusDataType: '8',
                            dataType: slave.modbusDataType,
                            dataSize: slave.modbusDataSize,
                        });
                    });
                }    
                setSensorConfigs(results);
                showToast('Modbus Config Data Retrieved', 'success');
                setRetrieveButtonText('Retrieve');            
            }
        });

        IO.on('config-status', (data) => {
            if(data.success){  
                if (data.step === 2) {
                    waitingForStepThree = true;
                    setActiveStep(1);
                } else if (data.step === 3 && waitingForStepThree) {
                    waitingForStepThree = false;
                    setActiveStep(data.step);
                } else if (data.step > 3) {
                    waitingForStepThree = false;
                    setActiveStep(data.step);
                } else {
                    setActiveStep(data.step);
                } 
                if(data.step === 5){
                    setTimeout(() => {
                        showToast('Configuration details updated successfully', 'success');
                        setOpen1(false);
                        setActiveStep(0);
                        setOpen(false);
                    }, 2000);
                }
            }else{
                setOpen2(true);
                setOpen1(false);
                setActiveStep(0);
                setOpen(false);
            }
        });

        return () => {
            if (modIORef.current) {
                modIORef.current.disconnect();
                modIORef.current = null;
            }
        };

    }, [_id, tags]);

    useEffect(() => {
        setOptions((prevOption) => ({
            ...prevOption,
            stopBits: [...stopBits],
            parityStatus: [...parityStatus],
            parameter: [...tags.map((tag) => ({ value: tag.tagId, label: tag.tagName }))],
            resgisterType: [...resgisterType],
            scallingFactor: [...scallingFactor],
            modbusType: [...modbusType],
            dataType: [...dataType],
            dataSize: [...dataSize],
        }));
    }, [tags]);

    const fetchDetailsBasedOnDeviceId = async (deviceId: string) => {
        const [responseDevice] = await Promise.all([
            http(`${baseUrlDevice}devices/${deviceId}`) as Promise<Device>,
        ]);
        setCurrentDevice(responseDevice);
        setTags(responseDevice.tags);           
    }

    const data: SensorConfig[] = sensorConfigs.length > 0 ? sensorConfigs.map((d: SensorConfig, index: number) => {
        return {
            parameterName: getValueFromObject(tags.map((tag) => ({ value: tag.tagId, label: tag.tagName })), d.parameterName),
            registerType: getValueFromObject(resgisterType, d.registerType),
            registerAddress: d.registerAddress,
            slaveId: d.slaveId,
            scalingFactor: getValueFromObject(scallingFactor, d.scalingFactor),
            modbusDataType: getValueFromObject(modbusType, d.modbusDataType),
            dataType: getValueFromObject(dataType, d.dataType),
            dataSize: getValueFromObject(dataSize, d.dataSize),
            action: (
                <span
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '5px',
                        borderRadius: '4px',
                        gap: '8px',
                        border: '0.2px solid #D5D5D5',
                        margin: 'auto',
                    }}
                    className="cursor-pointer"
                >
                    <ClearIcon cursor="pointer" onClick={() => handleDelete(index)}/>
                    <EditIcon cursor="pointer" onClick={() => handleEdit(index)}/>
                </span>
            )
        }
    }) : [];

    const handleAddSensor = () => {
        if(!selectedParameter || !selectedRegisterType || !registerAddress || !slaveId || !selectedScallingFactor || !selectedModbusType || !selectedDataType || !selectedDataSize) {
            showToast('Please fill all the fields', 'hint');
            return;
        }

        const checkPoint = sensorConfigs.find((d: SensorConfig) => d.parameterName === selectedParameter.value || d.registerAddress === registerAddress);
        if(checkPoint){
            showToast('Duplicate parameter or register address.', 'hint');
            return;
        }

        if(sensorConfigs.length > 30 && currentDevice?.deviceCategory === "ESP32"){
            showToast('Maximum 30 sensors are allowed.', 'hint');
            return;
        }

        const sensorConfig: SensorConfig = {
            parameterName: selectedParameter.value as string,
            registerType: selectedRegisterType.value as string,
            registerAddress: registerAddress as string,
            slaveId: slaveId as string,
            scalingFactor: selectedScallingFactor.value as string,
            modbusDataType: selectedModbusType.value as string,
            dataType: selectedDataType.value as string,
            dataSize: selectedDataSize.value as string,
        };
        setSensorConfigs([...sensorConfigs, sensorConfig]);        
    }

    const handleDelete = (index: number) => {
        const newConfigs = [...sensorConfigs];
        newConfigs.splice(index, 1);
        setSensorConfigs(newConfigs);
    }

    const handleEdit = (index: number) => {
        const newConfigs = [...sensorConfigs];
        setEditMode(true);
        setEditIndex(index);
        const data = newConfigs[index];        
        setSelectedParameter({ 
            value: data.parameterName, 
            label: getValueFromObject(tags.map((tag) => ({ value: tag.tagId, label: tag.tagName })), data.parameterName) 
        });
        setSelectedRegisterType({ 
            value: data.registerType, 
            label: getValueFromObject(resgisterType, data.registerType)
        });
        setRegisterAddress(data.registerAddress);
        setSlaveId(data.slaveId);
        setSelectedScallingFactor({ 
            value: data.scalingFactor, 
            label: getValueFromObject(scallingFactor, data.scalingFactor),
        });
        setSelectedModbusType({ 
            value: data.modbusDataType, 
            label: getValueFromObject(modbusType, data.modbusDataType)
        });
        setSelectedDataType({ 
            value: data.dataType, 
            label: getValueFromObject(dataType, data.dataType)
        });
        setSelectedDataSize({ 
            value: data.dataSize, 
            label: getValueFromObject(dataSize, data.dataSize) 
        });       
    }

    const handleUpdateSensor = () => {
        if(!selectedParameter || !selectedRegisterType || !registerAddress || !slaveId || !selectedScallingFactor || !selectedModbusType || !selectedDataType || !selectedDataSize) {
            showToast('Please fill all the fields', 'hint');
            return;
        }
        sensorConfigs.splice(editIndex, 1);
        
        const checkPoint = sensorConfigs.find((d: SensorConfig) => d.parameterName === selectedParameter.value || d.registerAddress === registerAddress);
        if(checkPoint){
            showToast('Duplicate parameter or register address.', 'hint');
            return;
        }

        if(sensorConfigs.length > 30 && currentDevice?.deviceCategory === "ESP32"){
            showToast('Maximum 30 sensors are allowed.', 'hint');
            return;
        }

        const newConfig: SensorConfig = {
            parameterName: selectedParameter.value as string,
            registerType: selectedRegisterType.value as string,
            registerAddress: registerAddress as string,
            slaveId: slaveId as string,
            scalingFactor: selectedScallingFactor.value as string,
            modbusDataType: selectedModbusType.value as string,
            dataType: selectedDataType.value as string,
            dataSize: selectedDataSize.value as string,
        }
        setSensorConfigs([...sensorConfigs, newConfig]);
        setEditMode(false);
        showToast('Sensor updated successfully', 'success');
    }

    const handleReset = () => {
        setBaudRate('9600');
        setWordLength('8');
        setSleepTime('15');
        setFirmwareVersionName('Generic');
    }

    const handleConfigSubmit = () => {
        if(!baudRate || !wordLength || !sleepTime || !firmwareVersionName){
            showToast('Please fill all the fields', 'hint');
            return;
        }
        if(!sensorConfigs.length){
            showToast('Please add at least one sensor', 'hint');
            return;
        }

        setOpen1(true);

        const Modbus_Config = {
          "Update_MOD_Values": {
            "Modbus_config": {
              "buad_rate": baudRate,
              "Stop_bits": selectedStopBits?.value,
              "parity": selectedParityStatus?.value,
              "word_length": wordLength
            }
          }
        };

        const Version_Details = {
            "Update_MOD_Values": {
            "sleeptime": sleepTime,
            "Firm_Ver": firmwareVersionName
            }
        };

        const Restart_Data = { "restart" : "0" };
        const Device_Data = sensorConfigs.map((config) => {
            return {
                ...config,
                parameterName: getValueFromObject(tags.map((tag) => ({ value: tag.tagId, label: tag.tagName })), config.parameterName),
            }
        });    

      modIORef.current?.emit('config-data', { Modbus_Config, Version_Details, Device_Data: Device_Data, Restart_Data });      
    }

    const handleClickOpen = () => {
        if(!sensorConfigs.length){
            showToast('Please add at least one sensor', 'hint');
            return;
        }
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClose1 = async () => {
        setOpen1(false);
        setOpen(false)
        setActiveStep(0);
        try {
            const res = await http(`${baseUrlDevice}modbus-config/cancel/${_id}`) as ApiResponseModConfig;
            showToast(res.message, 'success');
        } catch (error) {
            showToast("Something went wrong Please try again", 'error');
        }
    }

    const handleClose2 = () => {
        setOpen2(false);
    }
    
    const handleRetrieveConfigData = () => {
        setRetrieveButtonText("Retrieving Data...");
        modIORef.current?.emit('retrieve-config-data', { "MODBUS_values" : "0" });
        if(timeoutRef.current){
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        timeoutRef.current = setTimeout(() => {
            setRetrieveButtonText('Retrieve');
            setOpen2(true);
        }, 120000);
    }

    useEffect(() => {
        fetchDetailsBasedOnDeviceId(_id as string);
    }, [_id]);    

    return (
        <>
            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <CancelOutlined color='warning'/> Failed
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Gateway device communication failed. Verify your connection settings. If the error persists, please consult the user manual or contact support.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open1} onClose={handleClose1} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Modbus Config in Progess
                    <IconButton aria-label="close" onClick={handleClose1} sx={{ position: 'absolute', right: 8, top: 8, color: 'inherit' }}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            label ? (<Step key={label}>
                            <StepLabel
                                StepIconComponent={() => (
                                <div style={{ width: 24, height: 24, borderRadius: '50%', backgroundColor: activeStep >= index ? '#1976d2' : '#bdbdbd' }} />
                                )}
                            >
                                {label}
                            </StepLabel>
                            </Step>
                            ) : null
                        ))}
                        </Stepper>
                        {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3, mt: 2 }}>
                            <Typography variant="body1">
                                All steps completed - Configuration details updated successfully.
                            </Typography>
                        </Paper>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <ReportProblemOutlined color='warning'/> Caution
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Proceeding with the update will overwrite all existing Modbus configuration details. Please ensure that the new settings are accurate before proceeding, as previous configurations will be permanently replaced.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="secondary" size="medium" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfigSubmit}>Update</Button>
                </DialogActions>
            </Dialog>

            <div className='flex flex-col ml-5 mt-5 gap-5'>
                <div className="flex flex-col md:flex-row gap-10">
                    <div className='flex flex-col md:flex-row justify-start gap-5'>
                        <InputField
                            type="text"
                            id="imei"
                            value={currentDevice?.imeiNumber}
                            disabled
                        />
                        <InputField
                            type="text"
                            id="deviceName"
                            value={currentDevice?.deviceName}
                            disabled
                        />
                    </div>
                    <div className='flex flex-col md:flex-row justify-start gap-5'>
                        <div className='h-[50]'>
                            <Button type="submit" variant="primary" size="medium" onClick={handleRetrieveConfigData}>
                                {retrieveButtonText}
                            </Button>
                        </div>
                        <div className='h-[50]'>
                            <Button type="button" variant="secondary" size="medium" onClick={handleClickOpen}>
                                Submit Config
                            </Button>
                        </div>
                    </div>
                </div>
                <span className="font-inter font-medium text-[#393939] text-base md:text-lg lg:text-xl leading-8 md:leading-9 lg:leading-10">
                    Last data at Today
                </span>

                <div className='flex-grow grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div className='border p-2 rounded-sm flex flex-col'>
                        <div className="flex justify-between items-center">
                            <div className="p-4 rounded-md">
                                <div className="text-xl font-semibold text-gray-800">
                                    Modbus Config
                                </div>
                            </div>
                            <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-gray-300 cursor-pointer" onClick={handleReset}>
                                Reset
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center'>
                            <InputField
                                type="text"
                                id="baud-rate"
                                placeholder="Baud Rate"
                                label="Baud Rate"
                                value={baudRate}
                                onChange={(e) => setBaudRate(e.target.value)}
                            />
                            <SearchableSelect
                                options={options.stopBits}
                                value={selectedStopBits}
                                onChange={(option) => setSelectedStopBits(option)}
                                placeholder="Select Stop Bits"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.parityStatus}
                                value={selectedParityStatus}
                                onChange={(option) => setSelectedParityStatus(option)}
                                placeholder="Select Parity Status"
                            />
                            <InputField
                                type="text"
                                id="wordLen"
                                placeholder="Word Length"
                                label="Word Length"
                                value={wordLength}
                                onChange={(e) => setWordLength(e.target.value)}
                            />
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <InputField
                                type="text"
                                id="sleepTime"
                                placeholder="Sleep Time"
                                label="Sleep Time (Minutes)"
                                value={sleepTime}
                                onChange={(e) => setSleepTime(e.target.value)}
                            />
                            <InputField
                                type="text"
                                id="firmwareVer"
                                placeholder="Firmware Version"
                                label="Firmware Version Name"
                                value={firmwareVersionName}
                                onChange={(e) => setFirmwareVersionName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='border p-2 rounded-sm flex flex-col'>
                        <div className="flex justify-between items-center">
                            <div className="p-4 rounded-md">
                                <div className="text-xl font-semibold text-gray-800">
                                    Sensor Config
                                </div>
                            </div>
                            <div className="flex gap-2">
                                {editMode && ( <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-green-800 text-white cursor-pointer" onClick={handleUpdateSensor}>
                                    <span className='text-white'>Update</span>
                                </div> )}
                                <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-black text-white cursor-pointer" onClick={handleAddSensor}>
                                    <span className='text-white'>+ Add</span>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center'>
                            <SearchableSelect
                                options={options.parameter}
                                value={selectedParameter}
                                onChange={(option) => setSelectedParameter(option)}
                                placeholder="Select Parameter Name"
                            />
                            <SearchableSelect
                                options={options.resgisterType}
                                value={selectedRegisterType}
                                onChange={(option) => setSelectedRegisterType(option)}
                                placeholder="Select Register Type"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <InputField
                                type="text"
                                id="address"
                                placeholder="Register Address"
                                label="Register Address"
                                value={registerAddress}
                                onChange={(e) => setRegisterAddress(e.target.value)}
                            />
                            <InputField
                                type="text"
                                id="slaveId"
                                placeholder="Slave ID"
                                label="Slave ID"
                                value={slaveId}
                                onChange={(e) => setSlaveId(e.target.value)}
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.scallingFactor}
                                value={selectedScallingFactor}
                                onChange={(option) => setSelectedScallingFactor(option)}
                                placeholder="Select Scaling Factor"
                            />
                            <SearchableSelect
                                options={options.modbusType}
                                value={selectedModbusType}
                                onChange={(option) => setSelectedModbusType(option)}
                                placeholder="Select Modbus Data Type"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.dataType}
                                value={selectedDataType}
                                onChange={(option) => setSelectedDataType(option)}
                                placeholder="Select Data Type"
                            />
                            <SearchableSelect
                                options={options.dataSize}
                                value={selectedDataSize}
                                onChange={(option) => setSelectedDataSize(option)}
                                placeholder="Select Data Size"
                            />
                        </div>
                    </div>
                </div>

                <div className='border p-2 rounded-sm'>
                    <CommonTable
                        columns={columns}
                        data={data}
                        title="Overall Summary"
                    />
                </div>
            </div>


        </>
    )
}

export default Modbus_Config