import React, { useEffect, useRef, useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../../types/EnergyMonitoringProps'
import { ChartData } from '../../../../../types/Chart';
import FirstRowCard from '../../components/FirstRowCard';
import { DailyConsumptionItem } from '../../../../../workers/consumption-data-worker/consumptionWorkerDaily';
import { useCurrentUserState } from '../../../../../app-context/current-user-context/CurrentUserLoginState';
import { http } from '../../../../../utils/helper/http';
import { baseUrlDevice } from '../../../../../utils/env/env.config';
import { ResponseDeviceSensorData } from '../../../../../types/Device';
import Loader from '../../../../../components/loader/Loader';
import BarChart from '../../../../../components/chart/bar-chart/BarChart';
import LongMenu from '../../../../../components/long-menu/LongMenu';
import { transformDataForGraphPdfExcel } from '../../../../../workers/graph-data-filter-worker/transformData';
import { generateExcelWithDataTable, generateGraphPDFWithDataTable } from '../../../../../utils/graph-download/pdfDownload';
import RadioGroup from '../../../../../components/radio-group/RadioGroup';
const METER_READING_1ST_ROW_CARD_TAGS = ['t_acte', 't_re', 't_apte', 'r_hr']
type ChartState = {
  barchart: ChartData;
};

const initialChartData: ChartData = {
  seriesData: [],
  categories: [],
};

const initialActiveLineChartState: ChartState = {
  barchart: initialChartData,
};
let toolTip: string[] = [];
const activeTagId: string[] = []

type Option = {
  label: string;
  value: string;
};
const defaultDropdownOption: Option[] = [];
const defaultGraph: Option[] = [];



const DailyConsumption: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, sensorInfo, deviceState }) => {
  const [consumptionData, setConsumtptionData] = useState<DailyConsumptionItem[]>();
  const workerRefConProcessor = useRef<Worker | null>(null);
  const [loading, setLoading] = useState(false);
  const [consumptioBarChart, setConsumptionBarChart] = useState(initialActiveLineChartState);
  const { currentUser } = useCurrentUserState()
  const [selectedOptions, setSelectedOptions] = useState<{ label: string, value: string }[]>(defaultGraph);
  const activeTags = METER_READING_1ST_ROW_CARD_TAGS.filter((tag) => {
    if (sensorInfo?.[tag]?.status) {
      activeTagId.push(sensorInfo?.[tag]?.tagId);
      if (!defaultDropdownOption.some(item => item.value === tag)) {
        if (defaultGraph.length < 3) {
          defaultGraph.push({ value: tag || '', label: sensorInfo[tag].displaySensorName })
        }
        defaultDropdownOption.push({
          label: sensorInfo?.[tag]?.displaySensorName || '',
          value: tag || ''
        });
      }

    }

    return sensorInfo?.[tag]?.status;
  });
  const handleRadioChange = (selected: Option[]) => {
    setSelectedOptions(selected);
  };
  const handleDownloadConcumptionBargraph = (option: string) => {
    const config = [
      { id: "day", label: "Timestamp" },
    ];
    selectedOptions.map((tag) => {
      config.push({ id: "consumption" + tag.value, label: sensorInfo?.[tag.value].displaySensorName || '' })
    })
    const filterData = transformDataForGraphPdfExcel(consumptionData || [], config)
    if (option === 'Download as CSV') {
      generateExcelWithDataTable(filterData)
    } else if (option === 'Download as PDF') {
      generateGraphPDFWithDataTable("barChart-container", filterData, currentUser.appLogo);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}consumption-data?deviceId=${deviceState?._id}&tagId=${encodeURIComponent(JSON.stringify(activeTagId))}&month=2025-01`
      );
      const dataAsSensor = response as ResponseDeviceSensorData;
      setConsumtptionData(dataAsSensor.deviceData as DailyConsumptionItem[])
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (!consumptionData) {
      fetchData()
    }
    workerRefConProcessor.current = new Worker(
      new URL('../../../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefConProcessor.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setConsumptionBarChart((prevCharts) => ({
        ...prevCharts,
        barchart: {
          seriesData,
          categories,
        },
      }));
    };
    return () => {
      if (workerRefConProcessor.current) {
        workerRefConProcessor.current?.terminate();
        workerRefConProcessor.current = null;
      }

    };

  }, [])

  useEffect(() => {
    if (workerRefConProcessor.current && consumptionData) {
      const reversedConsumptionData = [...consumptionData].reverse();
      const names: string[] = [];
      const keyNames: string[] = [];
      toolTip = []
      selectedOptions.forEach(tag => {
        const sensorName = sensorInfo?.[tag.value]?.displaySensorName;
        if (sensorName) {
          names.push(sensorName);
          keyNames.push("consumption" + tag.value);
          const sensorUnit = sensorInfo?.[tag.value]?.displaySensorUnit || '';
          if (sensorUnit !== undefined) {
            toolTip.push(sensorUnit);
          }
        }
      });
      const colors = ['2980b9', '79c9d4', '79d4b7'];
      const categories = reversedConsumptionData.map((item) => item.day)

      workerRefConProcessor.current.postMessage({
        data: reversedConsumptionData.reverse(),
        names,
        keyNames,
        colors,
        timeKey: 'day',
        categories
      });
    }
  }, [consumptionData, selectedOptions]);


  return (
    <div>
      {loading && <Loader isBarCircle={loading} />}
      <FirstRowCard dashBoardSts={dashBoardSts} sensorInfo={sensorInfo} />
      {activeTags.length > 0 &&
        <>
          <div className="relative bg-white border border-1 p-4 mt-2">
            <div className="absolute top-2 left-2 w-full max-w-[450px]">
              <RadioGroup
                options={defaultDropdownOption}
                maxSelectable={3}
                selectedOptions={selectedOptions}
                onChange={handleRadioChange}
              />
            </div>
            <div className="pt-3">
              <div className="absolute top-0 right-0">
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick={handleDownloadConcumptionBargraph}
                  id={1}
                />
              </div>
              <p>
                {`Daily energy  data variation with time (between ${consumptionData?.length && consumptionData.length > 0 ? `${consumptionData[consumptionData.length - 1].day} to ${consumptionData[0].day}` : " "
                  })`}
              </p>
            </div>
            <div
              id="barChart-container"
            >
              <BarChart
                title="Consumption Summary"
                categories={consumptioBarChart.barchart.categories}
                series={consumptioBarChart.barchart.seriesData}
                height={300}
                xAxisTitle="Time"
                yAxisTitle="Data"
                postFixUnit={toolTip}
              />

            </div>
          </div>

        </>
      }

    </div>
  )
}

export default DailyConsumption