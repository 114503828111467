import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import locale for dd/mm/yyyy format
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as D } from '@mui/x-date-pickers/DatePicker';

dayjs.locale('en-gb'); // Set locale globally for dd/mm/yyyy

interface DatePickerProps {
  label: string;
  value: dayjs.Dayjs | null;
  onChange: (date: dayjs.Dayjs | null) => void;
  helperText?: string;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  error?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  helperText,
  minDate,
  maxDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <D
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        format="DD-MMM-YYYY"
        slotProps={{
          textField: {
            helperText: helperText,
            variant: 'outlined',
            fullWidth: true,
            size: 'small',
            error: false,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
