import React from 'react'
import ReportCategoryCard from '../../../components/card/RepoortCategoryCard';
import HwraIcon from '../../../assets/images/hwra.png'
import RadicoIcon from '../../../assets/images/radio 1.png'
import TataIcon from '../../../assets/images/tata 1.png'


const ServerIntegerationCard = () => {
  return (
    <div>
    <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
      <ReportCategoryCard subtitle='Server Integration' title='HWRA' iconBgClass='bg-purple-100' icon={HwraIcon} link={'/home/server-integration/hwra'}/>
      <ReportCategoryCard subtitle='Server Integration' title='Radico' iconBgClass='bg-yellow-100' icon={RadicoIcon} link={'/home/server-integration/radico'}/>
      <ReportCategoryCard subtitle='Server Integration' title='TATA' iconBgClass='bg-purple-100' icon={TataIcon} link={'/home/server-integration/tata'}/>
    </div>
  </div>
  )
}

export default ServerIntegerationCard;
