import React, { useState } from 'react'
import { DashboardEnergyMonitoringProps } from '../types/EnergyMonitoringProps'
import { Box, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../../../../components/custom-tab-panel/CustomTabPanel';
import MeterReading from './meter-reading/MeterReading';
import DailyConsumption from './daily-consumption/DailyConsumption';
import a11yProps from '../../../../types/a11yProps';

const Analysis: React.FC<DashboardEnergyMonitoringProps> = ({ dashBoardSts, data = [], deviceState, sensorInfo }) => {
  const [analysisTabValue, setAnalysisTabValue] = useState(0);
  const handleAnalysisTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setAnalysisTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', backgroundColor: "white" }} className="rounded-lg">
        <Tabs value={analysisTabValue} onChange={handleAnalysisTabChange} aria-label="Sub tabs for Analysis">
          <Tab label={"Meter Reading"} {...a11yProps(0)} />
          <Tab label={"Daily Consumption"} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={analysisTabValue} index={0}>
        {/* Meter Reading Content */}
        {/* <div>Details of Meter Reading go here</div> */}
        <MeterReading dashBoardSts={dashBoardSts} data={data} deviceState={deviceState} sensorInfo={sensorInfo} />
      </CustomTabPanel>
      <CustomTabPanel value={analysisTabValue} index={1}>
        {/* Daily Consumption Content */}
        {/* <div>Details of Daily Consumption go here</div> */}
        <DailyConsumption dashBoardSts={dashBoardSts} deviceState={deviceState} data={data} sensorInfo={sensorInfo} />
      </CustomTabPanel>
    </>
  )
}

export default Analysis