import './App.css';
import Login from './modules/login/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ForgotPassword from './modules/forgot-password/ForgotPassword';
import ResetPassword from './modules/reset-password/ResetPassword';
import HomePage from './components/home-page/HomePage';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ui/error-fallback/ErrorFallback';
import PermissionRouteContent from './routes/DynamicRoute';
import { RouteProvider } from './app-context/route/RouteState';
import NotFoundPage from './ui/not-found/NotFoundPage';
import ProtectedRoute from './utils/protected-route-component/ProtectedRoute';
import { OrganizationProvider } from './app-context/organization-context/OrganizationState';
import { CurrentUserProvider } from './app-context/current-user-context/CurrentUserLoginState';
import { DeviceProvider } from './app-context/device-context/DeviceState';
import { SiteProvider } from './app-context/site-context/VendorState';
import { UserProvider } from './app-context/user-context/UserState';
import { TagProvider } from './app-context/tag-context/TagState';
import { TemplateProvider } from './app-context/template-context/TemplateState';
import { RoleProvider } from './app-context/role-context/RoleState';
import { OverviewProvider } from './app-context/overview-context/OverviewState';
import { AlertProvider } from './app-context/alert-context/AlertState';
import CustomToastProvider from './components/custom-toast/CustomToastProvider';
import { OptionsProvider } from './app-context/dropdown-option/OptionState';
import { PermissionProvider } from './app-context/permission-context/PermissionState';
import { SchedulingProvider } from './app-context/scheduling-context/SchedulingState';
import { TicketProvider } from './app-context/ticket-context/TicketState';
import OtpForm from './modules/otp/components/OtpForm';


function App() {
  return (
    <>
      <CustomToastProvider>
        <PermissionProvider>
          <RouteProvider>
            <CurrentUserProvider>
              <OrganizationProvider>
                <DeviceProvider>
                  <SiteProvider>
                    <UserProvider>
                      <TagProvider>
                        <TemplateProvider>
                          <RoleProvider>
                            <AlertProvider>
                              <SchedulingProvider>
                                <OverviewProvider>
                                  <OptionsProvider>
                                    <TicketProvider>
                                      <BrowserRouter>
                                        {/* <ErrorBoundary fallbackRender={ErrorFallback}> */}
                                        <ErrorBoundary fallbackRender={ErrorFallback}>
                                          <Routes>
                                            <Route path='/' element={<Login />} />
                                            <Route
                                              path='/reset-password'
                                              element={<ResetPassword />}
                                            />
                                            <Route
                                              path='/forgot-password'
                                              element={<ForgotPassword />}
                                            />
                                            <Route
                                              path='/Otp'
                                              element={<OtpForm />}
                                            />
                                            <Route path='/' element={<ProtectedRoute />}>
                                              <Route path='/home' element={<HomePage />}>
                                                <Route
                                                  path='*'
                                                  element={<PermissionRouteContent />}
                                                />
                                              </Route>
                                            </Route>
                                            <Route path='*' element={<NotFoundPage />} />
                                          </Routes>
                                        </ErrorBoundary>
                                      </BrowserRouter>
                                    </TicketProvider>
                                  </OptionsProvider>
                                </OverviewProvider>
                              </SchedulingProvider>
                            </AlertProvider>
                          </RoleProvider>
                        </TemplateProvider>
                      </TagProvider>
                    </UserProvider>
                  </SiteProvider>
                </DeviceProvider>
              </OrganizationProvider>
            </CurrentUserProvider>
          </RouteProvider>
        </PermissionProvider>
      </CustomToastProvider>
    </>
  );
}

export default App;
