import React from 'react';
import CommonTable from '../../../components/table/Table';
import { Column, DataRow } from '../../../../src/types/table';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SubscriptionTable: React.FC = () => {
  const columns: Column[] = [
    { id: 'imei', label: 'IMEI' },
    { id: 'period', label: 'Period' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
  ];

  type Status = 'active' | 'expired' | 'suspended';

  const subscriptions: { imei: string; period: string; status: Status }[] = [
    {
      imei: '123456789012345',
      period: '2023-01-01 to 2023-12-31',
      status: 'active',
    },
    {
      imei: '987654321098765',
      period: '2023-05-01 to 2023-11-30',
      status: 'expired',
    },
  ];

  const data: DataRow[] = subscriptions.map((row) => ({
    ...row,
    status: row.status,
    action: (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5px',
          borderRadius: '4px',
          gap: '8px',
          border: '0.2px solid #D5D5D5',
          margin: 'auto',
        }}
      >
        <ChatIcon style={{ cursor: 'pointer' }} onClick={() => handleChat(row.imei)} />
        <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleEdit(row.imei)} />
        <DeleteOutlineOutlinedIcon
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => handleDelete(row.imei)}
        />
      </span>
    ),
  }));

  const handleChat = (imei: string) => {
    console.log(`Open chat for IMEI: ${imei}`);
  };

  const handleEdit = (imei: string) => {
    console.log(`Edit subscription for IMEI: ${imei}`);
  };

  const handleDelete = (imei: string) => {
    console.log(`Delete subscription for IMEI: ${imei}`);
  };

  const pagination = {
    rowsPerPage: 10,
    page: 1,
    totalItems: data.length,
    handlePageChange: (newPage: number) => {
      console.log(`Page changed to ${newPage}`);
    },
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(`Rows per page changed to ${event.target.value}`);
    },
  };

  return (
    <CommonTable
      columns={columns}
      data={data}
      title="Subscription Details"
      pagination={pagination}
      setHeight="400px"
    />
  );
};

export default SubscriptionTable;

